import { useTranslation } from 'react-i18next';
import { SelectInput } from '../../../layout/inputs';

interface Props {
  handleChange: (e: SelectOption) => void,
  options: SelectOption[],
  isLoading: boolean
  value?: SelectOption,
}

export default function RecordsScopeSelect(props: Props) {
  const { handleChange, options, isLoading, value } = props;
  const { t } = useTranslation();
  

  return (
    <SelectInput
      value={value}
      options={options}
      placeholder={t('Entities to show')}
      isLoading={isLoading}
      onChange={handleChange}
    />
  );
}
