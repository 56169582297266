import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useApolloClient, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectsProjectProjectStatusChoices, QuotationGroupDetailsType } from '../../../../../../../__generated__/graphql';
import { UPDATE_GROUP_PRODUCTION_UNIT_STATUS_MUTATION } from '../../../../../../../api/mutations/quotations/group';
import { PROJECT_QUERY } from '../../../../../../../api/queries/projects';
import { QUOTATION_GROUP_DETAILS_QUERY } from '../../../../../../../api/queries/quotations/details';
import { useAppDispatch } from '../../../../../../../helpers/reduxHooks';
import SelectInput from '../../../../../../../layout/inputs/SelectInput';
import { setIsLoadingUpdate } from '../../../../../../../redux/quotationSlice';


interface Props {
  quotationId: ID,
  group: QuotationGroupDetailsType,
  refetchQueries: InternalRefetchQueriesInclude,
  options: SelectOption[],
}


export default function GroupProductionUnitStatusSelect(props: Props) {
  const {
    quotationId, group, refetchQueries, options,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const client = useApolloClient();

  const [
    updateProductManufacturingWageMutation,
  ] = useMutation(UPDATE_GROUP_PRODUCTION_UNIT_STATUS_MUTATION, {
    refetchQueries: refetchQueries,
    onCompleted: (data) => {
      dispatch(setIsLoadingUpdate(false));
      client
        .query({
          query: QUOTATION_GROUP_DETAILS_QUERY,
          variables: { group: group.id as string },
          fetchPolicy: 'network-only',
        })
        .then((groupResult) => {
          if (groupResult.data.quotationGroupDetails?.response?.productionUnitStatus?.isCompleted) {
            // No sense to try to update project status or redirect to new quotation
            // if mutation contains errors
            if (!data.updateGroupProductionUnitStatus?.errors?.length) {
              // Fetch project details after group details are fetched
              return client.query({
                query: PROJECT_QUERY,
                variables: { project: projectId as string },
                fetchPolicy: 'network-only',
              });
            }
          }

        })
        .then((projectResult) => {
          const project = projectResult?.data?.project?.response;
          const projectStatus = project?.projectStatus;

          if (projectStatus === ProjectsProjectProjectStatusChoices.Completed) {
            const latestQuotation = project?.latestQuotation;
            navigate(`/quotation/${projectId}/${latestQuotation?.id}/`);
          }
        })
        .catch((error) => {
          console.error('Error fetching details:', error);
        });
    },
  });


  const handleChange = (e: SelectOption) => {
    dispatch(setIsLoadingUpdate(true));
    updateProductManufacturingWageMutation(
      {
        variables: {
          quotation: quotationId as string,
          group: group.id as string,
          productionUnitStatus: e.value as string,
        },
      },
    );
  };

  if (!options.length) {
    return (
      <div className='p-6 bg-cyellow-200 text-cred-700 rounded shadow'>
        <div>{t('No Statuses for Production Units available.')}</div>
        <div>{t('Please contact your administrator.')}</div>
      </div>
    );
  } else {
    return (
      <SelectInput
        value={options.find(option => option.value === group.productionUnitStatus?.id)}
        options={options}
        placeholder={t('Production Unit Status')}
        onChange={handleChange}
      />
    );
  }
}
