import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchFeatureType } from '../../../../__generated__/graphql';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import SelectInput from '../../../../layout/inputs/SelectInput';
import { setFeature, unsetFeature } from '../../../../redux/searchSlice';
import { deepCopyFeature } from './helpers';

interface Props {
  feature: SearchFeatureType,
}

export default function LogicalFeature(props: Props) {
  const { feature: originalFeature } = props;
  const selectedFeatures = useAppSelector(state => state.search.selectedFeatures);
  const selectedFeature = selectedFeatures.find(item => item.id === originalFeature.id);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [selectValue, setSelectValue] = useState<SelectOption | undefined>(undefined);
  const { t } = useTranslation();
  const selectRef = useRef();
  const dispatch = useAppDispatch();

  // Avoid modifiying the original feature
  const feature = deepCopyFeature(originalFeature);

  const options = feature?.values?.reverse().map(valueObj => {
    //@ts-ignore
    const valueBool = valueObj?.valueBool;
    return {
      value: valueBool,
      label: valueBool === true ? t('Yes') : t('No'),
    };
  });

  const handleCheckboxChange = () => {
    if (!isCheckboxChecked) {
      setIsCheckboxChecked(true);
      if (selectRef.current) {
        //@ts-ignore
        selectRef.current.focus(); // Open dropdown when checkbox is checked
      }
    } else {
      setIsCheckboxChecked(false);
      dispatch(unsetFeature(feature.id as string));
    }
  };

  const handleSelectChange = (selectedOption: SelectOption) => {
    if (selectedOption) {
      setIsCheckboxChecked(true);
      setSelectValue(selectedOption);
      dispatch(setFeature({
        id: feature.id as string,
        userValue: selectedOption.value as boolean,
        code: feature.code as string,
        featureType: feature.featureType as string,
      }));
    } else {
      dispatch(unsetFeature(feature.id as string));
      setIsCheckboxChecked(false);
    }
  };

  const handleSelectMenuClose = () => {
    // Only execute if value is not initiated
    if (!selectValue) {
      setIsCheckboxChecked(false);
    }
  };

  useEffect(() => {
    // Initialize state based on selectedFeature
    if (selectedFeature) {
      setIsCheckboxChecked(true);
      setSelectValue(
        {
          value: selectedFeature.userValue as boolean,
          label: selectedFeature.userValue ? t('Yes') : t('No'),
        },
      );
    } else {
      setIsCheckboxChecked(false);
      setSelectValue(undefined);
    }
  }, [selectedFeature, t]);

  return (
    <>
      <div className="relative w-4 ml-2">
        {selectedFeature && (
          <div className="absolute inset-y-0 -left-2 w-0.5 bg-cblue-500" />
        )}
        <input
          type="checkbox"
          className="absolute left-0 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-cblue-500 focus:ring-cblue-500 grow"
          checked={selectedFeature && isCheckboxChecked}
          onChange={handleCheckboxChange}
        />
      </div>
      <div className='w-full'>
        <SelectInput
          //@ts-ignore
          ref={selectRef}
          openMenuOnFocus
          value={selectValue}
          options={options}
          isClearable
          placeholder={feature.localizedDescription as string}
          onChange={handleSelectChange}
          showLabel={!!selectedFeature}
          onMenuClose={handleSelectMenuClose}
        />
      </div>
    </>
  );
}
