import { useEffect, useState } from 'react';
import SplitPane, { Pane, SashContent } from 'split-pane-react';
import { SearchResponseObjectType, SearchResponsePageType } from '../__generated__/graphql';
import ETIMFilter from '../components/quotation/composeMode/ETIMFilter';
import GroupFilter from '../components/quotation/composeMode/GroupsFilter';
import SearchHeader from '../components/quotation/composeMode/SearchHeader';
import NotesPanel from '../components/quotation/notes/NotesPanel';
import SearchComposeListWrapper from '../components/search/searchCompose/list/SearchComposeListWrapper';
import { ComposeModeSearchType } from '../constants';
import { PaginationData, createPaginationData } from '../helpers/PaginationUtils';
import { useAppSelector } from '../helpers/reduxHooks';
import 'split-pane-react/esm/themes/default.css';
// eslint-disable-next-line import/order
import ReduxAlertWrapper from '../components/ReduxAlertWrapper';
import { Navbar } from '../layout';
import QuotationDetails from './QuotationDetails';
import './compose.css';

export default function Quotation() {
  const [sizes, setSizes] = useState<(string | number)[]>(['50%', '50%', '0%']);
  const [pageData, setPageData] = useState<SearchResponsePageType | SearchResponseObjectType[]>();
  const [searchRecordsLoading, setSearchRecordsLoading] = useState(false);
  const isComposeMode = useAppSelector(state => state.quotation.isComposeMode);
  const isNotesVisible = useAppSelector(state => state.quotation.isNotesVisible);
  const composeModeSearchType = useAppSelector(state => state.search.composeModeSearchType);

  useEffect(() => {
    // Update sizes based on both compose mode and notes visibility
    if (isComposeMode && isNotesVisible) {
      // Compose mode and notes visible
      setSizes(['40%', '40%', '20%']);
    } else if (isComposeMode) {
      // Compose mode without notes
      setSizes(['50%', '50%', '0%']);
    } else if (isNotesVisible) {
      // Notes visible but not in compose mode
      setSizes(['0%', '75%', '25%']);
    } else {
      // Neither compose mode nor notes visible
      setSizes(['0%', '100%', '0%']);
    }
    // Removing console.log to fix linter warning
  }, [isComposeMode, isNotesVisible]);

  const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  };

  let objectsData: SearchResponseObjectType[] = [];
  let paginationData: PaginationData | undefined;
  let totalObjectsQuantity = 0;

  if (Array.isArray(pageData)) {
    // pageData is SearchResponseObjectType[]
    objectsData = pageData;
    totalObjectsQuantity = pageData.length;
  } else if (pageData) {
    // pageData is SearchResponsePageType
    objectsData = pageData.objects as SearchResponseObjectType[];
    paginationData = createPaginationData(pageData as PaginationData);
    totalObjectsQuantity = pageData.totalObjectsQuantity as number;
  }

  return (
    // Using a different approach for height instead of min-h-screen
    <div className="flex flex-col h-screen">
      <Navbar isQuotationPage />
      {/* Calculate remaining height after navbar */}
      <div className="flex-grow overflow-hidden">
        <ReduxAlertWrapper />
        <SplitPane
          sashRender={(index) => (
            <SashContent className='bg-cblue-500 z-40'>
              {index === 0 && isComposeMode ? [
                <div
                  key="compose-divider"
                  className="w-0 h-0 absolute left-0 top-[74px] z-50
                      border-t-[24px] border-t-transparent
                      border-l-[10px] border-l-cblue-100
                      border-b-[24px] border-b-transparent"
                />,
              ] : index === 1 && isNotesVisible ? [
                <div
                  key="notes-divider"
                  className="w-0 h-0 absolute left-0 top-[74px] z-50
                      border-t-[24px] border-t-transparent
                      border-r-[10px] border-r-cgray-100
                      border-b-[24px] border-b-transparent"
                />,
              ] : []}
            </SashContent>
          )}
          split='vertical'
          sizes={sizes}
          onChange={setSizes}
          className='h-full'
          resizerSize={(isComposeMode || isNotesVisible) ? 10 : 0}
        >
          {/* First pane - Search/Filter (only visible in Compose Mode) */}
          <Pane minSize={'20%'}>
            <div style={{ ...layoutCSS }}>
              <div className="grow flex flex-col h-full bg-white shadow-xl overflow-y-auto w-full">
                <div className="relative flex flex-col gap-4 w-full min-w-[900px] h-full">
                  <SearchHeader />
                  <div className='flex w-full bg-white overflow-y-clip grow'>
                    {composeModeSearchType === ComposeModeSearchType.DEFAULT && (

                      <ETIMFilter
                        setPageData={setPageData}
                        setSearchRecordsLoading={setSearchRecordsLoading}
                      />
                    )}
                    {composeModeSearchType === ComposeModeSearchType.GROUPS && (
                      <GroupFilter
                        setPageData={setPageData}
                        setSearchRecordsLoading={setSearchRecordsLoading}
                      />
                    )}
                    <div className='grow relative overflow-y-scroll shrink-0'>
                      <SearchComposeListWrapper
                        objectsData={objectsData}
                        isLoading={searchRecordsLoading}
                        paginationData={paginationData}
                        totalObjectsQuantity={totalObjectsQuantity}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Pane>
          
          {/* Second pane - Main content (always visible, but width varies) */}
          <Pane minSize={'40%'}>
            <div style={{ ...layoutCSS }}>
              <div className="grow flex flex-col h-full bg-white shadow-xl overflow-y-auto ml-px">
                <QuotationDetails />
              </div>
            </div>
          </Pane>
          
          {/* Third pane - Notes (only visible when notes toggle is on) */}
          <Pane minSize={'20%'}>
            <div style={{ ...layoutCSS }}>
              <div className="grow flex flex-col h-full bg-cgray-100 border-l border-cgray-200 shadow-xl overflow-y-auto">
                {isNotesVisible && <NotesPanel />}
              </div>
            </div>
          </Pane>
        </SplitPane>
      </div>
    </div>
  );
}
