import { gql } from '../../../__generated__/gql';

export const QUOTATION_ITEM_NOTES_QUERY = gql(`
query quotationItemNotes($quotation: ID!){
    quotationItemNotes(quotation: $quotation){
      response {
        id
        itemId
        createdAt
        updatedAt
        body
        author{
          id
          firstName
          lastName
          fullName
          email
        }
        replies{
          id
          itemId
          createdAt
          updatedAt
          body
          author{
            id
            firstName
            lastName
            fullName
            email
          }
        }
      }
      errors {
        messages
        field
      }
  }
}
`);