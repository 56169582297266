import { UniqueIdentifier } from '@dnd-kit/core/dist/types';
import moment from 'moment-timezone';
import type { CSSProperties, KeyboardEvent } from 'react';
import { 
  ItemTypeChoices, 
  MultipleItemType, 
  ProjectsProjectProjectStatusChoices,
  WageType,
  AdministrativeOverheadType,
  ManufacturingOverheadType,
  MaterialCostBurdenRateType,
  DiscountType,
  SalesOverheadType,
} from '../__generated__/graphql';
import colorsConfig from '../colorsConfig';
import { FlattenedItem } from '../components/shared/dnd/types';
import { setSelectedColumn } from '../redux/quotationSlice';
import { setProperty } from './dnd/utilities';

export function classNames(...classes: (string | boolean | { [key: string]: boolean })[]): string {
  return classes
    .flatMap(cls => {
      if (typeof cls === 'string' || typeof cls === 'boolean') {
        return cls;
      }
      if (typeof cls === 'object' && cls !== null) {
        return Object.entries(cls)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_, condition]) => condition)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .map(([className, _]) => className);
      }
      return [];
    })
    .filter(Boolean)
    .join(' ');
}


export function getInlineOptionsFromQuery(queryData: any, labelField = 'name'): SelectOption[] {
  const innerData = queryData[Object.keys(queryData)[0]];
  
  return innerData.response.objects.map(
    (item: any) => (
      {
        label: item[labelField],
        value: item.id,
      }
    ),
  );
}

export function getInitials(name: string) {
  //@ts-ignore
  return name.match(/(^\S\S?|\b\S)?/g).join('').match(/(^\S|\S$)?/g).join('')
    .toUpperCase();
}

// Update css theme for react-select
export function updateSelectTheme(theme: any) {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#64748b',
      neutral50: '#94a3b8', // Placeholder color
      neutral40: '#94a3b8', // No-Options color
    },
  };
}

export const updateSelectStylesOnError = {
  control: (styles: any, state: any) => ({
    ...styles,
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(252, 75, 108, 0.25)' : 0,
    borderColor: '#f6aaab',
    '&:hover': {
      borderColor: '#ef4444',
    },
  }),
};

export function formatTimestamp(dateString: string) {
  const date = moment(dateString);
  const format = 'YYYY-MM-DD HH:mm';
  // Treat London time as UTC-0
  const calcualted = moment.tz(date, 'Europe/London');
  return calcualted.tz(moment.tz.guess(true)).format(format);
}

export function toTitleCase(inputStr: string) {
  // Also replaces underscores with spaces
  return inputStr.replace(
    /\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase().replace(/_/g, ' '));
}

export function getDateLabel(date: Date) {
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  return `${year}-${month}-${day}`;
}

export function capitalize(inputString: string) {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export const isNonEmpty = (input: string | number | (string | number)[]) => {
  if (typeof input === 'string') {
    return input.length > 0;
  }
  if (typeof input === 'number') {
    // Considering any number as "non-empty"
    return true;
  }
  if (Array.isArray(input)) {
    return input.length > 0;
  }
  return false;
};

export const getListRowStyles = (
  style: CSSProperties, 
  item: FlattenedItem, 
  copiedItems: FlattenedItem[], 
  selectedItem: FlattenedItem | null, 
  itemsToCut: FlattenedItem[], 
  showDetails: boolean,
  selectedItems: FlattenedItem[],
) => {

  return {
    ...style,
    '--bgColor': (copiedItems.find(i => i.id === item.id) || itemsToCut.find(i => i.id === item.id))
      ? '#CADDEB'
      : (item.id === selectedItem?.id || selectedItems.find(i => i.id === item.id))
        ? '#E4EDF5'
        : '#fff',
    'opacity': itemsToCut.find(i => i.id === item.id) ? 0.5 : 1,
    '--borderBottomColor': (showDetails) ? 'transparent' : '#d3d3d3',
    '--border-b-l-radius': (showDetails) ? '0px 0px' : '4px 4px',
    '--border-b-r-radius': (showDetails) ? '0px 0px' : '4px 4px',
    '--justifyContent': 'space-between',
  };
};

export const convertMinutesToTimeLabel = (time: number) => {
  let hours, minutes;
  if (time / 60 < 1) {
    hours = '00';
  } else if (time / 60 < 10) {
    hours = '0' + `${Math.floor(time / 60)}`;
  } else {
    hours = Math.floor(time / 60);
  }
  if (time % 60 < 1) {
    minutes = '00';
  } else if (time % 60 < 10) {
    minutes = '0' + `${time % 60}`;
  } else {
    minutes = time % 60;
  }
  return `${hours}:${minutes}`;
};

export const recursiveSearch = (deepArray: FlattenedItem[], parentId: ID, order: number): FlattenedItem | undefined => {
  let currentSelectedItem = deepArray?.find(item => (item.parentId === parentId && item.order === order));
  if (currentSelectedItem !== undefined) {
    return currentSelectedItem;
  }
  for (let child = 0; child < deepArray.length; child++) {
    currentSelectedItem = recursiveSearch(deepArray[child].children as FlattenedItem[], parentId, order);
    if (currentSelectedItem !== undefined) {
      return currentSelectedItem;
    }
  }
  return undefined;
};

export const recursiveSearchParent = (deepArray: FlattenedItem[], parentId: ID): FlattenedItem | undefined => {
  let currentSelectedItem = deepArray?.find(item => item.id === parentId);
  if (currentSelectedItem !== undefined) {
    return currentSelectedItem;
  }
  for (let child = 0; child < deepArray.length; child++) {
    currentSelectedItem = recursiveSearchParent(deepArray[child].children as FlattenedItem[], parentId);
    if (currentSelectedItem !== undefined) {
      return currentSelectedItem;
    }
  }
  return undefined;
};

export const recursiveSearchParentSibling = (parent: FlattenedItem | undefined, treeItems: FlattenedItem[]): FlattenedItem | undefined => {
  if (!parent) return undefined;
  const result = recursiveSearch(treeItems as FlattenedItem[], parent?.parentId as ID, parent?.order as number + 1);
  if (result) {
    return result;
  } else {
    const recursiveSearchParentResult = recursiveSearchParent(treeItems as FlattenedItem[], parent?.parentId as ID);
    return recursiveSearchParentSibling(recursiveSearchParentResult, treeItems);
  }
};

export const recursiveSearchSiblingChildren = (prevSibling: FlattenedItem | undefined, treeItems: FlattenedItem[]): FlattenedItem | undefined => {
  if (!prevSibling) return undefined;
  if (prevSibling.children.length && !prevSibling.collapsed) {
    const lastChildOfPreviousSiblings = recursiveSearch(treeItems as FlattenedItem[], prevSibling?.id as ID, prevSibling.children.length - 1);
    return recursiveSearchSiblingChildren(lastChildOfPreviousSiblings, treeItems);
  } else {
    return prevSibling;
  }
};

export function handleQuotationGroupCollapse(items: FlattenedItem[], idsToProcess: UniqueIdentifier[]): FlattenedItem[] {
  return setProperty(items, idsToProcess, 'collapsed', value => !value);
}

export function applyCollapsedItemsId(items: FlattenedItem[], collapsedItemsId: UniqueIdentifier[]): FlattenedItem[] {
  return items.map(item => {
    const newItem = { ...item };
    if (collapsedItemsId.includes(item.id)) {
      newItem.collapsed = true;
    }
    return newItem;
  });
}

export function collapseGroups(items: FlattenedItem[]): FlattenedItem[] {
  // Collapse all groups in the tree
  return items.map(item => {
    const newItem = { ...item };
    if (item.children && item.children.length > 0) {
      newItem.collapsed = true;
      newItem.children = collapseGroups(item.children);
    }
    return newItem;
  });
}

export function expandGroups(items: FlattenedItem[]): FlattenedItem[] {
  // Expand all groups in the tree
  return items.map(item => {
    const newItem = { ...item };
    if (item.children && item.children.length > 0) {
      newItem.collapsed = false;
      newItem.children = expandGroups(item.children);
    }
    return newItem;
  });
}

export type OverheadType = AdministrativeOverheadType | ManufacturingOverheadType | SalesOverheadType | MaterialCostBurdenRateType;

export const areValuesDifferent = (oldValue: any, newValue: any, key: string, getManufacturingWageLabel: (wageId: string) => string) => {
  if (oldValue == null || newValue == null) return oldValue !== newValue;

  if (typeof oldValue === 'object' && oldValue !== null) {
    switch (key) {
      case 'manufacturingWage':
        return (oldValue as WageType).id !== (newValue as WageType).id &&
          getManufacturingWageLabel((oldValue as WageType).id) !== getManufacturingWageLabel((newValue as WageType).id);

      case 'manufacturingOverhead':
      case 'administrativeOverhead':
      case 'salesOverhead':
      case 'materialCostBurdenRate':
        return (oldValue as OverheadType).id !== (newValue as OverheadType).id &&
          (oldValue as OverheadType).surcharge !== (newValue as OverheadType).surcharge;

      case 'productDiscount':
        return (oldValue as DiscountType).id !== (newValue as DiscountType).id &&
          (oldValue as DiscountType).label !== (newValue as DiscountType).label;

      default:
        return oldValue.id !== newValue.id;
    }
  }

  return oldValue !== newValue;
};

export function prepareItemForMutation(
  itemForMutation: FlattenedItem,
  isQuotationSearchContext: boolean,
) {
  if (itemForMutation.itemType === ItemTypeChoices.Product) {
    return {
      externalId: itemForMutation.product?.externalId?.toString() as string,
      itemType: MultipleItemType.Product,
      catalog: itemForMutation.product?.catalogId as string,
      productId: isQuotationSearchContext ? itemForMutation.product?.id as string : undefined,
    };
  } else {
    return {
      item: itemForMutation.itemId as string,
      itemType: !isQuotationSearchContext
      // Groups and Services from not Quotation search are treated as GroupTemplate Type
      // This is because on Backend for GroupTemplate context
      // we search for Items within special Items table comparing to Quotation Items.
        ? MultipleItemType.GroupTemplate
        : MultipleItemType.Group,
    };
  }
}


export function getProjectStatusColorHex(value: ProjectsProjectProjectStatusChoices) {
  switch (value) {
    case ProjectsProjectProjectStatusChoices.Approved:
      return colorsConfig['cgreen-500'];
    case ProjectsProjectProjectStatusChoices.AwaitingApproval:
      return colorsConfig['cblue-500'];
    case ProjectsProjectProjectStatusChoices.Completed:
      return colorsConfig['cblue-500'];
    case ProjectsProjectProjectStatusChoices.Draft:
      return colorsConfig['cblue-300'];
    case ProjectsProjectProjectStatusChoices.Production:
      return colorsConfig['cyellow-500'];
    case ProjectsProjectProjectStatusChoices.Rejected:
      return colorsConfig['cred-500'];
    default:
      return colorsConfig['cblue-300'];
  }
}


export function prepareOptionsForStatusSelect() {
  
  const sortOrder = [
    ProjectsProjectProjectStatusChoices.Draft,
    ProjectsProjectProjectStatusChoices.AwaitingApproval,
    ProjectsProjectProjectStatusChoices.Approved,
    ProjectsProjectProjectStatusChoices.Production,
    ProjectsProjectProjectStatusChoices.Completed,
    ProjectsProjectProjectStatusChoices.Rejected,
  ];

  return Object.values(ProjectsProjectProjectStatusChoices)
    .map(status => ({
      value: status,
      label: toTitleCase(status),
      dotColor: getProjectStatusColorHex(status),
    }))
    .sort((a, b) => sortOrder.indexOf(a.value) - sortOrder.indexOf(b.value));
}

interface HandleKeyDownProps {
  e: KeyboardEvent<HTMLInputElement>;
  isClickedTwice: boolean;
  isClickedOnce: boolean;
  inputFocused: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  handleSubmit: () => void;
  handleInputBlur: () => void;
  setIsClickedTwice: (value: boolean) => void;
  setFieldValue: (field: string, value: string | number | undefined) => void;
  fieldName: string;
  originalValue: string | number | undefined;
  dispatch?: any;
}

export const handleInputKeyDown = ({
  e,
  isClickedTwice,
  isClickedOnce,
  inputFocused,
  inputRef,
  handleSubmit,
  handleInputBlur,
  setIsClickedTwice,
  setFieldValue,
  fieldName,
  originalValue,
  dispatch,
}: HandleKeyDownProps) => {
  const unsetSelectedColumn = () => {
    dispatch(setSelectedColumn(null));
  };
  if (e.key === 'Enter' && isClickedTwice) {
    handleSubmit();
    handleInputBlur();
  }
  if (e.key === 'Escape' && isClickedTwice) {
    setFieldValue(fieldName, originalValue);
    setIsClickedTwice(false);
  }
  if (e.key === 'Escape' && !isClickedTwice) {
    unsetSelectedColumn?.();
    inputRef.current?.blur();
  }
  if (e.key === 'Enter' && !isClickedTwice) {
    setIsClickedTwice(true);
  }
  if (!isClickedTwice && (e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
    e.stopPropagation();
    const event = new KeyboardEvent('keydown', {
      key: e.key,
      bubbles: true,
      cancelable: true,
    });
    inputRef.current?.blur();
    document.dispatchEvent(event);
  }
  if (isClickedTwice && (e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
    handleSubmit();
    handleInputBlur();
    e.stopPropagation();
    const event = new KeyboardEvent('keydown', {
      key: e.key,
      bubbles: true,
      cancelable: true,
    });
    inputRef.current?.blur();
    document.dispatchEvent(event);
  }
  // Make input editable and select all text when any key is pressed while focused
  if ((isClickedOnce || inputFocused) && !isClickedTwice && e.key.length === 1) {
    inputRef.current?.select();
    setIsClickedTwice(true);
  }
};

export const getAllParentIds = (deepArray: FlattenedItem[], itemId: ID): ID[] => {
  const parentIds: ID[] = [];
  
  const findParentChain = (array: FlattenedItem[], id: ID): boolean => {
    const item = array.find(i => i.id === id);
    if (item?.parentId) {
      parentIds.push(item.parentId);
      // Continue searching for this parent's parent
      findParentChain(deepArray, item.parentId);
      return true;
    }
    
    return false;
  };

  findParentChain(deepArray, itemId);
  return parentIds;
};

export const getAllChildIds = (deepArray: FlattenedItem[], itemId: ID): ID[] => {
  const childIds: ID[] = [];
  
  const findChildrenChain = (array: FlattenedItem[], id: ID) => {
    const item = array.find(i => i.id === id);
    if (item?.children?.length) {
      item.children.forEach((child: FlattenedItem) => {
        childIds.push(child.id);
        // Recursively search for children of this child
        findChildrenChain(deepArray, child.id);
      });
    }
  };

  findChildrenChain(deepArray, itemId);
  return childIds;
};

