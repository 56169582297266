import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UPDATE_GROUP_CHILDREN_FIELDS_MUTATION } from '../../api/mutations/quotations/group';
import { GROUP_CHILDREN_VALUES_REPORT_QUERY } from '../../api/queries/quotations/group';
import { useAppDispatch, useAppSelector } from '../../helpers/reduxHooks';
import ModalButtons from '../../layout/inputs/ModalButtons';
import { setSuccessAlert } from '../../redux/alertSlice';
import { setIsLoadingUpdate } from '../../redux/quotationSlice';
import { ChangedFields } from '../quotation/overlays/CheckValuesOverlay/ValuesChangesTable';

interface Props {  
  handleClose: () => void,
  changedProductFields: ChangedFields;
  changedServiceFields: ChangedFields;
}

export default function ChangeGroupChildrenConfirmationModal(props: Props) {
  const { handleClose, changedProductFields, changedServiceFields } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedFavouriteGroupId = useAppSelector(state => state.search.selectedFavouriteGroupId);

  const [
    updateGroupChildrenFields,
    {
      data: mutationData,
      loading: mutationLoading,
    },
  ] = useMutation(UPDATE_GROUP_CHILDREN_FIELDS_MUTATION, {
    refetchQueries: [
      {
        query: GROUP_CHILDREN_VALUES_REPORT_QUERY,
        variables: {
          group: selectedFavouriteGroupId,
        },
      },
    ],
  });

  const handleSubmit = () => {
    if (!selectedFavouriteGroupId) return;

    // Prepare update data for products - include all products with selected fields
    const productUpdates = Object.entries(changedProductFields).reduce<Array<{ item_id: string; fields_to_update: string[] }>>((acc, [itemId, fields]) => {
      const fieldsToUpdate = Object.entries(fields)
        .filter(([, isSelected]) => isSelected)
        .map(([field]) => field);
      
      if (fieldsToUpdate.length > 0) {
        acc.push({
          item_id: itemId,
          fields_to_update: fieldsToUpdate,
        });
      }
      return acc;
    }, []);

    // Prepare update data for services - include all services with selected fields
    const serviceUpdates = Object.entries(changedServiceFields).reduce<Array<{ item_id: string; fields_to_update: string[] }>>((acc, [itemId, fields]) => {
      const fieldsToUpdate = Object.entries(fields)
        .filter(([, isSelected]) => isSelected)
        .map(([field]) => field);
      
      if (fieldsToUpdate.length > 0) {
        acc.push({
          item_id: itemId,
          fields_to_update: fieldsToUpdate,
        });
      }
      return acc;
    }, []);

    updateGroupChildrenFields({ 
      variables: { 
        group: selectedFavouriteGroupId,
        updateItemsData: JSON.stringify([...productUpdates, ...serviceUpdates]),
      },
      onError: () => {
        dispatch(setIsLoadingUpdate(false));
      },
    });
  };

  useEffect(() => {
    if (mutationData?.updateGroupChildrenFields?.response) {
      dispatch(setSuccessAlert(
        ['Group children fields were successfully updated.'],
      ));
      handleClose();
    } else if (mutationData?.updateGroupChildrenFields?.errors) {
      handleClose();
    }
  }, [mutationData, dispatch, handleClose]);

  return (
    <div className='flex flex-col justify-between items-center w-full rounded drop-shadow-xl bg-white' onClick={e => e.stopPropagation()}>
      <div className='pl-8 bg-cblue-100 w-full h-14 border-b-2 border-cblue-500 font-bold flex items-center text-cblue-500'>{t('Change Group Children')}</div>
      <div className='w-full pl-8 my-8'>{t('Changes will be applied to the selected group children in the existing quotation')}</div>
      <ModalButtons 
        mutationLoading={mutationLoading} 
        cancelButtonText='Cancel' 
        submitButtonText="Confirm" 
        handleClose={handleClose} 
        onSubmit={handleSubmit}
        wrapperClassName='flex justify-end gap-8 pr-8 pb-8 w-full'
      />
    </div>
  );
} 