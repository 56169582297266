import { IconX } from '@tabler/icons-react';
import {
  FeaturePresetItemType,
  FeatureType,
  UnitType,
} from '../../../../../../../__generated__/graphql';
import { useAppDispatch } from '../../../../../../../helpers/reduxHooks';
import { classNames } from '../../../../../../../helpers/utils';
import {
  setShowSelectFeatureModal, setSelectedGroupFeature,
  setActiveQuotationGroupId,
  setShowDeleteFeatureModal,
  setGroupFeatureIdToEdit,
} from '../../../../../../../redux/quotationSlice';

interface Props {
  item: FeaturePresetItemType,
  groupId: string,
  readOnly?: boolean,
}

export default function EtimGroupFeatureRO(props: Props) {
  const { item, groupId, readOnly = true } = props;

  const dispatch = useAppDispatch();
  const handleClick = () => {
    if (readOnly) return;
    dispatch(setSelectedGroupFeature({
      feature: item.etimClassFeature?.feature as FeatureType,
      value: item.value,
      id: item.id as string,
      etimClassFeatureId: item.etimClassFeature?.id as string,
      unit: item.unit as UnitType,
    }));
    dispatch(setActiveQuotationGroupId(groupId));
    dispatch(setShowSelectFeatureModal(true));
  };


  return (
    <div
      className={classNames('pl-2 py-px flex items-center min-w-max max-h-[40px] border border-cgray-200 rounded-t relative group', readOnly ? 'cursor-default' : 'bg-cgray-100 cursor-pointer  hover:bg-cgray-200')}
      onClick={handleClick}
    >
      <div className='mr-2'>
        <p className='text-cgray-400 text-2xs'>{item.etimClassFeature?.feature?.localizedDescription}</p>
        <p>
          <span>
          {item.valueLabels}
          </span>
        </p>
      </div>
      {!readOnly && <div className='w-0 border-cgray-300 border-r flex items-center justify-center h-10'></div>}  
      {!readOnly && <div 
        className='h-full w-8 flex items-center justify-center group'
        onClick={(e) => {
          e.stopPropagation();
          dispatch(setActiveQuotationGroupId(groupId));
          dispatch(setGroupFeatureIdToEdit(item.id as string));
          dispatch(setShowDeleteFeatureModal(true));
        }}
      
      >
        <IconX
          className='h-5 w-5 cursor-pointer group-hover:text-cred-500'
        />
      </div>}
    </div>
  );
}
