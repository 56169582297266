import { useApolloClient, useLazyQuery } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import Tippy from '@tippyjs/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { INLINE_PROJECTS_QUERY, PROJECT_QUERY } from '../../../api/queries/projects';
import { INLINE_RECORDS_SIZE } from '../../../config';
import { SearchType } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { getInlineOptionsFromQuery } from '../../../helpers/utils';
import { SearchInput } from '../../../layout/inputs';
import Switch from '../../../layout/inputs/Switch';
import {
  setSearchInputValue,
  setSearchProjectId,
  setSearchQuotationId,
  unsetPinnedItems,
} from '../../../redux/searchSlice';
import SelectProject from '../../quotation/topBar/SelectProject';
import SelectQuotation from '../../quotation/topBar/SelectQuotation';
import SearchResultsTypeSelect from './SearchResultsTypeSelect';
import SearchTypeSelect from './SearchTypeSelect';

export default function SearchHeader() {
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchInputValue = useAppSelector(state => state.search.searchInputValue);
  const searchType = useAppSelector(state => state.search.searchType);
  const QuotationContext = useAppSelector(state => state.search.QuotationContext);
  const isClassData = !!useAppSelector(state => state.search.selectedETIMClassId).length;
  const pinnedItems = useAppSelector(state => state.search.pinnedItems);
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const query: DocumentNode = INLINE_PROJECTS_QUERY;

  const [getProject, { data: projectData }] = useLazyQuery(PROJECT_QUERY);

  const handlePasteMode = () => {
    navigate('/search/multiple/paste/');
  };
  const handleSearchInputValue = (inputedValues: SelectOption[]) => {
    const searchableValues = inputedValues.map(item => item.value);
    dispatch(setSearchInputValue(searchableValues as string[]));
  };

  const handleProjectChange = (selectedId: string) => {
    setSelectedProjectId(selectedId);
    
    if (searchType === SearchType.QUOTATION) {
      const pinnedItemsIds = pinnedItems.map(item => item.id);
      dispatch(unsetPinnedItems(pinnedItemsIds));
    }

    // If search project is not selected, clear SEARCH quotation id
    if (!selectedId) {
      dispatch(setSearchQuotationId(undefined));
    }
  };

  useEffect(() => {
    if (QuotationContext && QuotationContext.searchProjectId === selectedProjectId) {
      // Do nothing if QuotationContext is not initialized
      // or this value is already set.
      return;
    }

    if (selectedProjectId) {
      getProject({ 
        variables: { project: selectedProjectId },
      });
    }
    const project = projectData?.project?.response;

    if (project && !!project.quotations?.length) {
      dispatch(setSearchProjectId(project.id));
      const latestQuotation = project.quotations[0];
      dispatch(setSearchQuotationId(latestQuotation?.id));
    } else if (project) {
      dispatch(setSearchProjectId(project.id));
      dispatch(setSearchQuotationId(undefined));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData, selectedProjectId, QuotationContext]);

  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function loadProjectOptions(search, loadedOptions, { skip }: any) {
    const variables = {
      searchInput: search,
      skip,
    };

    const { data } = await client.query({
      query,
      variables: variables,
      context: {
        debounceKey: 'INLINE_PROJECTS_QUERY',
      },
    });
    const innerData = data[Object.keys(data)[0]];
    const innerOptions = getInlineOptionsFromQuery(data);

    return {
      options: data ? innerOptions : [],
      hasMore: innerData.response.hasNext,
      additional: {
        skip: skip + INLINE_RECORDS_SIZE,
      },
    };
  }

  const handleQuotationChange = (selectedId: string) => {
    dispatch(setSearchQuotationId(selectedId));

    if (searchType === SearchType.QUOTATION) {
      const pinnedItemsIds = pinnedItems.map(item => item.id);
      dispatch(unsetPinnedItems(pinnedItemsIds));
    }
  };

  let searchValue: SelectOption[] | undefined;
  if (searchInputValue) {
    searchValue = searchInputValue.map(item => (
      { value: item, label: item }
    ));
  }

  return (
    <div className="flex items-center gap-4 justify-end">
        <div className='w-40 shrink-0'>
          <SearchTypeSelect />
        </div>
        {
          searchType === SearchType.QUOTATION
          &&
          <>
            <div className="w-96 flex flex-col">
              <SelectProject
                onChange={handleProjectChange}
                projectId={QuotationContext && QuotationContext.searchProjectId}
                loadOptions={loadProjectOptions}
              />
            </div>
            {
              QuotationContext && QuotationContext.searchProjectId &&
              <div className="w-80 flex flex-col">
                <SelectQuotation
                  onChange={handleQuotationChange}
                  projectId={QuotationContext.searchProjectId}
                  quotationId={QuotationContext.searchQuotationId}
                />
              </div>
            }
          </>
        }
        {
          searchType !== SearchType.QUOTATION &&
          <div className="flex flex-col w-1/12 min-w-[100px]">
            <SearchResultsTypeSelect />
          </div>
        }
        <SearchInput
          placeholder={
            searchType === SearchType.ETIM && !isClassData 
              ? `${t('To activate the Search, please select Group and Сlass')}` 
              : `${t('Search')}`
          }
          isMulti
          onInput={handleSearchInputValue}
          value ={searchValue}
          isDisabled={searchType === SearchType.ETIM && !isClassData ? true : false}
        />
      {location.pathname.includes('multiple') &&
      <Tippy
        theme="calcutronGray"
        content={t('Select records to unblock Paste Mode')}
        disabled={!!pinnedItems.length}
        key={pinnedItems.length.toString()}
        placement="left"
      >
        <Switch
          value={location.pathname.includes('paste')}
          onChange={handlePasteMode}
          className='flex items-center gap-2 shrink-0'
          label={t('Paste mode')}
          isDisabled={!pinnedItems.length}
        />
      </Tippy>
      }
    </div>
  );
}
