import { useTranslation } from 'react-i18next';
import { SearchType } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import SelectInput from '../../../layout/inputs/SelectInput';
import { setSearchInputValue, setSearchType } from '../../../redux/searchSlice';

export default function SearchTypeSelect() {
  const dispatch = useAppDispatch();
  const searchType = useAppSelector(state => state.search.searchType);
  const { t } = useTranslation();

  const handleSearchBySelect = (selectedOption: SelectOption) => {
    dispatch(setSearchType(selectedOption.value as SearchType));
    dispatch(setSearchInputValue([]));
  };

  const options = [
    { value: SearchType.DESCRIPTION, label: `${t('Description')}` },
    { value: SearchType.ETIM, label: `${t('ETIM Standard')}` },
    { value: SearchType.QUOTATION, label: `${t('Quotation')}` },
  ];

  return (
      <SelectInput
        value={options.find(item => item.value === searchType)}
        options={options}
        placeholder={t('Search by')}
        onChange={handleSearchBySelect}
      />
  );
}
