import { useApolloClient, useMutation } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ProjectType } from '../../__generated__/graphql';
import { UPDATE_PROJECT_DETAILS_MUTATION } from '../../api/mutations/projects';
import { INLINE_CUSTOMERS_QUERY } from '../../api/queries/customers';
import { PROJECT_QUERY } from '../../api/queries/projects';
import { INLINE_RECORDS_SIZE } from '../../config';
import { useAppDispatch } from '../../helpers/reduxHooks';
import { getInlineOptionsFromQuery } from '../../helpers/utils';
import { Input, TextArea } from '../../layout/inputs';
import ModalButtons from '../../layout/inputs/ModalButtons';
import { setSuccessAlert } from '../../redux/alertSlice';
import CustomerSelect from '../projects/fields/CustomerSelect';
import ManagerSelect from '../projects/fields/ManagerSelect';
import ProjectTypeSelect from '../projects/fields/ProjectTypeSelect';

interface FormValues {
  customer: SelectOption | undefined,
  externalId: string,
  name: string,
  notes: string,
  daysOfValidity: number,
  projectType: string | undefined,
  managersIds: string[] | undefined,
}

interface Props {
  projectsData: ProjectType;
}

export default function EditProjectDetailsForm(props: Props) {
  const { projectsData } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { projectId } = useParams();  

  const initialValues: FormValues = {
    customer: {
      value: projectsData.customer.id,
      label: projectsData.customer.name,
    },
    externalId: projectsData.externalId,
    daysOfValidity: projectsData.daysOfValidity,
    name: projectsData.name,
    notes: projectsData.notes,
    projectType: projectsData.projectType,
    managersIds: projectsData.managers?.map((manager) => manager.id),
  };

  const client = useApolloClient();
  const query: DocumentNode = INLINE_CUSTOMERS_QUERY;

  const [
    projectMutation,
    {
      data: mutationData,
      loading: mutationLoading,
    },
  ] = useMutation(UPDATE_PROJECT_DETAILS_MUTATION, {
    refetchQueries: [{
      query: PROJECT_QUERY,
      variables: {
        project: projectId as string,
      },
    }],
  });

  const schema = yup.object({
    customer: yup.object().required(`${t('Requried')}`),
    externalId: yup.string()
      .required(`${t('Requried')}`),
    daysOfValidity: yup.number()
      .max(2147483647, `${t('The maximum number cannot exceed 2.147.483.647')}`)
      .min(1, `${t('The number cannot be negative or equal 0')}`)
      .integer(`${t('Days of validity must contain only digits')}`)
      .required(`${t('Requried')}`),
    name: yup.string().trim().required(`${t('Requried')}`),
    notes: yup.string(),
    projectType: yup.string().required(`${t('Requried')}`),
    managersIds: yup.array().of(yup.number()),
  });

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    values: formikValues,
    errors: formikErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
        externalId: values.externalId as string,
        customer: values.customer?.value as string,
        projectType: values.projectType as string,
        daysOfValidity: values.daysOfValidity,
        managers: values.managersIds,
        id: projectId as string,
      };

      projectMutation(
        {
          variables,
        },
      );
    },
  });

  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function loadCustomerOptions(search, loadedOptions, { skip }: any) {
    const variables = {
      searchInput: search,
      skip,
    };

    const { data } = await client.query({
      query,
      variables: variables,
      context: {
        debounceKey: 'INLINE_CUSTOMERS_QUERY',
      },
    });
    const innerData = data[Object.keys(data)[0]];
    const innerOptions = getInlineOptionsFromQuery(data);
    
    return {
      options: data ? innerOptions : [],
      hasMore: innerData.response.hasNext,
      additional: {
        skip: skip + INLINE_RECORDS_SIZE,
      },
    };
  }

  useEffect(() => {
    if (mutationData && mutationData.updateProject && mutationData.updateProject.response) {
      dispatch(setSuccessAlert(
        [t('Project was successfully updated')],
      ));
      navigate('/projects/');
    }
  }, [mutationData, dispatch, t, navigate]);
  
  return (
    <form className="px-12 py-8" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-8 items-center justify-center border-b border-gray-900/10 pb-12 w-full rounded-b-xl">
          <div className="w-[800px]">
            <h2 className="text-base font-semibold leading-7 text-gray-900">{t('Project details:')}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">{t('Project connects customer and quotation data.')}</p>
          </div>

          <div className="flex flex-col gap-6 w-[800px]">                       
            <div className="relative">
              <ProjectTypeSelect 
                setFieldValue={setFieldValue} 
                initialProjectType={formikValues.projectType || ''}
              />
              {touched.projectType && !!formikErrors.projectType && (<div className="mt-2 text-sm text-red-600">{formikErrors.projectType}</div>)}
            </div>

            <div className="relative mt-2">
              <Input
                id="externalId"
                name="externalId"
                type="text"
                value={formikValues.externalId ?? ''}
                //@ts-ignore
                errorMessage={touched.externalId && formikErrors.externalId}
                label={t('Project External Id')}
                placeholder={t('Project External Id')}
                required
                onChange={handleChange}
              />
            </div>
            
            <div className="relative mt-2">
              <Input
                id="daysOfValidity"
                name="daysOfValidity"
                type="number"
                value={formikValues.daysOfValidity}
                //@ts-ignore
                errorMessage={touched.daysOfValidity && formikErrors.daysOfValidity}
                label={t('Days of validity')}
                placeholder={t('Days of validity')}
                onChange={handleChange}
              />
            </div>

            <div className="relative mt-2">
              <Input
                id="name"
                name="name"
                type="text"
                value={formikValues.name}
                //@ts-ignore
                errorMessage={touched.name && formikErrors.name}
                label={t('Project Name')}
                placeholder={t('Project Name')}
                required
                onChange={handleChange}
              />
            </div>

            <div className="mt-4">
              <CustomerSelect 
                setFieldValue={setFieldValue}
                loadOptions={loadCustomerOptions}
                initialCustomer={formikValues.customer}
              />
              {touched.customer && !!formikErrors.customer && (<div className="mt-2 text-sm text-red-600">{formikErrors.customer}</div>)}
            </div>

            <div className="relative mt-4">
              <ManagerSelect 
                setFieldValue={setFieldValue} 
                multiValue={formikValues.managersIds}
              />
              {touched.managersIds && !!formikErrors.managersIds && (<div className="mt-2 text-sm text-red-600">{formikErrors.managersIds}</div>)}
            </div>

            <div className="col-span-full">
              <div className="mt-2">
                <div className="relative mt-2">
                  <TextArea
                    id="notes"
                    name="notes"
                    rows={3}
                    onChange={handleChange}
                    label={t('Notes')}
                    placeholder={t('Notes')}
                    value={formikValues.notes}
                  />
                </div>
              </div>
            </div>
           </div>
        </div>
        <ModalButtons 
          mutationLoading={mutationLoading} 
          cancelButtonText='Cancel' 
          submitButtonText="Save" 
          handleClose={() => navigate('/')} 
          wrapperClassName='mt-6 flex items-center justify-end w-[800px] gap-x-4'
        />
    </form>

  );
}
