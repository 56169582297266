import { useTranslation } from 'react-i18next';
import { EtimProductFeatureType, EtimProductType } from '../../../../__generated__/graphql';
import EtimProductFeature from '../../../quotation/list/row/details/EtimProductFeature';

interface Props {
  etimProduct: EtimProductType;
}

export default function ETIMData({ etimProduct }: Props) {
  const { t } = useTranslation();

  return (
    <div className="max-w-full">
      <div className="mb-2 font-bold text-cgray-600">{t('ETIM')}</div>
      
      {/* Prevents flex from overflowing */}
      <div className="flex flex-wrap gap-2">

        {/* Left Section: Descriptions & Classifications */}
        <div className="flex flex-col gap-2 flex-1 max-w-[640px]">
          <div className="pl-2 pr-1 py-px rounded-t border border-cgray-200">
            <p className="text-2xs text-cgray-400">{t('Short Description')}</p>
            <p className="break-words">{etimProduct?.descriptionShort}</p>
          </div>
          <div className="pl-2 pr-1 py-px rounded-t border border-cgray-200">
            <p className="text-2xs text-cgray-400">{t('Long Description')}</p>
            <p className="break-words">{etimProduct?.descriptionLong}</p>
          </div>
          <div className="pl-2 pr-1 py-px min-h-[42px] rounded-t border border-cgray-200">
            <p className="text-2xs text-cgray-400">{t('ETIM Group')}:</p>
            <p className="break-words">{etimProduct?.etimClass?.group?.localizedDescription}</p>
          </div>
          <div className="pl-2 pr-1 py-px min-h-[42px] rounded-t border border-cgray-200">
            <p className="text-2xs text-cgray-400">{t('ETIM Class')}:</p>
            <p className="break-words">{etimProduct?.etimClass?.localizedDescription}</p>
          </div>
        </div>

        {/* Right Section: Product Features */}
        <div className="flex-1 max-w-4xl">
          <div className="flex flex-wrap gap-2">
            {etimProduct.productFeatures.map((item: EtimProductFeatureType, index: number) => (
              <EtimProductFeature item={item} key={index} />
            ))}
          </div>
        </div>

      </div>
    </div>
  );
}
