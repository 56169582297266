import { useQuery } from '@apollo/client';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductValuesReport, ServiceValuesReport } from '../../../../__generated__/graphql';
import { MANUFACTURING_WAGES_QUERY } from '../../../../api/queries/settings';
import { areValuesDifferent } from '../../../../helpers/utils';
import { ProductChangesTable } from './ProductChangesTable';
import { ServiceChangesTable } from './ServiceChangesTable';

export interface ItemFieldsToUpdateInput {
  item_id: string;
  fields_to_update: string[];
}

export interface ChangedFields {
  [key: string]: {
    [key: string]: boolean;
  };
}

interface ValuesChangesTableProps {
  products?: ProductValuesReport[];
  services?: ServiceValuesReport[];
  changedProductFields: ChangedFields;
  setChangedProductFields: React.Dispatch<React.SetStateAction<ChangedFields>>;
  changedServiceFields: ChangedFields;
  setChangedServiceFields: React.Dispatch<React.SetStateAction<ChangedFields>>;
  showServiceFields: boolean;
}

export const ValuesChangesTable = ({ 
  products,
  services,
  changedProductFields,
  setChangedProductFields,
  changedServiceFields,
  setChangedServiceFields,
  showServiceFields,
}: ValuesChangesTableProps) => {
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const { t } = useTranslation();
  const { data: manufacturingWagesData } = useQuery(MANUFACTURING_WAGES_QUERY);

  const getManufacturingWageLabel = useCallback((wageId: string) => {
    const wage = manufacturingWagesData?.manufacturingWages?.response?.find(
      w => w?.id === wageId,
    );
    return wage ? wage.label : '-';
  }, [manufacturingWagesData]);

  // Initialize changed fields when incoming products change
  useEffect(() => {
    if (products) {
      const newChangedFields: ChangedFields = {};
      products?.forEach(product => {
        const itemId = product?.itemId as string;
        newChangedFields[itemId] = {};
        
        // Check each field for changes
        Object.keys(product?.oldValues || {}).forEach(key => {
          if (key === '__typename') return;

          const oldValue = product?.oldValues?.[key as keyof typeof product.oldValues];
          const newValue = product?.newValues?.[key as keyof typeof product.newValues];

          if (areValuesDifferent(oldValue, newValue, key, getManufacturingWageLabel)) {
            newChangedFields[itemId][key] = false;
          }
        });
      });
      setChangedProductFields(newChangedFields);
    }
  }, [products, getManufacturingWageLabel, setChangedProductFields]);

  // Recalculate selectedProducts based on changedProductFields
  useEffect(() => {
    if (!products) return;

    const newSelectedProducts = products.reduce<string[]>((acc, product) => {
      const itemId = product?.itemId as string;
      const productFields = changedProductFields[itemId];
      
      if (!productFields) return acc;

      // Get all fields that have changes (excluding __typename)
      const changedKeys = Object.keys(product?.oldValues || {}).filter(key => {
        if (key === '__typename') return false;
        
        const oldValue = product?.oldValues?.[key as keyof typeof product.oldValues];
        const newValue = product?.newValues?.[key as keyof typeof product.newValues];
        
        return areValuesDifferent(oldValue, newValue, key, getManufacturingWageLabel);
      });

      // Check if all changed fields for this product are selected
      const allFieldsSelected = changedKeys.every(key => productFields[key]);

      if (allFieldsSelected) {
        return [...acc, itemId];
      }
      return acc;
    }, []);

    setSelectedProducts(newSelectedProducts);
  }, [changedProductFields, products, getManufacturingWageLabel]);

  // Initialize changed fields when incoming services change
  useEffect(() => {
    if (services) {
      const newChangedServiceFields: ChangedFields = {};
      services?.forEach(service => {
        const itemId = service?.itemId as string;
        newChangedServiceFields[itemId] = {};
        
        // Check each field for changes
        Object.keys(service?.oldValues || {}).forEach(key => {
          if (key === '__typename') return;

          const oldValue = service?.oldValues?.[key as keyof typeof service.oldValues];
          const newValue = service?.newValues?.[key as keyof typeof service.newValues];

          if (areValuesDifferent(oldValue, newValue, key, getManufacturingWageLabel)) {
            newChangedServiceFields[itemId][key] = false;
          }
        });
      });
      setChangedServiceFields(newChangedServiceFields);
    }
  }, [services, getManufacturingWageLabel, setChangedServiceFields]);

  // Recalculate selectedServices based on changedServiceFields
  useEffect(() => {
    if (!services) return;

    const newSelectedServices = services.reduce<string[]>((acc, service) => {
      const itemId = service?.itemId as string;
      const serviceFields = changedServiceFields[itemId];
      
      if (!serviceFields) return acc;

      // Get all fields that have changes (excluding __typename)
      const changedKeys = Object.keys(service?.oldValues || {}).filter(key => {
        if (key === '__typename') return false;
        
        const oldValue = service?.oldValues?.[key as keyof typeof service.oldValues];
        const newValue = service?.newValues?.[key as keyof typeof service.newValues];
        
        return areValuesDifferent(oldValue, newValue, key, getManufacturingWageLabel);
      });

      // Check if all changed fields for this service are selected
      const allFieldsSelected = changedKeys.every(key => serviceFields[key]);

      if (allFieldsSelected) {
        return [...acc, itemId];
      }
      return acc;
    }, []);

    setSelectedServices(newSelectedServices);
  }, [changedServiceFields, services, getManufacturingWageLabel]);

  const handleSelectAllProducts = () => {
    const willDeselectAll = !!selectedProducts.length;
    
    if (willDeselectAll) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(products?.map(product => product?.itemId as string) ?? []);
    }

    // Update changedProductFields for all products
    setChangedProductFields(prevFields => {
      const newFields = { ...prevFields };

      products?.forEach(product => {
        const itemId = product?.itemId as string;
        const productFields = prevFields[itemId] || {};
        const newProductFields = { ...productFields };

        Object.keys(product?.oldValues || {}).forEach(key => {
          if (key === '__typename') return;

          const oldValue = product?.oldValues?.[key as keyof typeof product.oldValues];
          const newValue = product?.newValues?.[key as keyof typeof product.newValues];

          // Check if the field has changes
          const hasChanges = areValuesDifferent(oldValue, newValue, key, getManufacturingWageLabel);

          if (hasChanges) {
            // Set all changed fields to false if deselecting all, true if selecting all
            newProductFields[key] = !willDeselectAll;
          }
        });

        newFields[itemId] = newProductFields;
      });

      return newFields;
    });
  };

  const handleProductSelect = (itemId: string) => {
    setSelectedProducts(prev => {
      const willBeDeselected = prev.includes(itemId);
      const newSelectedProducts = willBeDeselected 
        ? prev.filter(id => id !== itemId)
        : [...prev, itemId];

      // Update changedProductFields based on product selection
      setChangedProductFields(prevFields => {
        const productFields = prevFields[itemId] || {};
        const newProductFields = { ...productFields };

        // Get all changed fields for this product
        const product = products?.find(p => p?.itemId === itemId);
        if (product) {
          Object.keys(product.oldValues || {}).forEach(key => {
            if (key === '__typename') return;

            const oldValue = product.oldValues?.[key as keyof typeof product.oldValues];
            const newValue = product.newValues?.[key as keyof typeof product.newValues];

            // Check if the field has changes
            const hasChanges = areValuesDifferent(oldValue, newValue, key, getManufacturingWageLabel);

            if (hasChanges) {
              // Set all changed fields to true if product is selected, false if deselected
              newProductFields[key] = !willBeDeselected;
            }
          });
        }

        return {
          ...prevFields,
          [itemId]: newProductFields,
        };
      });

      return newSelectedProducts;
    });
  };

  const handleSelectAllServices = () => {
    const willDeselectAll = !!selectedServices.length;
    
    if (willDeselectAll) {
      setSelectedServices([]);
    } else {
      setSelectedServices(services?.map(service => service?.itemId as string) ?? []);
    }

    // Update changedServiceFields for all services
    setChangedServiceFields(prevFields => {
      const newFields = { ...prevFields };

      services?.forEach(service => {
        const itemId = service?.itemId as string;
        const serviceFields = prevFields[itemId] || {};
        const newServiceFields = { ...serviceFields };

        Object.keys(service?.oldValues || {}).forEach(key => {
          if (key === '__typename') return;

          const oldValue = service?.oldValues?.[key as keyof typeof service.oldValues];
          const newValue = service?.newValues?.[key as keyof typeof service.newValues];

          // Check if the field has changes
          const hasChanges = areValuesDifferent(oldValue, newValue, key, getManufacturingWageLabel);

          if (hasChanges) {
            // Set all changed fields to false if deselecting all, true if selecting all
            newServiceFields[key] = !willDeselectAll;
          }
        });

        newFields[itemId] = newServiceFields;
      });

      return newFields;
    });
  };

  const handleServiceSelect = (itemId: string) => {
    setSelectedServices(prev => {
      const willBeDeselected = prev.includes(itemId);
      const newSelectedServices = willBeDeselected 
        ? prev.filter(id => id !== itemId)
        : [...prev, itemId];

      // Update changedServiceFields based on service selection
      setChangedServiceFields(prevFields => {
        const serviceFields = prevFields[itemId] || {};
        const newServiceFields = { ...serviceFields };

        // Get all changed fields for this service
        const service = services?.find(s => s?.itemId === itemId);
        if (service) {
          Object.keys(service.oldValues || {}).forEach(key => {
            if (key === '__typename') return;

            const oldValue = service.oldValues?.[key as keyof typeof service.oldValues];
            const newValue = service.newValues?.[key as keyof typeof service.newValues];

            // Check if the field has changes
            const hasChanges = areValuesDifferent(oldValue, newValue, key, getManufacturingWageLabel);

            if (hasChanges) {
              // Set all changed fields to true if service is selected, false if deselected
              newServiceFields[key] = !willBeDeselected;
            }
          });
        }

        return {
          ...prevFields,
          [itemId]: newServiceFields,
        };
      });

      return newSelectedServices;
    });
  };

  const handleFieldSelect = (itemId: string, field: string) => {
    setChangedProductFields(prev => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        [field]: !prev[itemId]?.[field],
      },
    }));
  };

  const handleServiceFieldSelect = (itemId: string, field: string) => {
    setChangedServiceFields(prev => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        [field]: !prev[itemId]?.[field],
      },
    }));
  };

  const handleColumnSelect = (columnKey: string) => {
    // Check if the column is currently selected
    const isSelected = products?.every(product => {
      const itemId = product?.itemId as string;
      const hasChanges = areValuesDifferent(product?.oldValues?.[columnKey as keyof typeof product.oldValues], product?.newValues?.[columnKey as keyof typeof product.newValues], columnKey, getManufacturingWageLabel);
      return !hasChanges || changedProductFields[itemId]?.[columnKey];
    });

    // Update changedProductFields for all products that have changes in this column
    setChangedProductFields(prev => {
      const newFields = { ...prev };

      products?.forEach(product => {
        const itemId = product?.itemId as string;
        const oldValue = product?.oldValues?.[columnKey as keyof typeof product.oldValues];
        const newValue = product?.newValues?.[columnKey as keyof typeof product.newValues];

        // Check if this field has changes
        const hasChanges = areValuesDifferent(oldValue, newValue, columnKey, getManufacturingWageLabel);

        if (hasChanges) {
          newFields[itemId] = {
            ...newFields[itemId],
            [columnKey]: !isSelected, // Toggle the selection state
          };
        }
      });

      return newFields;
    });
  };

  const handleServiceColumnSelect = (columnKey: string) => {
    // Check if the column is currently selected
    const isSelected = services?.every(service => {
      const itemId = service?.itemId as string;
      const oldValue = service?.oldValues?.[columnKey as keyof typeof service.oldValues];
      const newValue = service?.newValues?.[columnKey as keyof typeof service.newValues];
      const hasChanges = areValuesDifferent(oldValue, newValue, columnKey, getManufacturingWageLabel);
      return !hasChanges || changedServiceFields[itemId]?.[columnKey];
    });

    // Update changedServiceFields for all services that have changes in this column
    setChangedServiceFields(prev => {
      const newFields = { ...prev };

      services?.forEach(service => {
        const itemId = service?.itemId as string;
        const oldValue = service?.oldValues?.[columnKey as keyof typeof service.oldValues];
        const newValue = service?.newValues?.[columnKey as keyof typeof service.newValues];

        // Check if this field has changes
        const hasChanges = areValuesDifferent(oldValue, newValue, columnKey, getManufacturingWageLabel);

        if (hasChanges) {
          newFields[itemId] = {
            ...newFields[itemId],
            [columnKey]: !isSelected, // Toggle the selection state
          };
        }
      });

      return newFields;
    });
  };

  const isFieldSelected = (itemId: string, field: string) => {
    return changedProductFields[itemId]?.[field] || false;
  };

  const isServiceFieldSelected = (itemId: string, field: string) => {
    return changedServiceFields[itemId]?.[field] || false;
  };

  const hasProducts = !!products?.length;
  const hasServices = !!services?.length;

  return (
    <div className="w-full h-5/6 overflow-y-auto">
      {showServiceFields && hasServices ? (
        <ServiceChangesTable 
          services={services as ServiceValuesReport[]}
          selectedServices={selectedServices}
          handleServiceSelect={handleServiceSelect}
          handleSelectAllServices={handleSelectAllServices}
          handleFieldSelect={handleServiceFieldSelect}
          isFieldSelected={isServiceFieldSelected}
          handleColumnSelect={handleServiceColumnSelect}
        />
      ) : !showServiceFields && hasProducts ? (
        <ProductChangesTable 
          products={products as ProductValuesReport[]}
          selectedProducts={selectedProducts}
          handleProductSelect={handleProductSelect}
          handleSelectAll={handleSelectAllProducts}
          handleFieldSelect={handleFieldSelect}
          isFieldSelected={isFieldSelected}
          handleColumnSelect={handleColumnSelect}
        />
      ) : (
        <div className='flex items-center justify-center w-full py-4'>
          {t('No changes found.')}
        </div>
      )}
    </div>
  );
}; 