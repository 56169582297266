import { useAppDispatch, useAppSelector } from '../../helpers/reduxHooks';
import { classNames } from '../../helpers/utils';
import { setSelectedFavouriteGroupId } from '../../redux/searchSlice';

interface Props {
  groupId: string;
  description: string;
  className?: string;
}

export function GroupFilterItem({ groupId, description, className = '' }: Props) {
  const dispatch = useAppDispatch();
  const selectedFavouriteGroupId = useAppSelector(state => state.search.selectedFavouriteGroupId);

  const isChecked = selectedFavouriteGroupId === groupId;

  const handleClick = () => {
    if (isChecked) {
      dispatch(setSelectedFavouriteGroupId(''));
    } else {
      dispatch(setSelectedFavouriteGroupId(groupId));
    }
  };

  return (
    <div 
      key={groupId} 
      className={classNames(
        isChecked ? 'bg-cblue-500 text-white' : 'bg-cgray-100 text-cgray-500',
        'cursor-pointer flex items-center justify-between relative min-h-[40px]',
        className,
      )}
      onClick={handleClick}
    >
      <label
        htmlFor={`filter-${groupId}`}
        className="cursor-pointer flex-grow ml-3 text-sm"
      >
        {description}
      </label>
    </div>
  );
} 