import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { SingleValue } from 'react-select';
import { PositionTypeChoices } from '../../../../../__generated__/graphql';
import { UPDATE_PRODUCT_POSITION_TYPE } from '../../../../../api/mutations/quotations/product';
import { POSITION_TYPES_QUERY } from '../../../../../api/queries/settings';
import { SelectedColumnName } from '../../../../../constants';
import { useAppDispatch } from '../../../../../helpers/reduxHooks';
import PopoutInput from '../../../../../layout/inputs/PopoutInput';
import { setIsLoadingUpdate, setSelectedColumn } from '../../../../../redux/quotationSlice';

interface Props {
  positionType: PositionTypeChoices | undefined, 
  id: ID,
  refetchQueries:  InternalRefetchQueriesInclude,
  setIsItemHovered: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function PositionTypeSelect(props: Props) {
  const { positionType, id, refetchQueries, setIsItemHovered } = props;
  const [selectedType, setSelectedType] = useState(positionType);
  const [inputFocused, setInputFocused] = useState(false);

  const { quotationId } = useParams();
  const dispatch = useAppDispatch();

  const {
    data: positionTypesData,
  } = useQuery(POSITION_TYPES_QUERY);

  const [
    updateProductPositonTypeMutation,
    {
      loading: updateProductPositonTypeLoading,
    },
  ] = useMutation(UPDATE_PRODUCT_POSITION_TYPE, { refetchQueries });

  const options = positionTypesData && positionTypesData.positionTypes && positionTypesData.positionTypes.response
    ? positionTypesData.positionTypes.response.map((type) => (
      { value: type?.value, label: type?.label }) as SelectOption)
    : [];

  const handleChange = (selectedOption: SelectOption) => {
    updateProductPositonTypeMutation({
      variables: {
        positionType: selectedOption.value as string,
        product: id as string,
        quotation: quotationId as string,
      },
    });
  };

  useEffect(() => {
    setSelectedType(positionType);
  }, [positionType]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(updateProductPositonTypeLoading));
  }, [dispatch, updateProductPositonTypeLoading]);

  const handleFocus = () => {
    setInputFocused(true);
    dispatch(setSelectedColumn(SelectedColumnName.POSITION_TYPE));
  };

  const handleBlur = () => {
    setInputFocused(false);
  };

  return (
    <div className="h-full" onClick={()=>setIsItemHovered(null)}>
      <PopoutInput
        value={options.find(option => option.value === selectedType)}
        options={options}
        onChange={handleChange as (value?: SingleValue<SelectOption>) => void}
        buttonLabel={options.find(option => option.value === selectedType)?.label.slice(0, 2).toUpperCase() as string}
        positionType={selectedType}
        onFocus={handleFocus}
        onBlur={handleBlur}
        menuIsOpen={inputFocused}
      />
    </div>
  );
}
