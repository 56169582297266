interface UserAvatarInterface {
  firstName: string;
  lastName: string;
  email: string;
}

interface Props {
  user: UserAvatarInterface;
  size?: 'sm' | 'md' | 'lg';
  shadowSize?: 'sm' | 'md' | 'lg';
}

const UserAvatar = (props: Props) => {
  const { user, size = 'lg', shadowSize = 'lg' } = props;

  const sizeClasses = {
    xs: 'w-4 h-4',
    sm: 'w-6 h-6',
    md: 'w-8 h-8',
    lg: 'w-10 h-10',
    xl: 'w-12 h-12',
  };

  const userAvatarLetters = (user?.firstName && user?.lastName)
    ? user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()
    : user?.email.slice(0, 2).toUpperCase();

  return (
    <>
        <div className={`shadow-${shadowSize} ${sizeClasses[size]} bg-cblue-100 flex items-center justify-center text-sm rounded-full`}>
          {userAvatarLetters}
        </div>
    </>
  );
};

export default UserAvatar;
