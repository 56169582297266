import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { classNames } from '../../helpers/utils';
import FavouriteButton from '../quotation/list/row/details/groupDetails/FavouriteButton';
import { Handle } from '../shared/dnd/Item/components/Handle/Handle';
import { GroupFilterItem } from '../shared/GroupsFilterItem';

interface Props {
  groupId: string;
  description: string;
}

export function DraggableFavouriteGroupItem({ groupId, description }: Props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: groupId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 2 : 1,
  };

  return (
    <div 
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="relative flex items-center"
    >
      <FavouriteButton
        groupId={groupId}
        isFavourite={true}
      />
      <GroupFilterItem
        groupId={groupId}
        description={description}
        className={classNames(
          isDragging ? 'opacity-50' : '',
          'ml-2 pr-8 grow',
        )}
      />
      <Handle {...listeners} className="absolute right-0 top-0 bottom-0" />
    </div>
  );
} 