import { Switch as HeadlessSwitch } from '@headlessui/react';
import { IconCheck, IconX } from '@tabler/icons-react';
import { forwardRef } from 'react';
import { classNames } from '../../helpers/utils';

interface Props {
  value: boolean,
  onChange: () => void,
  className: string,
  isLoading?: boolean,
  label: string,
  isDisabled?: boolean,
  checkedIcon?: React.ReactNode,
  uncheckedIcon?: React.ReactNode,
}

const Switch = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    value, onChange, className, isLoading, label, isDisabled = false,
    checkedIcon = <IconCheck className='w-3 h-3'/>, 
    uncheckedIcon = <IconX  className='w-3 h-3'/>,
  } = props;

  let switchColor = 'bg-ffffff hover:bg-cblue-200';
  if (isLoading) {
    switchColor = 'cyellow-500';
  } else if (value) {
    switchColor = 'bg-cblue-500';
  }
  
  return (
    <>
      { !isDisabled ?
        <HeadlessSwitch.Group as="div" className={className}>
          <HeadlessSwitch
            ref={ref}
            checked={value}
            onChange={onChange}
            className={`${switchColor} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent ring-2 ring-cblue-500 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none`}
          >
            <span      
            aria-hidden="true"
            className={classNames(
              value ? 'translate-x-5' : 'translate-x-0',
              'text-cblue-500 flex items-center justify-center pointer-events-none h-5 w-5 rounded-full border-2 border-cblue-500 bg-white shadow transform transition ease-in-out duration-200',
            )}
          >
            {value ? checkedIcon : uncheckedIcon}
            </span>
          </HeadlessSwitch>
          <HeadlessSwitch.Label passive className={classNames(value ? 'text-cblue-500' : 'text-cgray-500', 'font-medium transition-colors ease-in-out duration-200')}>{label}</HeadlessSwitch.Label>
        </HeadlessSwitch.Group>
        :
          <button className={className} ref={ref}>
            <div
              className={'cursor-default relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent ring-2 ring-cgray-400 rounded-full transition-colors ease-in-out duration-200 focus:outline-none'}
            >
              <span
                    aria-hidden="true"
                    className={classNames(
                      value ? 'translate-x-5' : 'translate-x-0',
                      'text-cblue-500 flex items-center justify-center h-5 w-5 rounded-full border-2 border-cgray-400 bg-cgray-400 shadow transform transition ease-in-out duration-200',
                    )}
                  >
                    {value ? checkedIcon : uncheckedIcon}
                  </span>
            </div>
            <div className='text-cgray-400 font-medium transition-colors ease-in-out duration-200'>{label}</div>
          </button>
      }
    </>
  );
});

export default Switch;