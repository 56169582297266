import { gql } from '../../../__generated__/gql';

export const PRODUCTION_UNIT_STATUSES_QUERY = gql(`
query productionUnitStatuses{
    productionUnitStatuses{
      response {
        id
        sortOrder
        title
        color
        statusType
        isReadonly
      }
      errors {
        messages
        field
      }
  }
}
`);


export const SHOW_MANUAL_PRICE_CONVERSION_WARNING = gql(`
  query showManualPriceConversionWarning($group: ID!) {
      showManualPriceConversionWarning(group: $group){
        response
        errors{
          messages
          field
        }
      }
  }
  `);

export const GROUP_CHILDREN_VALUES_REPORT_QUERY = gql(`
  query GroupChildrenValuesReport($group: ID!) {
    groupChildrenValuesReport(
      group: $group
    ) {
      response {
        products {
          itemId
          position
          description
          oldValues {
            notes
            profitSurcharge
            manufacturingTime
            specialPrice
            netPricePiece
              manufacturingWage {
                id
                wageGroup
              }
              manufacturingOverhead {
                id
                surcharge
              }
              administrativeOverhead {
                id
                surcharge
              }
              salesOverhead {
                id
                surcharge
              }
              materialCostBurdenRate {
                id
                surcharge
              }
              productDiscount {
                id
                label
              }
            }
            newValues {
              notes
              profitSurcharge
              manufacturingTime
              specialPrice
              netPricePiece
              manufacturingWage {
                id
                wageGroup
              }
              manufacturingOverhead {
                id
                surcharge
              }
              administrativeOverhead {
                id
                surcharge
              }
              salesOverhead {
                id
                surcharge
              }
              materialCostBurdenRate {
                id
                surcharge
              }
              productDiscount {
                id
                label
              }
            }
        }
        services {
          itemId
          position
          description
          oldValues {
            manufacturingOverhead {
              id
              surcharge
            }
            administrativeOverhead {
              id
              surcharge
            }
            salesOverhead {
              id
              surcharge
            }
          }
          newValues {
            manufacturingOverhead {
              id
              surcharge
            }
            administrativeOverhead {
              id
              surcharge
            }
            salesOverhead {
              id
              surcharge
            }
          }
        }
      }
      errors {
        messages
        field
      }
    }
  }
`);