import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { InlineUserType } from '../../../__generated__/graphql';
import { INLINE_USERS_QUERY } from '../../../api/queries/users';
import { SelectInput } from '../../../layout/inputs';

interface Props {
  setFieldValue: SetFieldValue,
  multiValue?: string[] | undefined,
}

export default function ManagerSelect(props: Props) {
  const { setFieldValue, multiValue } = props;
  const { t } = useTranslation();
  const { data, loading } = useQuery(INLINE_USERS_QUERY);

  const inlineUsers = data ? data.inlineUsers as InlineUserType[] : [];

  const options = inlineUsers.map((user) => ({ value: user.id, label: user.fullName } ));

  function handleChange(selectedOptions: SelectOption[]) {
    
    const managerIds = selectedOptions.map(option => option.value);
    setFieldValue('managersIds', managerIds);
  }
  
  const multiValueOptions = multiValue?.map((id) => options.find(option => option.value === id));
  
  return (
    <SelectInput
      options={options}
      placeholder={t('Select managers for this project')}
      isLoading={loading}
      isMulti
      onMultiChange={handleChange}
      multiValue={multiValueOptions ? multiValueOptions as SelectOption[] : undefined}
    />
  );
}
