import type { UniqueIdentifier } from '@dnd-kit/core';
import type { AnimateLayoutChanges } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CSSProperties, useEffect, useState } from 'react';
import { ItemTypeChoices } from '../../../../__generated__/graphql';
import { iOS } from '../../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { setJustPastedItemsIds } from '../../../../redux/quotationSlice';
import { FlattenedItem } from '../../../shared/dnd/types';
import {
  DefaultRowGroup,
  RODefaultRowGroup,
  DraggedRowGroup,
  EstimateRowGroup,
  ROEstimateRowGroup,
} from './group';
import {
  DefaultRowProduct,
  RODefaultRowProduct,
  DraggedRowProduct,
  EstimateRowProduct,
  ROEstimateRowProduct,
} from './product';
import {
  DefaultRowService,
  RODefaultRowService,
  DraggedRowService,
  EstimateRowService,
  ROEstimateRowService,
} from './service';

interface Props {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  item: FlattenedItem;
  id: UniqueIdentifier;
  indentationWidth: number;
  indicator?: boolean;
  onCollapse?(): void;
  hasDndContext?: boolean,
  quotationLength: number;
  initialIndex: number;
  isHighlighted?: boolean;
  hasNotes?: boolean;
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
  isSorting || wasDragging ? false : true;

const fadeOutAnimation = `
  @keyframes fadeOutBackground {
    0% { background-color: transparent; }
    30% { background-color: rgba(255, 216, 114, 0.3); }
    100% { background-color: transparent; }
  }
  
  @keyframes highlightPulse {
    0% { background-color: rgba(251, 191, 36, 0.2); }
    50% { background-color: rgba(251, 191, 36, 0.4); }
    100% { background-color: rgba(251, 191, 36, 0.2); }
  }
`;

export function QuotationListItem(props: Props) {
  const {
    childCount, clone, collapsed, depth, item, id, indentationWidth, hasDndContext,
    indicator, onCollapse, quotationLength, initialIndex, isHighlighted } = props;
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const openedDeatilsItems = useAppSelector(state => state.quotation.openedDeatilsItems);
  const isDetailsShown = openedDeatilsItems.includes(id);
  const [showDetails, setShowDetails] = useState(isDetailsShown);
  const isEstimateMode = useAppSelector(state => state.quotation.isEstimateMode);
  const isQuotationReadOnly = useAppSelector(state => state.quotation.isQuotationReadOnly);
  const justPastedItemsIds = useAppSelector(state => state.quotation.justPastedItemsIds);
  const isJustPasted = justPastedItemsIds.includes(id);
  const dispatch = useAppDispatch();

  if (isJustPasted) {
    style.animation = 'fadeOutBackground 1.5s ease-in-out forwards';
    setTimeout(() => {
      dispatch(setJustPastedItemsIds([]));
    }, 1500);
  }

  if (isHighlighted) {
    style.animation = 'fadeOutBackground 1.5s ease-in-out forwards';
  }

  useEffect(() => {
    setShowDetails(openedDeatilsItems.includes(id));    
  }, [id, openedDeatilsItems]);

  switch (item.itemType) {
    case ItemTypeChoices.Product:
      const productProps = {
        style, clone, depth, indentationWidth, indicator, item,
        ref: setDraggableNodeRef, showDetails, setShowDetails,
        wrapperRef: setDroppableNodeRef, hasDndContext,
        ghost: isDragging,
        disableSelection: iOS,
        disableInteraction: isSorting,
        handleProps: {
          ...attributes,
          ...listeners,
        },
      };
      let ProductComponent = DefaultRowProduct;
      if (clone) {
        ProductComponent = DraggedRowProduct;
      } else {
        if (isQuotationReadOnly && isEstimateMode) {
          ProductComponent = ROEstimateRowProduct;
        } else if (isQuotationReadOnly) {
          ProductComponent = RODefaultRowProduct;
        } else if (isEstimateMode) {
          ProductComponent = EstimateRowProduct;
        }
      }
      return (
        <>
          <style>{fadeOutAnimation}</style>
          <ProductComponent {...productProps} />
        </>
      );
    case ItemTypeChoices.Group:
      const groupProps = {
        collapsed, indentationWidth, depth, style, clone, indicator, childCount, onCollapse, item,
        ref: setDraggableNodeRef, showDetails, setShowDetails, hasDndContext,
        wrapperRef: setDroppableNodeRef,
        ghost: isDragging,
        disableSelection: iOS,
        disableInteraction: isSorting,
        handleProps: {
          ...attributes,
          ...listeners,
        },
        quotationLength,
        initialIndex,
      };
      let GroupComponent = DefaultRowGroup;
      if (clone) {
        GroupComponent = DraggedRowGroup;
      } else {
        if ((isQuotationReadOnly && isEstimateMode) || (item.group?.productionStatus?.isReadonly && isEstimateMode)) {
          GroupComponent = ROEstimateRowGroup;
        } else if (
          isQuotationReadOnly
          || item.group?.productionStatus?.isReadonly
          || item.group?.productionStatus?.isCompleted
        ) {
          GroupComponent = RODefaultRowGroup;
        } else if (isEstimateMode) {
          GroupComponent = EstimateRowGroup;
        }
      }
      return (
        <>
          <style>{fadeOutAnimation}</style>
          <GroupComponent {...groupProps} />
        </>
      );
    case ItemTypeChoices.Service:
      const serviceProps = {
        style, clone, depth, indentationWidth, indicator, item, showDetails, setShowDetails, hasDndContext,
        ref: setDraggableNodeRef,
        wrapperRef: setDroppableNodeRef,
        ghost: isDragging,
        disableSelection: iOS,
        disableInteraction: isSorting,
        handleProps: {
          ...attributes,
          ...listeners,
        },
      };
      let ServiceComponent = DefaultRowService;
      if (clone) {
        ServiceComponent = DraggedRowService;
      } else {
        if (isQuotationReadOnly && isEstimateMode) {
          ServiceComponent = ROEstimateRowService;
        } else if (isQuotationReadOnly) {
          ServiceComponent = RODefaultRowService;
        } else if (isEstimateMode) {
          ServiceComponent = EstimateRowService;
        }
      }
      return (
        <>
          <style>{fadeOutAnimation}</style>
          <ServiceComponent {...serviceProps} />
        </>
      );
    default:
      return <></>;
  }
}
