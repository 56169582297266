import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from '../../../../layout';

interface ContextMenuTextAreaInputProps {
  handleSubmit: (value: string) => void;
  loading: boolean;
  placeholder?: string;
  rows?: number;
}

const ContextMenuTextAreaInput: React.FC<ContextMenuTextAreaInputProps> = ({
  handleSubmit,
  loading,
  placeholder = 'Enter note for this item...',
  rows = 6,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);


  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (value.trim() && !loading) {
      handleSubmit(value);
      setValue('');
    }
  };

  return (
    <div className="p-2 w-64">
      <form onSubmit={onSubmit}>
        <textarea
          ref={textAreaRef}
          className="w-full border border-cgray-300 rounded px-2 py-1 text-sm resize-none focus:outline-none focus:border-cblue-500"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          rows={rows}
          disabled={loading}
        />
        <div className="flex justify-end mt-2">
          <button
            type="submit"
            className="bg-cblue-500 hover:bg-cblue-600 text-white px-3 py-1 rounded text-xs flex items-center"
            disabled={loading || !value.trim()}
          >
            {loading ? (
              <LoadingIndicator color="white" className="mr-1" size="xs"/>
            ) : null}
            {t('Add Note')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContextMenuTextAreaInput; 