import { useQuery } from '@apollo/client';
import { EtimProductType, ProductDetailsType } from '../../../../../../__generated__/graphql';
import {
  PRODUCT_DETAILS_QUERY,
} from '../../../../../../api/queries/quotations/details';
import { CURRENT_USER_QUERY } from '../../../../../../api/queries/users';
import { PRODUCT_DETAILS_ROW_HEIGHT } from '../../../../../../constants';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { LoadingIndicator } from '../../../../../../layout';
import ETIMData from '../../../../../shared/details/products/ETIMData';
import ExternalLinks from '../../../../../shared/details/products/ExternalLinks';
import PurchaseOptions from '../../../../../shared/details/products/PurchaseOptions';
import StorageProductData from '../../../../../shared/details/products/StorageProductData';

interface Props {
  externalProductId: ID,
  catalogId: ID,
  indentationWidth: number;
  depth: number;
  productId?: ID;
  maximumUncollapsedDepth: number;
}

export default function DefaultProductDetailsCompact(props: Props) {
  const { externalProductId, indentationWidth, depth, maximumUncollapsedDepth, catalogId, productId } = props;

  const {
    data: productData,
    loading: productLoading,
  } = useQuery(PRODUCT_DETAILS_QUERY, {
    variables: {
      externalId: externalProductId as string,
      catalogId: catalogId as string,
      product: productId as string,
    },
  });

  const { data: userData } = useQuery(CURRENT_USER_QUERY);
  const user = userData && userData.user;

  if (productLoading) {
    return (
      <div className='p-2.5 pr-5 border-[1px] border-t-0 rounded-bl rounded-br border-cgray-300 flex text-xs 2xl:text-sm text-cgray-400'>
        <LoadingIndicator className="w-full flex h-56 justify-center items-center" />
      </div>
    );
  } else if (!productLoading && productData && productData.productDetails && productData.productDetails.response) {
    const product = productData.productDetails.response;
    return (
      <div
        className='p-2.5 pr-5 flex border border-t-0 rounded-bl rounded-br border-cgray-300 text-xs 2xl:text-sm text-cgray-400 z-20 bg-white overflow-y-auto'
        style={{ height: `${PRODUCT_DETAILS_ROW_HEIGHT}px` }}
      >
        <div
          className="flex justify-end"
          style={{ width: `${getItemWidth(maximumUncollapsedDepth, indentationWidth, depth)}px` }}
        ></div>

        <div className="flex flex-col w-full gap-2 text-sm text-cgray-600 whitespace-normal max-w-full">
          <div className="flex max-w-full gap-2">
            <div className="flex-shrink-0 w-[40rem]">
              <StorageProductData 
                product={product as ProductDetailsType} 
                productId={productId as string} 
                isReadOnly={true}
              />
            </div>
            <div className="flex-grow min-w-0 overflow-x-auto">
              <PurchaseOptions product={product as ProductDetailsType} isReadOnly={true} />
            </div>
          </div>
          
          {product.etimProduct && (
            <ETIMData etimProduct={product.etimProduct as EtimProductType} />
          )}

          {user?.isSuperuser && (
            <ExternalLinks 
              quotationProductId={productId as string} 
              storageProductId={product.storageProduct?.id} 
            />
          )}
        </div>
      </div>
    );
  } else {
    return (<></>);
  }
}
