import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { ServiceValuesReport } from '../../../../__generated__/graphql';
import { areValuesDifferent } from '../../../../helpers/utils';
import { ValueCell, ValueType } from './ValueCell';

interface ServiceChangesTableProps {
  services: ServiceValuesReport[];
  selectedServices: string[];
  handleServiceSelect: (id: string) => void;
  handleSelectAllServices: () => void;
  handleFieldSelect: (itemId: string, field: string) => void;
  isFieldSelected: (itemId: string, field: string) => boolean;
  handleColumnSelect: (columnKey: string) => void;
}

interface ColumnConfig {
  key: string;
  width: string;
  label: string;
  shortLabel: string;
  valueType: ValueType;
}

export const ServiceChangesTable = ({ 
  services,
  selectedServices,
  handleServiceSelect,
  handleSelectAllServices,
  handleFieldSelect,
  isFieldSelected,
  handleColumnSelect,
}: ServiceChangesTableProps) => {
  const { t } = useTranslation();

  // Configuration array defining the service table columns:
  // - key: property name in the service data object
  // - width: CSS width class for the column
  // - label: full column name for tooltip
  // - shortLabel: abbreviated column name shown in header
  // - valueType: type of value for proper rendering and formatting
  const columns: ColumnConfig[] = [
    { key: 'manufacturingOverhead', width: 'w-60', label: 'Manufacturing Overhead', shortLabel: 'Man. OH', valueType: ValueType.MANUFACTURING_OVERHEAD },
    { key: 'administrativeOverhead', width: 'w-60', label: 'Administrative Overhead', shortLabel: 'Admin. OH', valueType: ValueType.ADMINISTRATIVE_OVERHEAD },
    { key: 'salesOverhead', width: 'w-60', label: 'Sales Overhead', shortLabel: 'Sales OH', valueType: ValueType.SALES_OVERHEAD },
  ];

  const hasColumnChanges = (columnKey: string) => {
    return services?.some(service => {
      const oldValue = service?.oldValues?.[columnKey as keyof typeof service.oldValues];
      const newValue = service?.newValues?.[columnKey as keyof typeof service.newValues];
      return areValuesDifferent(oldValue, newValue, columnKey, () => '-');
    });
  };

  const isColumnSelected = (columnKey: string) => {
    return services?.every(service => {
      const itemId = service?.itemId as string;
      const oldValue = service?.oldValues?.[columnKey as keyof typeof service.oldValues];
      const newValue = service?.newValues?.[columnKey as keyof typeof service.newValues];
      const hasChanges = areValuesDifferent(oldValue, newValue, columnKey, () => '-');
      return !hasChanges || isFieldSelected(itemId, columnKey);
    });
  };

  const tableContent = (
    <>
      <div className="w-full h-[52px] flex items-center gap-6 bg-cblue-100 border-b-2 border-t-2 border-cblue-500 mb-3 font-bold text-sm text-cgray-600">
        <div className='w-14 flex items-center justify-center'>
          <Tippy content={selectedServices.length > 0 ? t('Deselect All') : t('Select All')}>
            <input
              type="checkbox"
              className="h-5 w-5 rounded border-gray-300 text-cblue-500 focus:ring-0 focus:ring-offset-0 focus:outline-none cursor-pointer"
              checked={!!selectedServices?.length}
              onChange={handleSelectAllServices}
            />
          </Tippy>
        </div>
        <div className="w-16 flex flex-wrap items-center">
          <Tippy content={t('Position')}><span>{t('Position')}</span></Tippy>
        </div>
        {/* Render table headers for each column */}
        {columns.map(column => (
          <div key={column.key} className={`${column.width} flex flex-wrap items-center gap-2`}>
            <Tippy content={t(column.label)}>
              <div className="flex items-center gap-2">
                {hasColumnChanges(column.key) ? (
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-cblue-500 focus:ring-0 focus:ring-offset-0 focus:outline-none cursor-pointer"
                    checked={isColumnSelected(column.key)}
                    onChange={() => handleColumnSelect(column.key)}
                  />
                ) : null}
                <span>{t(column.shortLabel)}</span>
              </div>
            </Tippy>
          </div>
        ))}
      </div>
      
      <div className="flow-root w-full mt-4 h-full overflow-y-auto">
        <ul className="space-y-0">
          {/* Render each service row with its values and change indicators */}
          {services.map((service, index) => {
            const isServiceSelected = selectedServices.includes(service?.itemId as string);
            const itemId = service?.itemId as string;
            return (
              <li 
                key={index}
                className='relative flex items-center py-2 gap-6 border border-cgray-200 cursor-pointer'
              >
                <div className="relative w-14 flex items-center justify-center">
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-cblue-500 focus:ring-0 focus:ring-offset-0 focus:outline-none cursor-pointer"
                    checked={isServiceSelected}
                    onChange={() => {
                      handleServiceSelect(service?.itemId as string);
                    }}
                  />
                </div>
                <div className="w-16 h-16 flex items-start justify-start truncate break-all">
                  <ValueCell 
                    oldValue={service?.position ?? t('empty')} 
                    newValue={service?.position ?? t('empty')}
                    valueType={ValueType.POSITION}
                    isSelected={isServiceSelected}
                    description={service?.description ?? ''}
                  />
                </div>
                {columns.map(column => (
                  <div key={column.key} className={`${column.width} h-16 flex items-start justify-start`}>
                    <ValueCell 
                      oldValue={service?.oldValues?.[column.key as keyof typeof service.oldValues]} 
                      newValue={service?.newValues?.[column.key as keyof typeof service.newValues]}
                      valueType={column.valueType}
                      isSelected={isFieldSelected(itemId, column.key)}
                      onClick={() => handleFieldSelect(itemId, column.key)}
                    />
                  </div>
                ))}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );

  return (
    <div>
      {tableContent}
    </div>
  );
}; 