
import { GroupTemplateGroupFeatureType } from '../../../../../../../__generated__/graphql';

interface Props {
  groupFeatures: GroupTemplateGroupFeatureType[],
}

const ROGroupTemplateFeatures = (props: Props) => {
  const { groupFeatures } = props;

  return (
    <>
      {groupFeatures.map((feature) => {                
        return (
            <div key={feature?.id} className='cursor-default h-10 flex flex-col px-2 rounded-t border border-cgray-200'>
              <p className="text-2xs text-cgray-400">{feature?.etimClassFeature?.feature?.localizedDescription}{feature?.etimClassFeature?.unit ? `, ${feature?.etimClassFeature?.unit.abbreviation}` : ''}</p>
              <p className='text-sm overflow-auto grow'>{feature.valueLabels}</p>
            </div>
        );
      },
      )
      }
    </>
  );
};

export default ROGroupTemplateFeatures;