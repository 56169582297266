import type { UniqueIdentifier } from '@dnd-kit/core';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { TreeItem } from 'performant-array-to-tree';
import { 
  EtimClassFeatureType,
  QuotationGroupFeatureType,
  ItemTypeChoices,
  QuotationItemType,
  UnitType,
  FeatureType,
} from '../__generated__/graphql';
import { FlattenedItem } from '../components/shared/dnd/types';
import { GroupCollapseState, MaxUncollapsedDepthSource, SelectedColumnName } from '../constants';
import { getAllParentIds, getAllChildIds } from '../helpers/utils';

interface TargetOrder {
  targetOrder?: number
}

interface FeatureEditType {
  item: QuotationGroupFeatureType,
  groupId: string,
}

export interface QuotationGroupFeature {
  feature: FeatureType,
  value: string | number | boolean | null | number[],
  id?: ID,
  etimClassFeatureId?: ID,
  unit?: UnitType,
}

// Visible item information
interface VisibleItemInfo {
  item: FlattenedItem;
  top: number;
  height: number;
}

interface QuotationState {
  selectedGroupFeature?: QuotationGroupFeature,
  copiedItems: FlattenedItem[],
  selectedItem: FlattenedItem | null,
  selectedItems: FlattenedItem[],
  selectedColumn: SelectedColumnName | null,
  pasteSearchResultsBelowSelectedItem: boolean, 
  itemsToCut: FlattenedItem[],
  itemToSinglePasteBelow: { 
    parentId: UniqueIdentifier | null;
    order?: number;
    externalId: string;
    id: UniqueIdentifier
  },
  justPastedItemsIds: UniqueIdentifier[],
  currentQuotationItems: QuotationItemType[],
  previousQuotationItems: QuotationItemType[],
  maximumQuotationUncollapsedDepth: number,
  maximumQuotationListStaticItemsUncollapsedDepth: number,
  maximumQuotationListDndItemsUncollapsedDepth: number,
  isEstimateMode: boolean,
  quotationItemsTreeLength: number,
  isLoadingUpdate: boolean,
  isGroupSuggestionOpened: boolean,
  isPresetSuggestionOpened: boolean,
  isInfoOverlayOpen: boolean,
  isProjectLogOverlayOpen: boolean,
  isCreateItemOverlayOpen: boolean,
  isCheckValuesOverlayOpen: boolean,
  groupItemIdForSuggestion: string,
  activeQuotationGroupId: string,
  groupFeatureIdToEdit: string,
  featureOptionToEdit: SelectOption | undefined,
  featureValueOptionToEdit: SelectOption | number | undefined,
  featureToEdit: EtimClassFeatureType | undefined,
  showDeleteModal: boolean,
  showCreateGroupModal: boolean,
  showCreateServiceModal: boolean,
  showGeneratePDFModal: boolean,
  showSelectFeatureModal: boolean,
  showDeleteFeatureModal: boolean,
  showCreateGTConfirmationModal: boolean,
  itemIdToCreateGTFrom: string,
  newGroupOrder?: number,
  newServiceOrder?: number,
  newProductOrder?: number,
  createNewItemInside: boolean,
  // treeItems is used for navigation through quotation using arrow keys
  treeItems: TreeItem[],
  openedDeatilsItems: ID[],
  // recentlyOpenedOrClosedDetailsItemId is used for scrolling to the most recently opened or closed details
  recentlyOpenedOrClosedDetailsItemId: ID,
  // recentlyToggledItemId is used for scrolling to the most recently toggled item
  recentlyToggledItemId: UniqueIdentifier | null;
  itemToCollapse: ID,
  itemToUncollapse: ID,
  collapsedItemsId: UniqueIdentifier[],
  groupCollapseState: GroupCollapseState,
  isQuotationReadOnly: boolean,
  productSuggestionQuantity: number,
  dndGroupId: UniqueIdentifier,
  isComposeMode: boolean,
  showFullSummary: boolean,
  quotationPDFName: string,
  isAnyGroupBeingExported: boolean,
  dndGroupScrollPosition: number,
  isNotesVisible: boolean,
  highlightedItemId: UniqueIdentifier | null;
  // Add this property for tracking visible items
  visibleItems: VisibleItemInfo[];
}

// Define the initial state using that type
const initialState: QuotationState = {
  copiedItems: [],
  selectedItem: null,
  selectedItems: [],
  selectedColumn: null,
  pasteSearchResultsBelowSelectedItem: true,
  itemsToCut: [],
  itemToSinglePasteBelow: { parentId: '', order: 0, externalId: '', id: '' },
  justPastedItemsIds: [],
  currentQuotationItems: [],
  previousQuotationItems: [],
  maximumQuotationUncollapsedDepth: 0,
  maximumQuotationListStaticItemsUncollapsedDepth: 0,
  maximumQuotationListDndItemsUncollapsedDepth: 0,
  isEstimateMode: false,
  quotationItemsTreeLength: 0,
  isLoadingUpdate: false,
  isGroupSuggestionOpened: false,
  isPresetSuggestionOpened: false,
  isInfoOverlayOpen: false,
  isProjectLogOverlayOpen: false,
  isCreateItemOverlayOpen: false,
  isCheckValuesOverlayOpen: false,
  groupItemIdForSuggestion: '',
  activeQuotationGroupId: '',
  groupFeatureIdToEdit: '',
  featureOptionToEdit: undefined,
  featureValueOptionToEdit: undefined,
  featureToEdit: undefined,
  showDeleteModal: false,
  showCreateGroupModal: false,
  showCreateServiceModal: false,
  showGeneratePDFModal: false,
  showSelectFeatureModal: false,
  showDeleteFeatureModal: false,
  showCreateGTConfirmationModal: false,
  itemIdToCreateGTFrom: '',
  createNewItemInside: false,
  treeItems: [],
  openedDeatilsItems: [],
  recentlyOpenedOrClosedDetailsItemId: '',
  itemToCollapse: '',
  itemToUncollapse: '',
  collapsedItemsId: [],
  // collapse all groups by default
  groupCollapseState: GroupCollapseState.MIXED,
  isQuotationReadOnly: false,
  productSuggestionQuantity: 1,
  dndGroupId: '',
  isComposeMode: false,
  showFullSummary: false,
  quotationPDFName: '',
  isAnyGroupBeingExported: false,
  dndGroupScrollPosition: 0,
  recentlyToggledItemId: null,
  isNotesVisible: false,
  highlightedItemId: null,
  // Initialize visibleItems as an empty array
  visibleItems: [],
};

const quotationSlice = createSlice({
  name: 'quotation',
  initialState: initialState,
  reducers: {
    setSelectedGroupFeature: (state, action: PayloadAction<QuotationGroupFeature | undefined>) => {
      state.selectedGroupFeature = action.payload;
    },
    setCopiedItems: (state, action: PayloadAction<FlattenedItem[]>) => {
      state.copiedItems = action.payload;
    },
    setItemsToCut: (state, action: PayloadAction<FlattenedItem[]>) => {
      state.itemsToCut = action.payload;
    },
    setSelectedItem: (state, action: PayloadAction<FlattenedItem>) => {
      state.selectedItems = [];
      state.selectedItem = action.payload;
      if (action.payload.itemType === ItemTypeChoices.Group) {
        state.pasteSearchResultsBelowSelectedItem = false;
      } else {
        state.pasteSearchResultsBelowSelectedItem = true;
      }
    },
    setSelectedColumn: (state, action: PayloadAction<SelectedColumnName | null>) => {
      state.selectedColumn = action.payload;
    },
    setPasteSearchResultsBelowSelectedItem: (state, action: PayloadAction<boolean>) => {
      state.pasteSearchResultsBelowSelectedItem = action.payload;
    },
    unsetCopiedItems: (state) => {
      state.copiedItems = [];
    },
    unsetItemsToCut: (state) => {
      state.itemsToCut = [];
    },
    unsetSelectedItem: (state) => {
      state.selectedItem = null;
    },
    setItemToSinglePasteBelow: (state, action: PayloadAction<FlattenedItem>) => {
      const createNewItemInside = state.createNewItemInside;
      const orderToPaste = createNewItemInside ? action.payload.children.length : action.payload.order as number + 1;
      state.itemToSinglePasteBelow.parentId = action.payload.parentId;
      state.itemToSinglePasteBelow.order = orderToPaste;
      state.itemToSinglePasteBelow.externalId = action.payload.product?.externalId as string;
      state.itemToSinglePasteBelow.id = action.payload.id;
    },
    unsetItemToSinglePasteBelow: (state) => {
      state.itemToSinglePasteBelow.parentId = '';
      state.itemToSinglePasteBelow.order = 0;
      state.itemToSinglePasteBelow.externalId = '';
      state.itemToSinglePasteBelow.id = '';
    },
    setJustPastedItemsIds: (state, action: PayloadAction<UniqueIdentifier[]>) => {
      state.justPastedItemsIds = action.payload;
    },
    setCurrentQuotationItems: (state, action: PayloadAction<QuotationItemType[]>) => {
      if (state.currentQuotationItems.length !== 0) {
        // Store current items as previous
        state.previousQuotationItems = state.currentQuotationItems;
        // Set new current items
        state.currentQuotationItems = action.payload;  
        // Calculate new items by comparing with previous state
        const previousIds = new Set(state.previousQuotationItems.map(item => item.id));
        const newItemIds = action.payload
          .filter(item => !previousIds.has(item.id))
          .map(item => item.id);
        state.justPastedItemsIds = newItemIds;
      } else {
        // If it is first render, just set currentQuotationItems to action.payload
        state.currentQuotationItems = action.payload;
      }
    },
    setPreviousQuotationItems: (state, action: PayloadAction<QuotationItemType[]>) => {
      state.previousQuotationItems = action.payload;
    },
    setMaximumQuotationUncollapsedDepth: (state, action: PayloadAction<{ 
      maxDepth: number, 
      source?: MaxUncollapsedDepthSource,
    }>) => {
      if (action.payload.source) {
        if (action.payload.source === MaxUncollapsedDepthSource.QuotationListStaticItem) {
          state.maximumQuotationListStaticItemsUncollapsedDepth = action.payload.maxDepth;
        }
        if (action.payload.source === MaxUncollapsedDepthSource.QuotationListDndItem) {
          state.maximumQuotationListDndItemsUncollapsedDepth = action.payload.maxDepth;
        }

        state.maximumQuotationUncollapsedDepth = Math.max(
          state.maximumQuotationListStaticItemsUncollapsedDepth, 
          state.maximumQuotationListDndItemsUncollapsedDepth,
        );
      } else {
        state.maximumQuotationUncollapsedDepth = action.payload.maxDepth;
      }
    },
    setEstimateMode: (state, action: PayloadAction<boolean>) => {
      state.isEstimateMode = action.payload;
    },
    setQuotationItemsTreeLength: (state, action: PayloadAction<number>) => {
      state.quotationItemsTreeLength = action.payload;
    },
    setIsLoadingUpdate: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUpdate = action.payload;
    },
    setIsGroupSuggestionOpened: (state, action: PayloadAction<boolean>) => {
      state.isGroupSuggestionOpened = action.payload;
    },
    setIsPresetSuggestionOpened: (state, action: PayloadAction<boolean>) => {
      state.isPresetSuggestionOpened = action.payload;
    },
    setGroupItemIdForSuggestion: (state, action: PayloadAction<string>) => {
      state.groupItemIdForSuggestion = action.payload;
    },
    setActiveQuotationGroupId: (state, action: PayloadAction<string>) => {
      state.activeQuotationGroupId = action.payload;
    },
    setFeatureEdit: (state, action: PayloadAction<FeatureEditType | undefined>) => {
      if (action) {
        state.activeQuotationGroupId = action.payload?.groupId as string;
        state.groupFeatureIdToEdit = action.payload?.item.id as string;
        state.featureOptionToEdit = {
          value: action.payload?.item.etimClassFeature?.feature?.id,
          label: action.payload?.item.etimClassFeature?.feature?.localizedDescription as string,
        };
        state.featureValueOptionToEdit = { value: action.payload?.item.value, label: action.payload?.item?.valueLabels?.toString() as string };
        state.featureToEdit = action.payload?.item.etimClassFeature as EtimClassFeatureType;
      } else {
        state.activeQuotationGroupId = '';
        state.groupFeatureIdToEdit = '';
        state.featureOptionToEdit = undefined;
        state.featureValueOptionToEdit = undefined;
        state.featureToEdit = undefined;
      }
    },
    setGroupFeatureIdToEdit: (state, action: PayloadAction<string>) => {
      state.groupFeatureIdToEdit = action.payload;
    },
    setShowDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.showDeleteModal = action.payload;
    },
    setShowCreateGroupModal: (state, action: PayloadAction<boolean>) => {
      state.showCreateGroupModal = action.payload;
    },
    setShowCreateServiceModal: (state, action: PayloadAction<boolean>) => {
      state.showCreateServiceModal = action.payload;
    },
    openCreateServiceModal: (state, action: PayloadAction<TargetOrder>) => {
      state.newServiceOrder = action.payload.targetOrder;
      state.showCreateServiceModal = true;
    },
    setShowGeneratePDFModal: (state, action: PayloadAction<boolean>) => {
      state.showGeneratePDFModal = action.payload;
    },
    openCreateGroupModal: (state, action: PayloadAction<TargetOrder>) => {
      state.newGroupOrder = action.payload.targetOrder;
      state.showCreateGroupModal = true;
    },
    setShowSelectFeatureModal: (state, action: PayloadAction<boolean>) => {
      state.showSelectFeatureModal = action.payload;
    },
    setShowDeleteFeatureModal: (state, action: PayloadAction<boolean>) => {
      state.showDeleteFeatureModal = action.payload;
    },
    setShowCreateGTConfirmationModal: (state, action: PayloadAction<boolean>) => {
      state.showCreateGTConfirmationModal = action.payload;
    },
    setItemIdToCreateGTFrom: (state, action: PayloadAction<string>) => {
      state.itemIdToCreateGTFrom = action.payload;
    },
    setCreateNewItemInside: (state, action: PayloadAction<boolean>) => {
      state.createNewItemInside = action.payload;
    },
    setTreeItems: (state, action: PayloadAction<TreeItem[]>) => {
      state.treeItems = action.payload;
    },
    addOpenedDeatilsItems: (state, action: PayloadAction<ID>) => {
      state.recentlyOpenedOrClosedDetailsItemId = action.payload;
      state.openedDeatilsItems = [...state.openedDeatilsItems, action.payload];
      state.recentlyToggledItemId = null;
    },
    removeOpenedDeatilsItems: (state, action: PayloadAction<ID>) => {
      state.recentlyOpenedOrClosedDetailsItemId = action.payload;
      const result = state.openedDeatilsItems.filter(item => item !== action.payload);
      state.openedDeatilsItems = [...result];
      state.recentlyToggledItemId = null;
    },
    setItemToCollapse: (state, action: PayloadAction<ID>) => {
      state.itemToCollapse = action.payload;
      state.groupCollapseState = GroupCollapseState.MIXED;
    },
    setItemToUncollapse: (state, action: PayloadAction<ID>) => {
      state.itemToUncollapse = action.payload;
      state.groupCollapseState = GroupCollapseState.MIXED;
    },
    setCollapsedItemsId: (state, action: PayloadAction<UniqueIdentifier[]>) => {
      // If payload is empty array, clear all collapsed items
      if (!action.payload || action.payload.length === 0) {
        state.collapsedItemsId = [];
        state.recentlyOpenedOrClosedDetailsItemId = '';
        return;
      }

      // For each ID in the payload
      action.payload.forEach(id => {
        if (!state.collapsedItemsId.includes(id)) {
          // If ID is not in the list, add it
          state.collapsedItemsId.push(id);
        } else {
          // If ID is in the list, remove it
          state.collapsedItemsId = state.collapsedItemsId.filter(item => item !== id);
        }
      });
      state.recentlyOpenedOrClosedDetailsItemId = '';
    },
    setCollapseAllGroupsState: (state, action: PayloadAction<GroupCollapseState>) => {
      state.groupCollapseState = action.payload;
    },
    setIsInfoOverlayOpen: (state, action: PayloadAction<boolean>) => {
      state.isInfoOverlayOpen = action.payload;
    },
    setIsProjectLogOverlayOpen: (state, action: PayloadAction<boolean>) => {
      state.isProjectLogOverlayOpen = action.payload;
    },
    setIsCreateItemOverlayOpen: (state, action: PayloadAction<boolean>) => {
      state.isCreateItemOverlayOpen = action.payload;
    },
    setIsCheckValuesOverlayOpen: (state, action: PayloadAction<boolean>) => {
      state.isCheckValuesOverlayOpen = action.payload;
    },
    setNewProductOrder: (state, action: PayloadAction<number>) => {
      state.newProductOrder = action.payload;
    },
    setIsQuotationReadOnly: (state, action: PayloadAction<boolean>) => {
      state.isQuotationReadOnly = action.payload;
    },
    setProductSuggestionQuantity: (state, action: PayloadAction<number>) => {
      state.productSuggestionQuantity = action.payload;
    },
    setDndGroupId: (state, action: PayloadAction<UniqueIdentifier>) => {
      if (state.dndGroupId !== action.payload) {
        state.dndGroupId = action.payload;
      } else {
        state.dndGroupId = '';
      }
    },
    setIsComposeMode: (state, action: PayloadAction<boolean>) => {
      state.isComposeMode = action.payload;
    },
    setShowFullSummary: (state, action: PayloadAction<boolean>) => {
      state.showFullSummary = action.payload;
    },
    setQuotationPDFName: (state, action: PayloadAction<string>) => {
      state.quotationPDFName = action.payload;
    },
    setIsAnyGroupBeingExported: (state, action: PayloadAction<boolean>) => {
      state.isAnyGroupBeingExported = action.payload;
    },
    addToSelection: (state, action: PayloadAction<FlattenedItem>) => {
      // Get all parent IDs for the clicked item
      const allParentIds = getAllParentIds(state.treeItems as FlattenedItem[], action.payload.id);
      
      // Filter out parent IDs that are already in selectedItems or selectedItem
      const parentIdsToRemove = allParentIds.filter(parentId => 
        state.selectedItems.some(item => item.id === parentId) || 
        state.selectedItem?.id === parentId,
      );

      // Get all child IDs if the clicked item is a group
      const childIdsToRemove = action.payload.itemType === ItemTypeChoices.Group 
        ? getAllChildIds(state.treeItems as FlattenedItem[], action.payload.id)
        : [];

      // Add selectedItem to selectedItems if it exists and isn't already there
      if (state.selectedItem && !state.selectedItems.find(item => item.id === state.selectedItem?.id)) {
        state.selectedItems.push(state.selectedItem);
      }

      // Toggle the clicked item
      if (!state.selectedItems.find(item => item.id === action.payload.id)) {
        state.selectedItems.push(action.payload);
      } else {
        state.selectedItems = state.selectedItems.filter(item => item.id !== action.payload.id);
      }

      // Remove any parents that are already selected and all children (if it's a group)
      state.selectedItems = state.selectedItems.filter(item => 
        !parentIdsToRemove.includes(item.id) && 
        !childIdsToRemove.includes(item.id),
      );

      // Update selectedItem based on selectedItems length
      if (state.selectedItems.length === 1) {
        state.selectedItem = state.selectedItems[0];
      } else {
        state.selectedItem = null;
      }
    },
    clearSelection: (state) => {
      state.selectedItems = [];
    },
    setDndGroupScrollPosition: (state, action: PayloadAction<number>) => {
      state.dndGroupScrollPosition = action.payload;
    },
    setRecentlyToggledItemId: (state, action: PayloadAction<UniqueIdentifier | null>) => {
      state.recentlyOpenedOrClosedDetailsItemId = '';
      state.recentlyToggledItemId = action.payload;
    },
    setNotesVisibility: (state, action: PayloadAction<boolean>) => {
      state.isNotesVisible = action.payload;
    },
    setHighlightedItemId: (state, action: PayloadAction<UniqueIdentifier | null>) => {
      state.highlightedItemId = action.payload;
    },
    // Add this reducer for updating visible items
    setVisibleItems: (state, action: PayloadAction<VisibleItemInfo[]>) => {
      state.visibleItems = action.payload;
    },
  },
});

export const { 
  setSelectedGroupFeature, setCopiedItems, setSelectedItem, unsetCopiedItems, unsetSelectedItem,
  setMaximumQuotationUncollapsedDepth, setEstimateMode, setItemToSinglePasteBelow,
  setQuotationItemsTreeLength, unsetItemToSinglePasteBelow, setIsLoadingUpdate,
  setIsGroupSuggestionOpened, setGroupItemIdForSuggestion, setShowDeleteModal,
  setShowCreateGroupModal, openCreateGroupModal,  
  setShowCreateServiceModal, openCreateServiceModal, setCreateNewItemInside, setTreeItems,
  addOpenedDeatilsItems, removeOpenedDeatilsItems, setItemToCollapse, setItemToUncollapse,
  setIsInfoOverlayOpen, setIsProjectLogOverlayOpen, setIsQuotationReadOnly, setIsCreateItemOverlayOpen, setNewProductOrder,
  setProductSuggestionQuantity, setDndGroupId, setItemsToCut, unsetItemsToCut, 
  setCollapsedItemsId, setIsComposeMode, setShowFullSummary, setPasteSearchResultsBelowSelectedItem,
  setShowSelectFeatureModal, setFeatureEdit, setActiveQuotationGroupId, setShowDeleteFeatureModal,
  setGroupFeatureIdToEdit, setShowGeneratePDFModal, setQuotationPDFName, setIsAnyGroupBeingExported,
  setShowCreateGTConfirmationModal, setItemIdToCreateGTFrom, setIsPresetSuggestionOpened, setSelectedColumn,
  addToSelection, clearSelection, setDndGroupScrollPosition, setRecentlyToggledItemId, setCollapseAllGroupsState,
  setCurrentQuotationItems, setPreviousQuotationItems, setJustPastedItemsIds, setIsCheckValuesOverlayOpen,
  setNotesVisibility, setHighlightedItemId, setVisibleItems,
} = quotationSlice.actions;

export default quotationSlice.reducer;