import { useMutation, useQuery } from '@apollo/client';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useMemo } from 'react';
import { InputMaybe, QuotationGroupType } from '../../__generated__/graphql';
import { UPDATE_GROUP_SEARCH_WEIGHT } from '../../api/mutations/quotations/group';
import { FAVOURITE_GROUPS_QUERY } from '../../api/queries/searchRecords';
import { useDebounceValue } from '../../helpers/customHooks';
import { useAppSelector } from '../../helpers/reduxHooks';
import { LoadingIndicator } from '../../layout';
import { DraggableFavouriteGroupItem } from './DraggableFavouriteGroupItem';

export function DraggableFavoriteGroups() {
  const searchFavouriteGroupsInput = useAppSelector(state => state.search.searchFavouriteGroupsInput);
  const debouncedSearchFavouriteGroupsInput = useDebounceValue(searchFavouriteGroupsInput, 500);
  
  const { data: favouriteGroupsData, loading: favouriteGroupsLoading } = useQuery(FAVOURITE_GROUPS_QUERY, {
    variables: {
      searchInput: debouncedSearchFavouriteGroupsInput as InputMaybe<string>,
    },
    fetchPolicy: 'cache-and-network',
  });

  const items = useMemo(() => 
    favouriteGroupsData?.favouriteGroups?.response as QuotationGroupType[] || [],
  [favouriteGroupsData?.favouriteGroups?.response],
  );

  const [updateSearchWeight] = useMutation(UPDATE_GROUP_SEARCH_WEIGHT, {
    refetchQueries: [{ 
      query: FAVOURITE_GROUPS_QUERY,
      variables: { searchInput: debouncedSearchFavouriteGroupsInput as InputMaybe<string> },
    }],
    awaitRefetchQueries: true,
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (over && active.id !== over.id) {
      const newIndex = items.findIndex((item: QuotationGroupType) => item.id === over.id);

      updateSearchWeight({
        variables: {
          group: active.id as string,
          position: newIndex,
        },
      });
    }
  };

  if (favouriteGroupsLoading && items.length === 0) {
    return <LoadingIndicator size="md" />;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items?.map((item: QuotationGroupType) => item.id) || []}
        strategy={verticalListSortingStrategy}
      >
        <div className="flex flex-col gap-2">
          {items?.map((item: QuotationGroupType) => (
            <DraggableFavouriteGroupItem
              key={item.id}
              groupId={item.id}
              description={item.description}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
} 