import { useMutation, useQuery } from '@apollo/client';
import { IconTrash, IconPencil } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DELETE_QUOTATION_ITEM_NOTE_MUTATION } from '../../../api/mutations/quotations/note';
import { QUOTATION_ITEM_NOTES_QUERY } from '../../../api/queries/quotations/notes';
import { CURRENT_USER_QUERY } from '../../../api/queries/users';
import { ReactComponent as IconRobot } from '../../../assets/icons/Robot.svg';
import { useAppDispatch } from '../../../helpers/reduxHooks';
import { LoadingIndicator } from '../../../layout';
import { setErrorAlert } from '../../../redux/alertSlice';
import UserAvatar from '../projectLog/UserAvatar';
import QuotationItemNoteForm from './QuotationItemNoteForm';
export interface Author {
  firstName: string;
  lastName: string;
  email: string;
  fullName?: string;
  id?: string;
}

interface QuotationItemNoteCardProps {
  id: string;
  body: string;
  author?: Author | null;
  createdAt: string;
  compact?: boolean; // For styling differences between main notes and replies
  className?: string; // Additional classes for the container
  showDeleteButton?: boolean; // Whether to show the delete button
  itemId?: string; // The item ID this note belongs to
  parentId?: string; // Parent note ID if this is a reply
}

const QuotationItemNoteCard: React.FC<QuotationItemNoteCardProps> = ({
  id,
  body,
  author,
  createdAt,
  compact = false,
  className = '',
  showDeleteButton = true,
  itemId,
  parentId,
}) => {
  const dispatch = useAppDispatch();
  const { quotationId } = useParams();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  
  // Get current user to check ownership
  const { data: userData } = useQuery(CURRENT_USER_QUERY);
  const currentUser = userData?.user;
  
  // Check if current user is the author of this note
  const isAuthor = currentUser?.id && author?.id && currentUser.id === author.id;

  // Set up delete mutation
  const [deleteNote] = useMutation(DELETE_QUOTATION_ITEM_NOTE_MUTATION, {
    variables: {
      note: id,
    },
    refetchQueries: [
      {
        query: QUOTATION_ITEM_NOTES_QUERY,
        variables: {
          quotation: quotationId,
        },
      },
    ],
  });

  // Format the date for display
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString([], { hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' });
  };

  // Handle delete button click
  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDeleting(true);
    try {
      await deleteNote();
    } catch (error) {
      dispatch(setErrorAlert([`${t('Error deleting note')}: ${error}`]));
      setIsDeleting(false);
    }
  };

  // Handle edit button click
  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  // Cancel editing
  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const avatarSize = compact ? 'w-7 h-7' : 'w-8 h-8';
  const contentPadding = compact ? 'pl-9' : 'pl-10';
  const authorName = author ? author.fullName : 'Calcutron';

  // If in edit mode, render the edit form
  if (isEditing) {
    return (
      <div className={`py-3 bg-white rounded border border-cgray-200 ${className} ${compact ? 'mb-3 px-3' : 'mb-4 pl-3'}`}>
        {/* Author info row */}
        <div className="flex items-center mb-2">
          <div className="flex items-center">
            {author ? (
              <div className={`${avatarSize} mr-2`}>
                <UserAvatar user={author} size={'md'} shadowSize={'md'} />
              </div>
            ) : (
              <div className={`${avatarSize} mr-2`}>
                <IconRobot className={`${avatarSize} fill-cblue-500`} />
              </div>
            )}
            <span className="font-medium text-sm ml-2">{authorName}</span>
          </div>
        </div>
        
        {/* Reuse note create form for editing */}
        <QuotationItemNoteForm
          note={{ 
            id, 
            body,
            itemId,
            parentId,
          }}
          onCancel={handleCancelEdit}
          compact={compact}
        />
      </div>
    );
  }

  // Regular display mode
  return (
    <div 
      key={id} 
      className={`py-3 bg-white rounded border border-cgray-200 ${className} ${compact ? 'mb-3 px-3' : 'mb-4 pl-3'}`}
    >
      {/* Author info and date row */}
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          {author ? (
            <div className={`${avatarSize} mr-2`}>
              <UserAvatar user={author} size={'md'} shadowSize={'md'} />
            </div>
          ) : (
            <div className={`${avatarSize} mr-2`}>
              <IconRobot className={`${avatarSize} fill-cblue-500`} />
            </div>
          )}
          <span className="font-medium text-sm ml-2">{authorName}</span>
        </div>
        <div className="flex items-center text-xs text-cgray-500">
          {formatDate(createdAt)}
          
          {/* Edit button - only visible for author */}
          {showDeleteButton && isAuthor && !isDeleting && (
            <button 
              type="button" 
              className="ml-2 text-cgray-500 hover:text-cblue-500 transition-colors"
              onClick={handleEdit}
              title={t('Edit note')}
            >
              <IconPencil size={16} stroke={1.5} />
            </button>
          )}
          
          {/* Delete button - only visible for author and if showDeleteButton is true */}
          {showDeleteButton && isAuthor && !isDeleting && (
            <button 
              type="button" 
              className="ml-2 text-cgray-500 hover:text-cred-500 transition-colors"
              onClick={handleDelete}
              title={t('Delete note')}
            >
              <IconTrash size={16} stroke={1.5} />
            </button>
          )}
          
          {/* Loading indicator during deletion */}
          {isDeleting && (
            <div className="ml-2">
              <LoadingIndicator color="gray-500" size="xs" />
            </div>
          )}
        </div>
      </div>
      
      {/* Note content */}
      <div className={`text-sm text-cgray-600 my-2 ml-2 ${contentPadding}`}>
        {body.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default QuotationItemNoteCard; 