import { useQuery } from '@apollo/client';
import { IconArrowBarRight, IconArrowBarToLeft } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputMaybe,
  SearchResponsePageType,
} from '../../../__generated__/graphql';
import {
  SEARCH_BY_FAVOURITE_GROUP_QUERY,
  FAVOURITE_GROUPS_QUERY,
} from '../../../api/queries/searchRecords';
import { useDebounceValue } from '../../../helpers/customHooks';
import { useAppSelector } from '../../../helpers/reduxHooks';
import FavouriteGroupSearchInput from '../../shared/FavouriteGroupSearchInput';
import { GroupFilterItem } from '../../shared/GroupsFilterItem';


interface Props {
  setPageData: any,
  setSearchRecordsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function GroupFilter(props: Props) {
  const { setPageData, setSearchRecordsLoading } = props;
  const [isGroupsOpen, setIsGroupsOpen] = useState(true);
  const { t } = useTranslation();
  const isComposeMode = useAppSelector(state => state.quotation.isComposeMode);


  const selectedFavouriteGroupId = useAppSelector(state => state.search.selectedFavouriteGroupId);
  const searchInputValue = useAppSelector(state => state.search.searchInputValue);
  const searchFavouriteGroupsInput = useAppSelector(state => state.search.searchFavouriteGroupsInput);
  const debouncedSearchFavouriteGroupsInput = useDebounceValue(searchFavouriteGroupsInput, 500);

  const {
    data: searchRecordsData,
    loading: searchRecordsLoading,
  } = useQuery(SEARCH_BY_FAVOURITE_GROUP_QUERY, {
    variables: {
      group: selectedFavouriteGroupId as string,
      searchInput: searchInputValue as InputMaybe<InputMaybe<string> | InputMaybe<string>[]>,
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: groupsData,
  } = useQuery(FAVOURITE_GROUPS_QUERY, {
    variables: {
      searchInput: debouncedSearchFavouriteGroupsInput as string,
    },
    skip: !isComposeMode,
    fetchPolicy: 'network-only',
  });


  useEffect(() => {
    if (searchRecordsData
      //@ts-ignore
      && searchRecordsData[Object.keys(searchRecordsData)[0]].response) {
      //@ts-ignore
      setPageData(searchRecordsData[Object.keys(searchRecordsData)[0]].response as SearchResponsePageType);
    }
  }, [searchRecordsData, setPageData]);

  useEffect(() => {
    setSearchRecordsLoading(searchRecordsLoading);
  }, [searchRecordsLoading, setSearchRecordsLoading]);

  useEffect(() => {
    if (!selectedFavouriteGroupId) {
      setPageData([]); 
    }
  }, [setPageData, selectedFavouriteGroupId]);

  return (
    <>
      {isGroupsOpen ?
        <div className="flex flex-col">
          <div className="sticky top-0 z-20 flex items-center bg-cblue-100 border-t-2 border-b-2 border-cblue-500 pt-3 pb-3 mb-3 font-bold text-sm text-cgray-600">
            <IconArrowBarToLeft
              className="w-6 h-6 ml-4 cursor-pointer"
              onClick={() => setIsGroupsOpen(false)}
            />
            <div className="w-36 mt-0.5 mb-0.5 h-5 ml-2">
              {t('Favorite Groups')}
            </div>
          </div>
          <div className="grow w-72 pl-2 flex flex-col gap-2 pr-2 border-r-2 border-cgray-300 h-full max-h-screen overflow-y-scroll overflow-x-clip">
            {groupsData &&
              groupsData.favouriteGroups &&
              groupsData.favouriteGroups.response && (
                <>
                  <FavouriteGroupSearchInput />
                  {groupsData.favouriteGroups.response.map((item) => (
                    <GroupFilterItem
                      key={item?.id}
                      groupId={item?.id}
                      description={item?.description as string}
                    />
                  ))}
                </>
            )
            }
          </div>
        </div>
        :
        <div onClick={() => setIsGroupsOpen(true)}>
          <div className="sticky top-0 z-20 flex items-center bg-cblue-100 border-t-2 border-b-2 border-cblue-500 pt-3 pb-3 mb-3 font-bold text-sm text-cgray-600">
            <IconArrowBarRight className="w-6 h-6 ml-2 cursor-pointer" />
          </div>
          <div
            className="grow w-12 flex flex-col justify-center gap-2 border-r-2 border-cgray-300 h-full max-h-screen overflow-y-scroll overflow-x-clip cursor-pointer"
          >
            <div className='-rotate-90 whitespace-nowrap uppercase'>{t('Favorite Groups')}</div>
          </div>
        </div>
      }
    </>
  );
}
