import { gql } from '../../../__generated__/gql';

export const COPY_QUOTATION_ITEM = gql(`
mutation copyQuotationItems(
  $items: [ID]!, 
  $order: Int!, 
  $parent: ID, 
  $quotation: ID!, 
  ){
    copyQuotationItems(
      input: {
        items: $items
        order: $order
        parent: $parent
        quotation: $quotation
      }
    ){
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const DELETE_QUOTATION_ITEMS_MUTATION = gql(`
mutation deleteQuotationItems(
  $items: [ID]!
  $quotation: ID!
  ){
    deleteQuotationItems(
      input: {
        items: $items
        quotation: $quotation
      }
    )
  { 
    response
    errors{
      field
      messages
    }
  }
}
`);

export const MOVE_QUOTATION_ITEM_MUTATION = gql(`
mutation moveQuotationItems(
  $order: Int!,
  $parent: ID,
  $items: [ID]!,
  $quotation: ID!,
  ){
    moveQuotationItems(
      input: {
        order: $order
        parent: $parent
        items: $items
        quotation: $quotation
      }
    )
  { 
    response
    errors{
      field
      messages
    }
  }
}
`);

export const UPDATE_QUOTATION_ITEM_POSITION = gql(`
  mutation updateQuotationItemPosition(
    $item: ID!,
    $position: String!,
    $quotation: ID!,
    ){
      updateQuotationItemPosition(
        input: {
          item: $item,
          position: $position,
          quotation: $quotation
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const ADD_QUOTATION_ITEMS_MUTATION = gql(`
mutation addQuotationItemsMutation(
  $order: Int!
  $quantity: Int
  $quotation: ID!
  $parent: ID
  $items: [AddItemType]!
  ){
    addQuotationItems(
      input: {
        items: $items
        order: $order
        quotation: $quotation
        parent: $parent
        quantity: $quantity
      }
    )
  { 
    response
    errors{
      field
      messages
    }
  }
}
`);

export const UPDATE_ITEMS_QUANTITY = gql(`
  mutation updateQuotationItemsQuantity(
    $quotation: ID!,
    $quantity: Decimal!
		$items: [ID!]!,
    ){
      updateQuotationItemsQuantity(
        input: {
          quotation: $quotation,
          quantity: $quantity,
          items: $items,
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);