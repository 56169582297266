
import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { IconMessage2Up } from '@tabler/icons-react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { UserType } from '../../../__generated__/graphql';
import { QUOTATION_NOTE_MUTATION } from '../../../api/mutations/quotations/note';
import { classNames } from '../../../helpers/utils';
import { LoadingIndicator } from '../../../layout';
import UserAvatar from './UserAvatar';

interface Props {
  user: UserType;
  quotationId: string;
  refetchQueries: InternalRefetchQueriesInclude;
}

const CreateNoteForm = (props: Props) => {
  const { user, quotationId, refetchQueries } = props;
  const { t } = useTranslation();

  const [
    noteMutation,
    {
      loading: noteLoading,
    }] = useMutation(QUOTATION_NOTE_MUTATION, {
    refetchQueries: refetchQueries,
  });


  const schema = yup.object({
    body: yup.string().required('Requried'),
  });

  const {
    handleSubmit,
    handleChange,
    values: formikValues,
    resetForm,
  } = useFormik({
    initialValues: {
      body: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        quotation: quotationId,
        body: values.body,
      };
      noteMutation({
        variables,
      });
      resetForm();
    },
  });

  return (
    <div className="mt-6 w-full flex gap-x-3">
      <UserAvatar user={user as UserType} />
      <form onSubmit={handleSubmit} className="relative flex-auto">
        <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-cgray-300 focus-within:ring-2 focus-within:ring-cblue-500">
          <label htmlFor="comment" className="sr-only">
            {t('Add your note here')}
          </label>
          <textarea
            rows={3}
            name="body"
            id="body"
            className="block w-full resize-none border-0 bg-transparent py-1.5 text-cgray-900 placeholder:text-cgray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder={t('Add your note here')}
            value={formikValues.body}
            onChange={handleChange}
          />
        </div>

        <div className="absolute inset-x-0 bottom-0 flex justify-end py-2 pl-3 pr-2">
          <button
            type="submit"
            disabled={noteLoading}
            className={classNames(noteLoading 
              ? 'bg-cgray-300 text-cgray-300' 
              : 'bg-cblue-500 hover:bg-cblue-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100', 
            'relative w-20 h-10 flex justify-center px-4 py-2 border border-transparent rounded text-base font-medium text-white')}
          >
            {noteLoading 
              ? <LoadingIndicator color="white" className="flex justify-center items-center h-6 w-6" />
              : <IconMessage2Up className="h-5 w-5" />
            }
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateNoteForm;
