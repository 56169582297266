import { useMutation } from '@apollo/client';
import Tippy from '@tippyjs/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { CREATE_QUOTATION_PRODUCT } from '../../../../api/mutations/quotations/product';
import { QUOTATION_QUERY } from '../../../../api/queries/quotations/quotation';
import { useDebounceValue } from '../../../../helpers/customHooks';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { classNames } from '../../../../helpers/utils';
import { LoadingIndicator } from '../../../../layout';
import { Input } from '../../../../layout/inputs';
import SearchProductSuggestionsInput from '../../../../layout/inputs/SearchProductSuggestionsInput';
import { setSuccessAlert } from '../../../../redux/alertSlice';
import { setIsCreateItemOverlayOpen, setIsLoadingUpdate, setProductSuggestionQuantity } from '../../../../redux/quotationSlice';
import { setSearchInputValue } from '../../../../redux/searchSlice';

interface FormValues {
  description: string,
  price: number | string,
  quantity: number | string,
}

interface Props {
  parentId?: ID | null,
  searchRecordsByDescriptionLoading: boolean,
}

export default function CreateProductForm(props: Props) {
  const { 
    parentId, 
    searchRecordsByDescriptionLoading,
  } = props;
  const dispatch = useAppDispatch();
  const { quotationId } = useParams();
  const { t } = useTranslation();
  const targetOrder = useAppSelector(state => state.quotation.newProductOrder);
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [descriptionInputValue, setDescriptionInputValue] = useState<string>('');
  const debouncedSearchInput = useDebounceValue(descriptionInputValue, 1000) as string;

  const schema = yup.object({
    description: yup.string().trim(). required(`${t('Requried')}`),
    price: yup.number().positive(t('Number must be positive')).required(`${t('Requried')}`),
    quantity: yup.number().positive(t('Number must be positive')).integer(`${t('Quantity must contain only digits')}`).required(`${t('Requried')}`),
  });

  const initialValues: FormValues = {
    description: '',
    price: '',
    quantity: '',
  };
  const {
    handleChange,
    handleSubmit,
    values: formikValues,
    errors: formikErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      if (!isCreateDisabled && !searchRecordsByDescriptionLoading) {
        createProductMutation(
          {
            variables: {
              description: values.description,
              quotation: quotationId as string,
              order: targetOrder !== undefined ? targetOrder : 0,
              parent: parentId as string,
              quantity: +values.quantity,
              price: +values.price,
            },
            onError: () => {
              dispatch(setIsCreateItemOverlayOpen(false));
              dispatch(setIsLoadingUpdate(false));
            },
          },
        );
      }
    },
  });

  const [
    createProductMutation,
    {
      data: mutationData,
      loading: mutationLoading,
    }] = useMutation(CREATE_QUOTATION_PRODUCT, {
    refetchQueries: [{
      query: QUOTATION_QUERY,
      variables: {
        quotation: quotationId as string,
      },
      fetchPolicy: 'cache-first',
    }],
  });

  const searchSuggestions = (inputedValue: string) => {
    dispatch(setSearchInputValue(inputedValue.split(' ')));
  };

  useEffect(() => {
    setIsCreateDisabled(!formikValues.quantity || !formikValues.description || !formikValues.price);
  }, [formikValues.quantity, formikValues.description, formikValues.price]);

  useEffect(() => {
    if (mutationData && mutationData.createQuotationProduct && mutationData.createQuotationProduct.response) {
      dispatch(setSuccessAlert(
        [`${t('New product was successfully created')}`],
      ));
      dispatch(setIsCreateItemOverlayOpen(false));
    }
  }, [mutationData, dispatch, quotationId, t]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(mutationLoading));
  }, [dispatch, mutationLoading]);

  useEffect(() => {
    dispatch(setSearchInputValue(debouncedSearchInput.split(' ')));
  }, [debouncedSearchInput, dispatch]);

  return (
    <form className="px-8 w-full" onSubmit={handleSubmit}>
      <div className="space-y-8">
        
        <div className="flex flex-col gap-8 items-center justify-center border-b border-gray-900/10 pb-8 w-full">
          <div className="flex flex-col gap-8 w-full">
            <div className="relative">
              <SearchProductSuggestionsInput
                id="description"
                name="description"
                type="text"
                value={formikValues.description}
                //@ts-ignore
                errorMessage={touched.description && formikErrors.description}
                label={t('Description')}
                placeholder={t('Description')}
                required
                onChange={e=>{
                  setDescriptionInputValue(e.target.value);
                  handleChange(e);
                }}
                onBlur={searchSuggestions}
              />
            </div>
            <div className='flex justify-between gap-10'>
              <div className="relative flex-grow">
                <Input
                  id="price"
                  name="price"
                  type="number"
                  value={formikValues.price}
                  //@ts-ignore
                  errorMessage={touched.price && formikErrors.price}
                  label={t('Price')}
                  placeholder={t('Price')}
                  onChange={handleChange}
                />
              </div>
  
              <div className="relative flex-grow">
                <Input
                  id="quantity"
                  name="quantity"
                  type="number"
                  value={formikValues.quantity}
                  //@ts-ignore
                  errorMessage={touched.quantity && formikErrors.quantity}
                  label={t('quantity')}
                  placeholder={t('quantity')}
                  onChange={e=>{
                    dispatch(setProductSuggestionQuantity(+e.target.value));
                    handleChange(e);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-4 flex items-center justify-end w-full gap-x-8 ">
            <button
              type="button"
              onClick={() => dispatch(setIsCreateItemOverlayOpen(false))}
              className="w-1/5 flex justify-center py-2 px-4 border border-cblue-500 rounded text-sm font-medium uppercase text-cblue-500 hover:bg-cblue-200 focus:bg-cblue-300 active:bg-cblue-300"
            >
              {t('Cancel')}
            </button>
            <div className='w-1/5 h-full relative'>
              <Tippy content={t('Please fill out all fields first and have a look at available options')} placement="top" disabled={!isCreateDisabled && !searchRecordsByDescriptionLoading}>
                <button
                  className={classNames(
                    (isCreateDisabled || searchRecordsByDescriptionLoading) && 'bg-cgray-300 text-white',
                    mutationLoading && 'text-cgray-300 bg-cgray-300',
                    (!isCreateDisabled && !searchRecordsByDescriptionLoading && !mutationLoading) &&
                      'text-white bg-cblue-500 hover:bg-cblue-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100',
                    'w-full flex justify-center py-2 px-4 rounded text-sm font-medium uppercase ')}
                >
                  {t('Create New Product')}
                </button>
              </Tippy>
                {mutationLoading && <LoadingIndicator color="white" className="flex justify-center items-center absolute w-full h-full top-0 right-0" />}
              </div>


          </div>
        </div>
      </div>
    </form>
  );
}
