import type { UniqueIdentifier } from '@dnd-kit/core';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { FlattenedItem } from '../components/shared/dnd/types';
import { SearchResultsType, SearchType, ComposeModeSearchType } from '../constants';

export interface Feature {
  id: string,
  userValue?: string[] | number[] | boolean,
  code: string,
  featureType: string,
}

interface QuotationContext {
  /*
  "Destination" refers to the quotation that was used as search starting point 
   and is the same as the one that user should be redirected back when PasteMode
   gets switched off.
   "Search" refers to ids used to idfentify the source of search items if
   "Search by Qotation" search type is used.
   */
  destinationProjectId?: string,
  destinationQuotationId?: string,    
  searchProjectId?: string,
  searchQuotationId?: string,
  searchPasteSelectedProjectId?: string,
  searchPasteLatestQuotationId?: string,
  searchETIMCatalogId?: string,
}

interface SearchState {
  selectedFeatures: Feature[],
  selectedETIMGoupsId: string[],
  selectedETIMClassId: string,
  redirectToQuotation: boolean,
  pinnedItems: FlattenedItem[],
  QuotationContext: QuotationContext,
  searchClassesInput: string,
  searchHelp: boolean,
  searchInputValue: string[],
  searchListPage: number,
  searchType: SearchType,
  maximumSearchUncollapsedDepth: number,
  lastSearchResults: FlattenedItem[],
  searchResultsType: SearchResultsType,
  composeModeSearchType: ComposeModeSearchType,
  selectedFavouriteGroupId?: string,
  searchFavouriteGroupsInput: string,
  isPresetApplied: boolean,
}

// Define the initial state using that type
const initialState: SearchState = {
  selectedETIMGoupsId: [],
  selectedETIMClassId: '',
  redirectToQuotation: false,
  pinnedItems: [],
  QuotationContext: {
    destinationProjectId: undefined,
    destinationQuotationId: undefined,
    searchProjectId: undefined,
    searchQuotationId: undefined,
    searchETIMCatalogId: undefined,
  },
  searchClassesInput: '',
  searchHelp: false,
  searchInputValue: [],
  searchListPage: 1,
  searchType: SearchType.DESCRIPTION,
  maximumSearchUncollapsedDepth: 0,
  lastSearchResults: [],
  selectedFeatures: [],
  searchResultsType: SearchResultsType.PRODUCTS,
  composeModeSearchType: ComposeModeSearchType.DEFAULT,
  searchFavouriteGroupsInput: '',
  isPresetApplied: false,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState: initialState,
  reducers: {
    setGroup: (state, action: PayloadAction<string>) => {
      state.selectedETIMGoupsId = [
        ...state.selectedETIMGoupsId.filter(group => (group !== action.payload)),
        action.payload,
      ];
    },
    unsetGroup: (state, action: PayloadAction<string>) => {
      state.selectedETIMGoupsId = [
        ...state.selectedETIMGoupsId.filter(group => (group !== action.payload)),
      ];
    },
    setClass: (state, action: PayloadAction<string>) => {
      state.selectedETIMClassId = action.payload;
      state.selectedFeatures = [];
      // Reset search page to 1 when class is changed
      state.searchListPage = 1;
      // Reset isPresetApplied when ETIM class changes
      state.isPresetApplied = false;
    },
    setFeature: (state, action: PayloadAction<Feature>) => {
      const storedFeature = state.selectedFeatures.find((feature) => feature.id === action.payload.id);
      if (storedFeature) {
        storedFeature.userValue = action.payload.userValue;
        state.selectedFeatures = [
          ...state.selectedFeatures.filter((feature) => feature.id !== action.payload.id),
          storedFeature as Feature,
        ];
      } else {
        state.selectedFeatures = [
          ...state.selectedFeatures,
          {
            id: action.payload.id,
            code: action.payload.code,
            userValue: action.payload.userValue,
            featureType: action.payload.featureType,
          },
        ];
      }
    },
    unsetFeature: (state, action: PayloadAction<string>) => {
      const storedFeature = state.selectedFeatures.find((feature) => feature.id === action.payload);
      if (storedFeature) {
        state.selectedFeatures = [
          ...state.selectedFeatures.filter((feature) => feature.id !== action.payload),
        ];
      }
    },
    setSearchClassesInput: (state, action: PayloadAction<string>) => {
      state.searchClassesInput = action.payload;
    },
    setSearchInputValue: (state, action: PayloadAction<string[]>) => {
      state.searchInputValue = action.payload;
    },
    setSearchListPage: (state, action: PayloadAction<number>) => {
      state.searchListPage = action.payload;
    },
    setSearchType: (state, action: PayloadAction<SearchType>) => {
      state.searchType = action.payload;
    },
    setPinnedItem: (state, action: PayloadAction<FlattenedItem>) => {
      state.pinnedItems = [...state.pinnedItems, action.payload];
    },
    unsetPinnedItems: (state, action: PayloadAction<UniqueIdentifier[]>) => {
      state.pinnedItems = state.pinnedItems.filter(item => !action.payload.includes(item.id));
    },
    setSearchHelp: (state, action: PayloadAction<boolean>) => {
      state.searchHelp = action.payload;
    },
    setLastSearchResults: (state, action: PayloadAction<FlattenedItem[]>) => {
      state.lastSearchResults = action.payload;
    },
    setDestinationProjectId: (state, action: PayloadAction<string>) => {
      state.QuotationContext.destinationProjectId = action.payload;
    },
    setDestinationQuotationId: (state, action: PayloadAction<string | undefined>) => {
      state.QuotationContext.destinationQuotationId = action.payload;
    },
    unsetDestinationIds: (state) => {
      state.QuotationContext.destinationQuotationId = undefined;
      state.QuotationContext.destinationProjectId = undefined;
    },
    setSearchProjectId: (state, action: PayloadAction<string>) => {
      state.QuotationContext.searchProjectId = action.payload;
    },
    setSearchQuotationId: (state, action: PayloadAction<string | undefined>) => {
      state.QuotationContext.searchQuotationId = action.payload;
    },
    setSearchETIMCatalogId: (state, action: PayloadAction<string | undefined>) => {
      state.QuotationContext.searchETIMCatalogId = action.payload;
    },
    setRedirectToQuotation: (state, action: PayloadAction<boolean>) => {
      state.redirectToQuotation = action.payload;
    },
    setMaximumSearchUncollapsedDepth: (state, action: PayloadAction<number>) => {
      state.maximumSearchUncollapsedDepth = action.payload;
    },
    setSelectedFeatures: (state, action: PayloadAction<Feature[]>) => {
      state.selectedFeatures = [...action.payload];
    },
    unsetAllFeatures: (state) => {
      state.selectedFeatures = [];
    },
    setSearchResultsType: (state, action: PayloadAction<SearchResultsType>) => {
      state.searchResultsType = action.payload;
    },
    setComposeModeSearchType: (state, action: PayloadAction<ComposeModeSearchType>) => {
      state.composeModeSearchType = action.payload;
    },
    setSelectedFavouriteGroupId: (state, action: PayloadAction<string>) => {
      state.selectedFavouriteGroupId = action.payload;
    },
    setSearchFavouriteGroupsInput: (state, action: PayloadAction<string>) => {
      state.searchFavouriteGroupsInput = action.payload;
    },
    setIsPresetApplied: (state, action: PayloadAction<boolean>) => {
      state.isPresetApplied = action.payload;
    },
  },
});

export const {
  setGroup, unsetGroup, setClass, setFeature, unsetFeature,
  setSearchClassesInput, setSearchInputValue, setSearchListPage, setSearchType,
  unsetPinnedItems, setSearchHelp, setLastSearchResults, setDestinationProjectId,
  setSearchProjectId, setDestinationQuotationId, setSearchQuotationId,
  setRedirectToQuotation, unsetDestinationIds, setMaximumSearchUncollapsedDepth,
  unsetAllFeatures, setSelectedFeatures, setPinnedItem, setSearchResultsType,
  setSearchETIMCatalogId, setIsPresetApplied, setComposeModeSearchType,
  setSelectedFavouriteGroupId, setSearchFavouriteGroupsInput,
} = searchSlice.actions;

export default searchSlice.reducer;
