import type { UniqueIdentifier } from '@dnd-kit/core';
import React, { useEffect, useMemo } from 'react';
import { useStore } from 'react-redux';
import { List, AutoSizer } from 'react-virtualized';
import type { ListRowProps } from 'react-virtualized';
import { ItemTypeChoices } from '../../../__generated__/graphql';
import colorsConfig from '../../../colorsConfig';
import { MaxUncollapsedDepthSource, ROW_HEIGHT, GROUP_ROW_HEIGHT, GROUP_DETAILS_ROW_HEIGHT, PRODUCT_DETAILS_ROW_HEIGHT, SERVICE_DETAILS_ROW_HEIGHT, GroupCollapseState } from '../../../constants';
import { flattenTree, removeChildrenOf } from '../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { applyCollapsedItemsId, classNames, collapseGroups, expandGroups, handleQuotationGroupCollapse } from '../../../helpers/utils';
import { 
  setItemToCollapse, 
  setItemToUncollapse, 
  setMaximumQuotationUncollapsedDepth,
  setTreeItems, 
  setCollapsedItemsId,
  setRecentlyToggledItemId,
  setVisibleItems,
} from '../../../redux/quotationSlice';
import { RootState } from '../../../store';
import type { FlattenedItem } from '../../shared/dnd/types';
import HotKeysHandler from './actions/HotKeysHandler';
import QuotationListDndItem from './QuotationListDndItem';
import { QuotationListItem } from './row/QuotationListItem';
import 'react-virtualized/styles.css';

interface Props {
  defaultItems: FlattenedItem[];
  indentationWidth: number;
}

export default function QuotationList(props: Props) {
  const {
    indentationWidth, defaultItems,
  } = props;

  const dispatch = useAppDispatch();
  const [items, setItems] = React.useState(defaultItems);
  const dndGroupId = useAppSelector(state => state.quotation.dndGroupId);
  const singleGroupItems = items.filter(item => item.id === dndGroupId);
  const collapsedItemsId = useAppSelector(state => state.quotation.collapsedItemsId);
  const openedDeatilsItems = useAppSelector(state => state.quotation.openedDeatilsItems);
  const recentlyOpenedOrClosedDetailsItemId = useAppSelector(state => state.quotation.recentlyOpenedOrClosedDetailsItemId);
  const dndGroupScrollPosition = useAppSelector(state => state.quotation.dndGroupScrollPosition);
  const [lastDndGroupId, setLastDndGroupId] = React.useState<UniqueIdentifier | null>(null);
  const recentlyToggledItemId = useAppSelector(state => state.quotation.recentlyToggledItemId);
  const groupCollapseState = useAppSelector(state => state.quotation.groupCollapseState);
  const highlightedItemId = useAppSelector(state => state.quotation.highlightedItemId);
  const store = useStore();
  
  const listContainerRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<List>(null);

  const staticListFlattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);

    return removeChildrenOf(
      flattenedTree,
      collapsedItemsId,
    );
  }, [items, collapsedItemsId]);

  // Update array of excluded from staticListFlattenedItems items IDs each time when Group shown in DnD mode are change.
  const excludedItemsIdList: UniqueIdentifier[] = useMemo(() => {
    const singleGroupflattenedTree = flattenTree(singleGroupItems);
    const excludedItemsId = singleGroupflattenedTree.map(item => item.id);

    return excludedItemsId;
  }, [singleGroupItems]);

  useEffect(() => {
    const preparedStaticListFlattenedItems = staticListFlattenedItems.filter(item => !excludedItemsIdList.includes(item.id));
    const deepestQuotationUncollapsedLevel = Math.max(...preparedStaticListFlattenedItems.map(item => item.depth));
    dispatch(setMaximumQuotationUncollapsedDepth({ 
      maxDepth: deepestQuotationUncollapsedLevel, 
      source: MaxUncollapsedDepthSource.QuotationListStaticItem,
    }));

    // used to navigate by arrows keys
    dispatch(setTreeItems(staticListFlattenedItems));
  }, [dndGroupId, staticListFlattenedItems, dispatch, excludedItemsIdList]);

  useEffect(() => {
    // Update local items each time we have updated items incoming outside this component.
    setItems(applyCollapsedItemsId(defaultItems, collapsedItemsId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultItems]);

  useEffect(() => {
    if (groupCollapseState === GroupCollapseState.ALL_COLLAPSED) {
      setItems(collapseGroups(items));
      const flattenedItems = flattenTree(items);
      const collapsedAllItemsId = flattenedItems.filter(item => item?.children?.length).map(item => item.id);
      dispatch(setCollapsedItemsId(collapsedAllItemsId));
    }
    if (groupCollapseState === GroupCollapseState.ALL_EXPANDED) {
      setItems(expandGroups(items));
      dispatch(setCollapsedItemsId([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCollapseState]);


  function handleCollapse(idsToProcess: UniqueIdentifier[]) {
    setItems((itemsToUpdate) =>
      handleQuotationGroupCollapse(itemsToUpdate, idsToProcess),
    );
    dispatch(setItemToCollapse(''));
    dispatch(setItemToUncollapse(''));
    dispatch(setCollapsedItemsId(idsToProcess));
    
    dispatch(setRecentlyToggledItemId(idsToProcess[0]));
  }

  const getRowHeight = ({ index }: { index: number }) => {
    const item = staticListFlattenedItems[index];
    const isDetailsShown = openedDeatilsItems.includes(item.id);
    switch (item.itemType) {
      case ItemTypeChoices.Group:
        return isDetailsShown ? GROUP_DETAILS_ROW_HEIGHT + GROUP_ROW_HEIGHT : GROUP_ROW_HEIGHT;
      case ItemTypeChoices.Product:
        return isDetailsShown ? PRODUCT_DETAILS_ROW_HEIGHT + ROW_HEIGHT : ROW_HEIGHT;
      case ItemTypeChoices.Service:
        return isDetailsShown ? SERVICE_DETAILS_ROW_HEIGHT + ROW_HEIGHT : ROW_HEIGHT;
      default:
        return ROW_HEIGHT;
    }
  };

  // Keep track of items in view using onRowsRendered callback
  const handleRowsRendered = ({ overscanStartIndex, overscanStopIndex }: {
    overscanStartIndex: number;
    overscanStopIndex: number;
  }) => {
    if (!listRef.current) return;
    
    const visibleItemsInfo = [];
    
    for (let i = overscanStartIndex; i <= overscanStopIndex; i++) {
      if (i >= 0 && i < staticListFlattenedItems.length) {
        const item = staticListFlattenedItems[i];
        const rowTop = listRef.current.getOffsetForRow({ index: i });
        const rowHeight = getRowHeight({ index: i });
        
        visibleItemsInfo.push({
          item: item,
          top: rowTop,
          height: rowHeight,
        });
      }
    }
    
    dispatch(setVisibleItems(visibleItemsInfo));
  };

  const rowRenderer = ({ index, key, style }: ListRowProps) => {
    const staticFlattenedItem = staticListFlattenedItems[index];
    
    if (!dndGroupId || (dndGroupId && !excludedItemsIdList.includes(staticFlattenedItem.id))) {
      const isRootItem = !staticFlattenedItem.parentId;
      const isHighlighted = highlightedItemId === staticFlattenedItem.id;
      
      // For highlighted rows, we apply an eye-catching style
      const highlightStyle = isHighlighted ? {
        backgroundColor: colorsConfig['cyellow-200'],
        boxShadow: '0 0 0 2px rgba(251, 191, 36, 0.5)',
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
      } : {};
      
      return (
        <div key={key} style={{
          ...style,
          ...highlightStyle,
        }}>
          <div 
            className={classNames(
              isRootItem && 'top-[139px] z-20',
            )}
            data-item-id={staticFlattenedItem.id}
          >
            <QuotationListItem
              key={staticFlattenedItem.id}
              id={staticFlattenedItem.id}
              item={staticFlattenedItem}
              depth={staticFlattenedItem.depth}
              indentationWidth={indentationWidth}
              indicator
              collapsed={collapsedItemsId.includes(staticFlattenedItem.id)}
              onCollapse={
                staticFlattenedItem.children.length
                  ? () => handleCollapse([staticFlattenedItem.id])
                  : undefined
              }
              quotationLength={staticListFlattenedItems.filter(item => !item.parentId).length}
              initialIndex={staticFlattenedItem.index}
              isHighlighted={isHighlighted}
            />
          </div>
        </div>
      );
    } else if (dndGroupId === staticFlattenedItem.id) {
      return (
        <div key={key} style={style}>
          <QuotationListDndItem 
            key={staticFlattenedItem.id} 
            indentationWidth={indentationWidth} 
            defaultItems={singleGroupItems} 
            onCollapse={
              staticFlattenedItem.children.length
                ? (id) => handleCollapse([id])
                : undefined
            }
            quotationLength={staticListFlattenedItems.filter(item => !item.parentId).length}
            initialIndex={staticFlattenedItem.index}
          />
        </div>
      );
    }
    return null;
  };

  // Check if any item should be rendered as DnD
  const shouldRenderAsDnd = staticListFlattenedItems.some(item => item.id === dndGroupId);

  const renderRegularList = () => {
    // Only render the DnD group and its items
    const dndGroup = staticListFlattenedItems.find(item => item.id === dndGroupId);
    
    if (dndGroup) {
      return (
        <>
          <div
            className='relative bg-gradient-to-r from-cgray-100 via-cgray-200 to-cgray-100 h-full py-4 w-full min-h-[1000px] flex flex-col'
            
          >
            <div className='absolute inset-0 bg-[repeating-linear-gradient(135deg,rgba(255,255,255,0.4),rgba(255,255,255,0.4)_10px,transparent_10px,transparent_20px)]' />
            <div 
              className='relative z-10 w-full' 
              style={{ transform: `translateY(${dndGroupScrollPosition}px)` }}
            >
              <QuotationListDndItem 
                key={dndGroup.id} 
                indentationWidth={indentationWidth} 
                defaultItems={singleGroupItems} 
                onCollapse={
                  dndGroup.children.length
                    ? (id) => handleCollapse([id])
                    : undefined
                }
                quotationLength={staticListFlattenedItems.filter(item => !item.parentId).length}
                initialIndex={dndGroup.index}
              />
            </div>
          </div>
        </>
      );
    }

    // Render regular items (when no DnD is active)
    return staticListFlattenedItems.map((staticFlattenedItem) => {
      const isRootItem = !staticFlattenedItem.parentId;
      
      return (
        <div key={staticFlattenedItem.id}>
          <div 
            className={classNames(isRootItem && 'top-[139px] z-20')}
            data-item-id={staticFlattenedItem.id}
          >
            <QuotationListItem
              key={staticFlattenedItem.id}
              id={staticFlattenedItem.id}
              item={staticFlattenedItem}
              depth={staticFlattenedItem.depth}
              indentationWidth={indentationWidth}
              indicator
              collapsed={collapsedItemsId.includes(staticFlattenedItem.id)}
              onCollapse={
                staticFlattenedItem.children.length
                  ? () => handleCollapse([staticFlattenedItem.id])
                  : undefined
              }
              quotationLength={staticListFlattenedItems.filter(item => !item.parentId).length}
              initialIndex={staticFlattenedItem.index}
            />
          </div>
        </div>
      );
    });
  };

  // Find the index of the most recently opened/closed details or collapsed/uncollapsed item
  const getScrollToIndex = () => {
    const state = store.getState() as RootState;
    const selectedItem = state.quotation.selectedItem;
    
    if (selectedItem) {
      const index = staticListFlattenedItems.findIndex(item => item.id === selectedItem.id);
      if (index > -1) {
        return index;
      }
    }
    
    if (recentlyToggledItemId) {
      const index = staticListFlattenedItems.findIndex(item => item.id === recentlyToggledItemId);
      if (index > -1) {
        return index;
      }
    }
    
    if (recentlyOpenedOrClosedDetailsItemId) {
      const index = staticListFlattenedItems.findIndex(item => item.id === recentlyOpenedOrClosedDetailsItemId);
      return index > -1 ? index : 0;
    }
    return 0;
  };

  const syncScrollPosition = () => {
    const groupIdToSync = dndGroupId || lastDndGroupId;
    
    if (groupIdToSync && listRef.current) {
      const itemIndex = staticListFlattenedItems.findIndex(item => item.id === groupIdToSync);
      if (itemIndex !== -1) {
        // to ensure the scroll happens after the list has rendered
        setTimeout(() => {
          if (listRef.current) {
            listRef.current.scrollToRow(itemIndex);
            setLastDndGroupId(null);
          }
        }, 0);
      }
    }
  };

  useEffect(() => {
    syncScrollPosition();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dndGroupId]);

  const renderVirtualizedList = () => {
    const listKey = openedDeatilsItems.join(',');
    
    // Calculate total height of all rows
    const totalRowsHeight = staticListFlattenedItems.reduce((acc, _item, index) => {
      return acc + getRowHeight({ index });
    }, 0);
    
    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={listRef}
            key={listKey}
            height={height}
            width={width}
            rowCount={staticListFlattenedItems.length}
            rowHeight={getRowHeight}
            rowRenderer={rowRenderer}
            overscanRowCount={15}
            scrollToAlignment="center"
            scrollToIndex={getScrollToIndex()}
            onRowsRendered={handleRowsRendered}
            // To make visible bottom border of the last row (it has negative margin)
            containerStyle={{ height: `${totalRowsHeight + 16}px`, maxHeight: `${totalRowsHeight + 16}px`, width: '100%' }}  // This targets the innerScrollContainer
          />
        )}
      </AutoSizer>
    );
  };

  useEffect(() => {
    if (dndGroupId) {
      setLastDndGroupId(dndGroupId);
    }
  }, [dndGroupId]);

  return (
    <>
      <HotKeysHandler />
      <div 
        ref={listContainerRef}
        className="relative w-full overflow-x-auto"
        style={{ height: (shouldRenderAsDnd || staticListFlattenedItems.length === 0) ? '100%' : 'calc(100vh - 240px)' }}
      >
        {shouldRenderAsDnd ? renderRegularList() : renderVirtualizedList()}
      </div>
    </>
  );
}
