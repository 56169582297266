import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { SingleValue } from 'react-select';
import { Maybe, PositionTypeChoices } from '../../../../../__generated__/graphql';
import { UPDATE_GROUP_POSITION_TYPE } from '../../../../../api/mutations/quotations/group';
import { POSITION_TYPES_QUERY } from '../../../../../api/queries/settings';
import { SelectedColumnName } from '../../../../../constants';
import { useAppDispatch } from '../../../../../helpers/reduxHooks';
import PopoutInput from '../../../../../layout/inputs/PopoutInput';
import { setIsLoadingUpdate, setSelectedColumn } from '../../../../../redux/quotationSlice';

interface Props {
  positionType: Maybe<PositionTypeChoices> | undefined, 
  id: ID,
  refetchQueries: InternalRefetchQueriesInclude,
  setIsItemHovered: React.Dispatch<React.SetStateAction<string | null>>;
}

interface MutationVariables {
  group: string,
  positionType?: string
  quotation: string,
}

export default function PositionTypeSelect(props: Props) {
  const { positionType, id, refetchQueries, setIsItemHovered } = props;
  const [selectedType, setSelectedType] = useState(positionType);
  const [inputFocused, setInputFocused] = useState(false);

  const { quotationId } = useParams();
  const dispatch = useAppDispatch();

  const {
    data: positionTypesData,
  } = useQuery(POSITION_TYPES_QUERY);

  const [
    updateGroupPositonTypeMutation,
    {
      loading: updateGroupPositonTypeLoading,
    },
  ] = useMutation(UPDATE_GROUP_POSITION_TYPE, { refetchQueries });

  const options = positionTypesData && positionTypesData.positionTypes && positionTypesData.positionTypes.response
    ? positionTypesData.positionTypes.response.map((type) => (
      { value: type?.value, label: type?.label }) as SelectOption)
    : [];

  const handleChange = (selectedOption: SelectOption) => {
    const variables: MutationVariables = {
      group: id as string,
      quotation: quotationId as string,
    };
    if (selectedOption) {
      variables.positionType = selectedOption.value as string;
    }
    updateGroupPositonTypeMutation({ variables });
  };

  useEffect(() => {
    setSelectedType(positionType);
  }, [positionType]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(updateGroupPositonTypeLoading));
  }, [dispatch, updateGroupPositonTypeLoading]);

  const handleFocus = () => {
    setInputFocused(true);
    dispatch(setSelectedColumn(SelectedColumnName.POSITION_TYPE));
  };

  const handleBlur = () => {
    setInputFocused(false);
  };

  return (
    <div className="h-full" onClick={()=>setIsItemHovered(null)}>
      <PopoutInput
        value={options.find(option => option.value === selectedType)}
        options={options}
        isClearable
        onChange={handleChange as (value?: SingleValue<SelectOption>) => void}
        buttonLabel={options.find(option => option.value === selectedType)?.label.slice(0, 2).toUpperCase() as string}
        positionType={selectedType as string | undefined}
        onFocus={handleFocus}
        onBlur={handleBlur}
        menuIsOpen={inputFocused}
      />
    </div>
  );
}
