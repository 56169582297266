import { gql } from '../../__generated__/gql';


export const TEMPLATE_SUGGESTIONS = gql(`
  query templateSuggestions(
    $item: ID!
    $page: Int
  ){
    templateSuggestions(
      item: $item
      page: $page
      ){
        response {
          page
          pages
          hasNext
          hasPrev
          elidedPageRange
          totalObjectsQuantity
          objects {
            id
            name
            items {
              group {
                description
                id
              }
              groupTemplate {
                id
                name
              }
              id
              itemType
              order
              parentId
              product {
                description
                externalId
                id
                catalogId
                manufacturingWage{
                  id
                  costLabel
                }
                profitSurcharge
                supplierLabel
                manufacturingTime
                unit
              }
              service {
                description
                id
                notes
                serviceWage {
                  costLabel
                  id
                  isCurrent
                  label
                  wageGroupLabel
                }
                serviceTime
              }
            }
					}
        }
        errors {
          messages
          field
        }
    }
  }
`);


export const GROUP_TEMPLATE_GROUP_DETAILS_QUERY = gql(`
query GroupTemplateGroupDetails($group: ID!){
  groupTemplateGroupDetails(
    group: $group
    ){
      response {
        description
        id
        notes
        etimClass{
          id
          code
					features{
						id
						code
					}
					localizedDescription
        }
        groupFeatures{
          id
          value
          valueLabels
          etimClassFeature{
            id
            unit{
              id
              abbreviation
              code
            }
            feature{
              id
              code
              featureType
              localizedDescription
            }
          }
        }
		  }
      errors {
        messages
        field
      }
    }
  }
`);
