import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PROJECT_TYPES_QUERY } from '../../../api/queries/projects';
import { SelectInput } from '../../../layout/inputs';

interface Props {
  setFieldValue: SetFieldValue,
  initialProjectType?: string,
}

export default function ProjectTypeSelect(props: Props) {
  const { setFieldValue, initialProjectType } = props;
  const { t } = useTranslation();
  const { data, loading } = useQuery(PROJECT_TYPES_QUERY);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [initialOption, setInitialOption] = useState<SelectOption | undefined>();
  
  useEffect(() => {    
    const typesOptions = data && data.projectTypes && data.projectTypes.response
      ? data.projectTypes.response.map((type) => (
        { value: type?.value, label: type?.label }) as SelectOption)
      : [];
    setOptions(typesOptions);
    
    const initialTypeOption = typesOptions.find((option) => option.value === initialProjectType);
    setInitialOption(initialTypeOption);        
  }, [data, initialProjectType]);

  return (
    <SelectInput
      options={options}
      placeholder={t('Select Project Type')}
      isLoading={loading}
      onChange={(e: SelectOption) => setFieldValue('projectType', e.value)}
      value={initialOption}
    />
  );
}
