import { IconClick } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { ProductDetailsType } from '../../../../__generated__/graphql';
import { classNames } from '../../../../helpers/utils';
import ObsoletableValue from '../../../quotation/list/fields/ObsoletableValue';

interface Props {
  product: ProductDetailsType;
  isReadOnly?: boolean;
}

export default function PurchaseOptions({ product, isReadOnly }: Props) {
  const { t } = useTranslation();

  if (!product?.purchaseOptions || product.purchaseOptions.length === 0) {
    return null;
  }

  const selectDisabled = true;

  return (
    <div className="flex flex-col gap-2">
      <div className="font-bold text-cgray-600">{t('Purchase Options')}</div>
      <div className="overflow-x-auto overflow-y-auto max-h-96 pr-2">
        <div className="flex flex-col gap-2 max-w-4xl">
          {product?.purchaseOptions?.map((option, index) => (
            <div key={option?.id}>
              <div className="grid grid-cols-2 gap-2">

                {/* First Column (Pricing Details) */}
                <div className="col-span-1">
                  <div className="grid grid-cols-2 gap-2">
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Supplier')}</p>
                      <p>{option?.catalog?.supplier?.name}</p>
                    </div>
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Catalog')}</p>
                      <p>{option?.catalog?.name}</p>
                    </div>
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Price indicator')}</p>
                      <p>{option?.priceIndicator}</p>
                    </div>
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Match code')}</p>
                      <p className="break-all">{option?.matchCode}</p>
                    </div>
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Group Discount')}</p>
                      <p>{option?.discountGroup}</p>
                    </div>
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Units per price')}</p>
                      <p>{option?.unitsPerPrice}</p>
                    </div>
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Order amount')}</p>
                      <p>{option?.orderAmount}</p>
                    </div>
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Unit')}</p>
                      <p>{option?.unit}</p>
                    </div>
                  </div>
                </div>

                {/* Second Column (Discounts, Material Surcharge & Net Price) */}
                <div className="col-span-1">
                  <div className="grid grid-cols-2 gap-2">
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Price')}</p>
                      <p>{option?.priceLabel}</p>
                    </div>
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Special Price')}</p>
                      <ObsoletableValue
                        value={option?.specialPriceLabel}
                        isCurrent={!option?.specialPriceIsObsolete}
                      />
                    </div>
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Price/Piece')}</p>
                      <p>{option?.pricePieceLabel}</p>
                    </div>
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Discount')}</p>
                      <ObsoletableValue
                        value={option?.discount?.label}
                        isCurrent={!option?.discount?.isObsolete}
                      />
                    </div>
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Raw Material Surcharge')}</p>
                      <div>
                        {option?.rawMaterialSurcharges?.map((surcharge) => surcharge?.surchargeCostLabel).join(', ')}
                      </div>
                    </div>
                    <div className="col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className="text-2xs text-cgray-400">{t('Discounted Price/Piece')}</p>
                      <ObsoletableValue
                        value={option?.discountedPricePieceLabel}
                        isCurrent={!option?.discount?.isObsolete}
                      />
                    </div>
                    <div className="col-span-1 col-start-2 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t flex flex-col justify-between">
                      <p className="text-2xs text-cgray-400">{t('Net Price/Piece')}</p>
                      <p>{option?.netPricePieceLabel}</p>
                    </div>
                  </div>
                  {/* Selection & Status */}
                  <div className="col-span-2 flex py-px justify-end pt-3">
                    <div className='flex gap-2 items-center'>
                      {/* Status Tags */}
                      {option?.isObsolete && (
                        <span 
                          className="max-h-6 rounded bg-cyellow-500 px-1 py-0.5 text-xs font-medium text-gray-700 shadow-sm flex items-center"
                          role="status"
                        >
                          {t('Obsolete')}
                        </span>
                      )}
                      {option?.isSelectedOffer ? (
                        <span 
                          className="max-h-6 rounded bg-cblue-100 px-1 py-0.5 text-xs font-medium text-cblue-500 shadow-sm flex items-center"
                          role="status"
                        >
                          {t('Selected')}
                        </span>
                      ) : (
                        // Action Button
                        !isReadOnly && (
                          <button
                            type="button"
                            disabled={selectDisabled}
                            className={classNames(selectDisabled ? 'bg-cgray-200' : 'bg-cgreen-100 border-cgreen-500 text-cgreen-700 hover:bg-cgreen-200', 'flex gap-1 rounded  border px-2 py-1 text-sm font-semibold  shadow-sm  items-center')}
                            aria-pressed="false"
                          >
                            <IconClick 
                              className='h-5 w-5' 
                              aria-hidden="true" 
                            />
                            {t('Select')}
                          </button>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {index < (product?.purchaseOptions?.length || 0) - 1 && (
                <hr className="my-4 border-t border-cgray-300" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

} 