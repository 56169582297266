import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../helpers/reduxHooks';
import { SelectInput } from '../../../../../../../layout/inputs';
import { QuotationGroupFeature, setSelectedGroupFeature } from '../../../../../../../redux/quotationSlice';

export default function GroupFeatureLogicalValue() {
  const { t } = useTranslation();
  const selectedGroupFeature = useAppSelector(state => state.quotation.selectedGroupFeature);
  const dispatch = useAppDispatch();

  const handleChange = (e: SelectOption) => {
    const payload = {
      ...selectedGroupFeature,
      value: e.value,
    } as QuotationGroupFeature;
    dispatch(setSelectedGroupFeature(payload));
  };

  useEffect(() => {
    if (selectedGroupFeature?.value === null || selectedGroupFeature?.value === undefined) {
      const payload = {
        ...selectedGroupFeature,
        value: true,
      } as QuotationGroupFeature;
      dispatch(setSelectedGroupFeature(payload));
    }
  }, [selectedGroupFeature, dispatch]);

  const options = [
    { value: true, label: t('Yes') },
    { value: false, label: t('No') },
  ];

  return (
    <SelectInput
      options={options}
      placeholder={t('Select Value')}
      onChange={handleChange}
      value={options.find((item) => item.value === selectedGroupFeature?.value)}
    />
  );
}
