import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ProductDetailsType, StorageProductImageType } from '../../../../__generated__/graphql';
import { classNames } from '../../../../helpers/utils';
import { NotesInput } from '../../../quotation/list/fields/product';
import ProductGallery from './ProductGallery';

interface Props {
  product: ProductDetailsType;
  productId: string;
  refetchQueries?: InternalRefetchQueriesInclude;
  isReadOnly?: boolean;
}

export default function StorageProductData(
  { product, productId, refetchQueries, isReadOnly }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-2 font-bold text-cgray-600">{t('Product Overview')}</div>
      <div className="flex gap-2">
        <div className="flex flex-col gap-2 w-[320px]">
          <ProductGallery
            images={product.storageProduct?.images as StorageProductImageType[]}
            externalId={product.storageProduct?.externalId as string}
          />
        </div>
        <div className="w-[38rem] flex flex-col gap-2">
          <div className='flex flex-col pl-2 py-px h-full min-h-[40px] min-w-[256px] rounded-t border border-cgray-200 bg-white cursor-default'>
            <p className="text-2xs text-cgray-400">{t('Description')}</p>
            <textarea
              readOnly
              value={product.storageProduct?.description}
              className='resize-none cursor-default overflow-ellipsis w-full h-full p-0 text-sm flex items-start border-none ring-0 outline-none bg-transparent shadow-none drop-shadow-none focus:border-none focus:ring-0 focus:outline-none'
            />
          </div>
          <div className="pl-2 pr-1 py-px min-h-[40px] rounded-t border border-cgray-200">
            <p className="text-2xs text-cgray-400">{t('GTIN')}</p>
            <p className='break-words'>{product?.storageProduct?.gtin}</p>
          </div>
          <div className={classNames(!isReadOnly && 'bg-cgray-100 hover:bg-cgray-200 cursor-text', 'flex flex-col  h-full pl-2 py-px min-h-[40px] min-w-[80px]rounded-t border border-cgray-200')}>
            <p className="text-2xs text-cgray-400">{t('Notes')}</p>
            {!isReadOnly ? (
              <NotesInput
                productId={productId}
                notes={product.notes as string}
                refetchQueries={refetchQueries as InternalRefetchQueriesInclude}
              />
            ) : (
              <textarea
                readOnly
                value={product.notes as string}
                className='resize-none cursor-default overflow-ellipsis w-full h-full p-0 text-sm flex items-start border-none ring-0 outline-none bg-transparent shadow-none drop-shadow-none focus:border-none focus:ring-0 focus:outline-none'
              />
            )
            }
          </div>
        </div>
      </div>
    </div>
  );
} 