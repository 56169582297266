import { EtimProductFeatureType } from '../../../../../__generated__/graphql';

interface Props {
  item: EtimProductFeatureType,
}

export default function EtimProductFeature(props: Props) {
  const { item  } = props;
  
  return  (
    <div className="px-2 py-px min-w-max max-h-[40px] border border-cgray-200 rounded-t">
      <p className='text-cgray-400 text-2xs'>{item.etimClassFeature.feature.localizedDescription}: </p>
      <p><span>{item.valueLabels}</span></p>
    </div>
  );
}
