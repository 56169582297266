import { gql } from '../../__generated__/gql';


export const GROUPS_QUERY = gql(`
  query Groups{
    groups{
      response{
        id
        code
        localizedDescription
        isExtension
      }
      errors{
        field
        messages
      }
    }
  }
  `);

export const CLASSES_QUERY = gql(`
  query Classess(
    $group: ID!
    $searchInput: String
    ) {
    classes(group: $group, searchInput: $searchInput){
      response{
        id
        code
        localizedDescription
        isFavourite
        isExtension
      }
      errors{
        field
        messages
      }
    }
  }
`);

export const INLINE_ALL_CLASSES_QUERY = gql(`
  query inlineAllClasses($searchInput: String, $skip: Int){
      inlineAllClasses(
        searchInput: $searchInput
        skip: $skip
      ){
      response{
        hasNext
        objects{
          id
          code
          localizedDescription
          isFavourite
        }
      }
      errors{
        field
        messages
      }
    }
  }
`);

export const INLINE_ALL_FEATURES_QUERY = gql(`
  query inlineAllFeatures($searchInput: String, $skip: Int){
      inlineAllFeatures(
        searchInput: $searchInput
        skip: $skip
      ){
      response{
        hasNext
        objects{
          id
          feature{
            id
            code
            localizedDescription
            featureType
          }
          unit {
            id
            code
            localizedDescription
            abbreviation
          }
         
        }
      }
      errors{
        field
        messages
      }
    }
  }
`);


export const ALPHANUMERIC_FEATURE_VALUES_QUERY = gql(`
  query alphanumericFeatureValues($feature: ID!){
    alphanumericFeatureValues(
        feature: $feature
      ){
      response{
        id
        code
        localizedDescription
      }
      errors{
        field
        messages
      }
    }
  }
`);


export const FAVOURITE_CLASSES_QUERY = gql(`
  query FavouriteClasses {
    favouriteClasses {
      response{
        id
        code
        localizedDescription
        isFavourite
      }
      errors{
        field
        messages
      }
    }
  }
`);

export const FEATURES_PRESET_QUERY = gql(`
  query FeaturesPreset(
    $etimClass: ID!
    ) {
    featuresPreset(etimClass: $etimClass){
      response{
        id
        etimClassFeature{
          id
          feature{
            id
            code
            featureType
            localizedDescription
          }
          unit {
            id
            abbreviation
            code
          }
        }
        value
      }
      errors{
        field
        messages
      }
    }
  }
`);


export const PAGINATED_FEATURES_PRESET_QUERY = gql(`
  query paginatedFeaturesPreset(
    $page: Int
    $searchInput: String
  ){
    paginatedFeaturesPreset(
      page: $page
      searchInput: $searchInput
    ){
      response{
        hasNext
        totalObjectsQuantity
        hasPrev
        elidedPageRange
        page
        objects {
          id
          items{
						etimClassFeature {
							id
              feature{
                id
                code
                featureType
                localizedDescription
              }
						}
            unit {
              id
              code
              localizedDescription
              abbreviation
            }
						value
            valueLabels
					}
        }
      }
    }
  }
`);