import { useQuery } from '@apollo/client';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { QuotationItemNoteType } from '../../../__generated__/graphql';
import { QUOTATION_ITEM_NOTES_QUERY } from '../../../api/queries/quotations/notes';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { setHighlightedItemId } from '../../../redux/quotationSlice';
import QuotationItemNote from './QuotationItemNote';

// Utility function to format the notes quantity
export const formatNotesQuantity = (quantity: number) => {
  if (!quantity) return '';
  return <sup className="ml-0.5 text-xs font-normal">{quantity}</sup>;
};

const NotesPanel = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { quotationId } = useParams();
  const visibleItems = useAppSelector(state => state.quotation.visibleItems);

  // GraphQL query for fetching notes
  const {
    data: notesData,
    loading: notesLoading,
  } = useQuery(QUOTATION_ITEM_NOTES_QUERY, {
    variables: {
      quotation: quotationId as string,
    },
    skip: !quotationId,
    fetchPolicy: 'network-only', // Don't use cache to ensure fresh data
  });

  // Function to handle mouse enter
  const handleMouseEnter = (itemId: UniqueIdentifier) => {
    dispatch(setHighlightedItemId(itemId));
  };

  // Function to handle mouse leave
  const handleMouseLeave = () => {
    dispatch(setHighlightedItemId(null));
  };

  const notes = notesData?.quotationItemNotes?.response || [];
  

  return (
    <div className="h-full flex flex-col">
      {/* Header */}
      <div className="sticky top-0 pt-4 pb-4 px-4 bg-white border-b-2 border-cblue-500 z-30">
        <h2 className="font-bold text-sm">{t('Item Notes')}</h2>
        <p className="text-xs text-cgray-500">{t('Notes are shown for visible items')}</p>
      </div>
      
      {/* Loading state */}
      {notesLoading && (
        <div className="flex-1 flex items-center justify-center bg-cblue-100">
          <div className="p-4 text-center text-cgray-600">
            <p>{t('Loading notes...')}</p>
          </div>
        </div>
      )}
      
      {/* Notes content - scrollable area for notes */}
      {!notesLoading && (
        <div className="flex-1 overflow-y-auto p-4 bg-cblue-100">
          {/* Show all parent notes for visible items */}
          {visibleItems
            .filter(item => notes.some(note => note?.itemId === item.item.id))
            .map(item => {
              // Get all parent notes for this item
              const itemNotes = notes.filter(note => note?.itemId === item.item.id);
              
              return itemNotes.map(note => (
                <QuotationItemNote
                  key={`${item.item.id}-${note?.id}`}
                  note={note as QuotationItemNoteType}
                  itemId={item.item.id}
                  itemType={item.item.itemType}
                  itemTitle={item.item.group?.description || item.item.product?.description || item.item.service?.description || ''}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
              ));
            })}
          
          {/* Empty state - when no notes are visible */}
          {visibleItems.filter(item => notes.some(note => note?.itemId === item.item.id)).length === 0 && (
            <div className="p-4 text-center text-cgray-400 bg-white rounded-lg border border-cgray-200">
              <p className="mb-2">{t('No notes for visible items')}</p>
              <p className="text-xs">{t('Add notes to items to see them here')}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NotesPanel; 