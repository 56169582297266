import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { IconSend, IconX } from '@tabler/icons-react';
import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { QUOTATION_ITEM_NOTE_MUTATION } from '../../../api/mutations/quotations/note';
import { QUOTATION_ITEM_NOTES_QUERY } from '../../../api/queries/quotations/notes';
import { classNames } from '../../../helpers/utils';
import { LoadingIndicator } from '../../../layout';

interface Note {
  id: string;
  body: string;
  itemId?: string;
  parentId?: string;
}

interface Props {
  parentId?: string;
  itemId?: string;
  compact?: boolean;
  onReplyAdded?: () => void;
  note?: Note; // Optional note for editing
  onCancel?: () => void; // Only used in edit mode
}

const QuotationItemNoteForm: React.FC<Props> = ({
  parentId,
  itemId,
  compact = false,
  onReplyAdded,
  note,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { quotationId } = useParams();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const isEditMode = !!note;

  // Define refetch queries for after mutation
  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: QUOTATION_ITEM_NOTES_QUERY,
      variables: {
        quotation: quotationId,
      },
    },
  ];

  // Set up mutation
  const [
    mutateNote,
    { loading: isSubmitting },
  ] = useMutation(QUOTATION_ITEM_NOTE_MUTATION, {
    refetchQueries,
    onCompleted: () => {
      if (isEditMode && onCancel) {
        onCancel(); // Exit edit mode on success
      } else if (onReplyAdded) {
        onReplyAdded(); // Notify parent of reply added
      }
    },
  });

  // Form validation schema
  const schema = yup.object({
    body: yup.string().required(t('Note text is required')),
  });

  // Set up form with Formik
  const {
    handleSubmit,
    handleChange,
    values,
    resetForm,
  } = useFormik({
    initialValues: {
      body: note?.body || '',
    },
    validationSchema: schema,
    onSubmit: (formValues) => {
      // Always include all required fields for both new and edited notes
      const variables = {
        quotation: quotationId,
        item: isEditMode ? note.itemId : itemId,
        body: formValues.body,
      };

      if (parentId) {
        //@ts-ignore
        variables.parent = parentId;
      }
      
      // Only add id field when editing
      if (isEditMode) {
        //@ts-ignore
        variables.id = note.id;
      }
      
      mutateNote({
        //@ts-ignore
        variables,
      });
      
      if (!isEditMode) {
        resetForm();
      }
    },
  });

  // Focus textarea on mount
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
      // If editing, place cursor at end of text
      if (isEditMode && note?.body) {
        textAreaRef.current.setSelectionRange(note.body.length, note.body.length);
      }
    }
  }, [isEditMode, note?.body]);

  // Handle escape key press for edit mode
  useEffect(() => {
    if (!isEditMode || !onCancel) return;
    
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onCancel();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isEditMode, onCancel]);

  const rowsCount = compact ? 2 : 3;
  const placeholder = isEditMode ? t('Edit note') : parentId ? t('Add your reply') : t('Add a note');

  return (
    <div className={`w-full bg-white flex gap-x-2 ${compact ? 'mt-2' : 'mt-4'}`}>
      
      <form onSubmit={handleSubmit} className="relative flex-1">
        <div className="overflow-hidden rounded-lg pb-8 shadow-sm ring-1 ring-inset ring-cgray-300 focus-within:ring-2 focus-within:ring-cblue-500">
          <label htmlFor="body" className="sr-only">
            {placeholder}
          </label>
          <textarea
            ref={textAreaRef}
            rows={rowsCount}
            name="body"
            id="body"
            className="block w-full resize-none border-0 bg-transparent py-1.5 px-2 text-cgray-900 placeholder:text-cgray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder={placeholder}
            value={values.body}
            onChange={handleChange}
          />
        </div>

        <div className="absolute bottom-0 right-0 flex justify-end py-1.5 pr-1.5 space-x-2">
          {/* Only show cancel button in edit mode */}
          {isEditMode && onCancel && (
            <button
              type="button"
              onClick={onCancel}
              className="bg-cgray-200 hover:bg-cgray-300 text-cgray-700 hover:text-cgray-800 transition duration-150 relative flex justify-center items-center px-2 py-1 rounded"
            >
              <IconX size={16} />
            </button>
          )}
          
          <button
            type="submit"
            disabled={isSubmitting}
            className={classNames(
              isSubmitting 
                ? 'bg-cgray-300 text-cgray-300' 
                : 'bg-cblue-500 hover:bg-cblue-700 hover:opacity-90 transition duration-150', 
              'relative flex justify-center items-center px-2 py-1 rounded text-white',
            )}
          >
            {isSubmitting 
              ? <LoadingIndicator color="white" className="h-4 w-4" />
              : <IconSend size={16} />
            }
          </button>
        </div>
      </form>
    </div>
  );
};

export default QuotationItemNoteForm; 