import { IconUpload, IconX } from '@tabler/icons-react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
  setBase64File?: (base64: string | null) => void;
}

export default function X83Section({ selectedFile, setSelectedFile, setBase64File }: Props) {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setSelectedFile(file);
      
      // Convert to base64 if needed
      if (setBase64File) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          // Extract just the base64 part if it includes the data URL prefix
          const base64Content = base64String.includes(',') 
            ? base64String.split(',')[1] 
            : base64String;
          setBase64File(base64Content);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setSelectedFile(file);
      
      // Convert to base64 if needed
      if (setBase64File) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          // Extract just the base64 part if it includes the data URL prefix
          const base64Content = base64String.includes(',') 
            ? base64String.split(',')[1] 
            : base64String;
          setBase64File(base64Content);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <div>
      <div className="w-[800px]">
        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('Source files upload (Optional):')}</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">{t('Import project from X83 file')}</p>
      </div>
      
      <div 
        className={`relative mt-4 flex justify-center rounded-lg border border-dashed px-6 py-4 ${selectedFile && 'bg-green-100'} ${
          isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-900/25'
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {selectedFile && (
          <button 
            type="button" 
            className="absolute top-1 right-1 text-red-500 hover:text-red-700"
            onClick={() => {
              setSelectedFile(null);
              if (setBase64File) setBase64File(null);
              if (fileInputRef.current) fileInputRef.current.value = '';
            }}
          >
            <IconX className="h-6 w-6" />
          </button>
        )}
        <div className="text-center">
          {!selectedFile && (
            <>
              <IconUpload className="mx-auto h-8 w-8 text-gray-300" aria-hidden="true" />
              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-cblue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-cblue-500 focus-within:ring-offset-2 hover:text-cblue-500"
                >
                  <span>{t('Upload a file')}</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    onChange={handleFileChange}
                  />
                </label>
                <p className="pl-1">{t('or drag and drop')}</p>
              </div>
              <p className="text-xs leading-5 text-gray-600 mt-2">{t('XLS, XLSX, X83 up to 10MB')}</p>
              {selectedFile && (
                <div className="flex flex-col gap-1 mt-2">
                  <span className="text-sm text-gray-700">{t('Selected file')}: {selectedFile && (selectedFile as File).name}</span>
                  <button
                    type="button"
                    className="text-xs text-cred-500 hover:text-cred-700"
                    onClick={() => {
                      setSelectedFile(null);
                      if (setBase64File) setBase64File(null);
                      if (fileInputRef.current) fileInputRef.current.value = '';
                    }}
                  >
                    {t('Remove')}
                  </button>
                </div>
              )}
            </>
          )}
          
          {selectedFile && (
            <div className="flex items-center justify-center p-8 text-sm text-gray-500">
              <p className="font-medium text-cblue-600">{selectedFile.name}</p>
              <p className="mx-2">{(selectedFile.size / 1024).toFixed(2)} KB</p>
            </div>
          )}
        </div>
      </div>
          <div className="mt-3 text-xs text-cgray-600 max-w-3xl mx-auto">
            <p className="italic">
              {t('Note: Processing uploaded files may take some time. The assigned managers will be notified by email when quotation generation is complete.')}
            </p>
          </div>
    </div>
  );
}