import { useState, useRef, useEffect } from 'react';
import { FeatureType, ValueType } from '../../../../__generated__/graphql';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import SelectInput from '../../../../layout/inputs/SelectInput';
import { setFeature, unsetFeature } from '../../../../redux/searchSlice';

interface Props {
  feature: FeatureType,
}

export default function AlphanumericFeature(props: Props) {
  const { feature } = props;
  const selectedFeatures = useAppSelector(state => state.search.selectedFeatures);
  const selectedFeature = selectedFeatures.find(item => item.id === feature.id);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const selectRef = useRef();
  const dispatch = useAppDispatch();

  //@ts-ignore
  const options = feature?.values?.map((value: ValueType) => (
    { value: value.code, label: value.localizedDescription as string }
  ));

  const handleCheckboxChange = () => {
    if (!isCheckboxChecked) {
      setIsCheckboxChecked(true);
      if (selectRef.current) {
        //@ts-ignore
        selectRef.current.focus(); // Open dropdown when checkbox is checked
      }
    } else {
      setIsCheckboxChecked(false);
      dispatch(unsetFeature(feature.id));
    }
  };

  const handleSelectChange = (selectedOption: SelectOption[]) => {
    if (!!selectedOption.length) {
      const values = selectedOption.map((option) => option.value as string);
      
      setIsCheckboxChecked(true);
      dispatch(setFeature({
        id: feature.id,
        userValue: values,
        code: feature.code,
        featureType: feature.featureType,
      }));
    } else {
      dispatch(unsetFeature(feature.id));
      setIsCheckboxChecked(false);
    }
  };

  useEffect(() => {
    // Initialize state based on selectedFeature
    if (!!options && selectedFeature && !!selectedFeature.userValue) {
      setIsCheckboxChecked(true);
    } else {
      setIsCheckboxChecked(false);
    }
  }, [selectedFeature, options]);

  return (
    <>
      <div className="relative w-4 ml-2">
        {selectedFeature && (
          <div className="absolute inset-y-0 -left-2 w-0.5 bg-cblue-500" />
        )}
        <input
          type="checkbox"
          className="absolute left-0 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-cblue-500 focus:ring-cblue-500 grow"
          checked={!!selectedFeature && !!isCheckboxChecked}
          onChange={handleCheckboxChange}
        />
      </div>
      <div className='w-full'>
        <SelectInput
          //@ts-ignore
          ref={selectRef}
          openMenuOnFocus
          options={options}
          isMulti
          isClearable
          multiValue={selectedFeature && options && options.filter((item: { value: string; label: string }) => (selectedFeature.userValue as string[]).includes(item.value))}
          placeholder={feature.localizedDescription as string}
          showLabel={!!selectedFeature}
          onMultiChange={handleSelectChange}
        />
      </div>
    </>
  );
}
