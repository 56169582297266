import { useMutation, useQuery } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductValuesReport, ServiceValuesReport } from '../../../../__generated__/graphql';
import { CREATE_QUOTATION_MUTATION } from '../../../../api/mutations/quotations/quotation';
import { QUOTATION_VALUES_REPORT_QUERY, QUOTATIONS_QUERY } from '../../../../api/queries/quotations/quotation';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { LoadingIndicator } from '../../../../layout';
import ModalButtons from '../../../../layout/inputs/ModalButtons';
import Switch from '../../../../layout/inputs/Switch';
import { setSuccessAlert } from '../../../../redux/alertSlice';
import { setIsCheckValuesOverlayOpen } from '../../../../redux/quotationSlice';
import { ValuesChangesTable, ItemFieldsToUpdateInput, ChangedFields } from './ValuesChangesTable';

export default function CheckValuesOverlay() {
  const isCheckValuesOverlayOpen = useAppSelector(state => state.quotation.isCheckValuesOverlayOpen);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { quotationId, projectId } = useParams();
  const { t } = useTranslation();
  const [changedProductFields, setChangedProductFields] = useState<ChangedFields>({});
  const [changedServiceFields, setChangedServiceFields] = useState<ChangedFields>({});
  const [showServiceFields, setShowServiceFields] = useState(false);

  const hasChanges = Object.values(changedProductFields).some(fields => 
    Object.values(fields).some(isSelected => isSelected),
  ) || Object.values(changedServiceFields).some(fields => 
    Object.values(fields).some(isSelected => isSelected),
  );

  const [
    createQuotation,
    {
      loading: createQuotationLoading,
    }] = useMutation(CREATE_QUOTATION_MUTATION, { 
    refetchQueries: [
      { 
        query: QUOTATIONS_QUERY,
        variables: {
          project: projectId,
        },
      },
    ],
  });

  const {
    data: valuesReportData,
    loading: valuesReportLoading,
    refetch: valuesReportRefetch,
  } = useQuery(QUOTATION_VALUES_REPORT_QUERY, {
    variables: {
      quotation: quotationId as string,
    },
    skip: !quotationId || !isCheckValuesOverlayOpen,
  });

  const products = valuesReportData?.quotationValuesReport?.response?.products;
  const services = valuesReportData?.quotationValuesReport?.response?.services;
  
  const hasProducts = !!products?.length;
  const hasServices = !!services?.length;
  const hasBoth = hasProducts && hasServices;

  useEffect(() => {
    // Fetch data on each overlay display
    if (isCheckValuesOverlayOpen) {
      valuesReportRefetch();
    }
  }, [isCheckValuesOverlayOpen, valuesReportRefetch]);
  
  // Automatically set showServiceFields based on available data
  useEffect(() => {
    if (hasServices && !hasProducts) {
      setShowServiceFields(true);
    } else if (hasProducts && !hasServices) {
      setShowServiceFields(false);
    }
  }, [hasProducts, hasServices]);

  const handleSubmit = () => {
    if (!quotationId) return;

    // Prepare update data for products - include all products with selected fields
    const productUpdates = Object.entries(changedProductFields).reduce<Array<ItemFieldsToUpdateInput>>((acc, [itemId, fields]) => {
      const fieldsToUpdate = Object.entries(fields)
        .filter(([, isSelected]) => isSelected)
        .map(([field]) => field);
      
      if (fieldsToUpdate.length > 0) {
        acc.push({
          item_id: itemId,
          fields_to_update: fieldsToUpdate,
        });
      }
      return acc;
    }, []);

    // Prepare update data for services - include all services with selected fields
    const serviceUpdates = Object.entries(changedServiceFields).reduce<Array<ItemFieldsToUpdateInput>>((acc, [itemId, fields]) => {
      const fieldsToUpdate = Object.entries(fields)
        .filter(([, isSelected]) => isSelected)
        .map(([field]) => field);
      
      if (fieldsToUpdate.length > 0) {
        acc.push({
          item_id: itemId,
          fields_to_update: fieldsToUpdate,
        });
      }
      return acc;
    }, []);

    createQuotation({ 
      variables: { 
        quotation: quotationId,
        updateItemsData: JSON.stringify([...productUpdates, ...serviceUpdates]),
      },
      onCompleted: (data) => {
        if (data?.createQuotation?.response) {
          dispatch(setSuccessAlert(
            [`${data.createQuotation.response.name} quotation was successfully created.`],
          ));
          dispatch(setIsCheckValuesOverlayOpen(false));
          navigate(`/quotation/${projectId}/${data.createQuotation.response.id}/`);
        }
      },
    });
  };

  return (
    <Transition.Root show={isCheckValuesOverlayOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-40 text-sm" onClose={() => dispatch(setIsCheckValuesOverlayOpen(false))}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-white/10" />

          <div className="fixed w-11/12 inset-y-0 right-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 -left-8 flex bg-cgray-200">
                    <button
                      type="button"
                      className="text-cgray-500"
                      onClick={() => dispatch(setIsCheckValuesOverlayOpen(false))}
                    >
                      <IconX className="h-8 w-8" />
                    </button>
                  </div>
                </Transition.Child>
                <div id="styled-scroll" className="pb-4 grow flex h-full bg-white shadow-xl overflow-y-auto">
                  <div className='grow flex justify-start items-start flex-col h-full w-full rounded bg-white' onClick={e => e.stopPropagation()}>
                    {valuesReportLoading && <LoadingIndicator className='w-full h-full flex items-center justify-center' />}
                    {(!valuesReportLoading && products) && (
                      <>
                        <ValuesChangesTable 
                          products={products as ProductValuesReport[]}
                          services={services as ServiceValuesReport[]}
                          changedProductFields={changedProductFields}
                          setChangedProductFields={setChangedProductFields}
                          changedServiceFields={changedServiceFields}
                          setChangedServiceFields={setChangedServiceFields}
                          showServiceFields={showServiceFields}
                        />
                        {hasChanges && (
                          <div className='flex items-center justify-center w-full mt-6'>
                            {t('Quotation will be copied with the selected updates.')}
                          </div>
                        )}
                        <div className='flex items-center justify-between w-full px-6 mt-6'>
                          {hasBoth && (
                            <Switch
                              value={showServiceFields}
                              onChange={()=>{setShowServiceFields(!showServiceFields);}}
                              className='flex items-center gap-2 shrink-0'
                              label={t('Show service fields')}
                              checkedIcon={<div className='w-3 h-3 text-sm mb-1.5'>S</div>}
                              uncheckedIcon={<div className='w-3 h-3 text-sm mb-1.5'>P</div>}
                            />
                          )}
                          <ModalButtons 
                            mutationLoading={createQuotationLoading} 
                            cancelButtonText='Cancel' 
                            submitButtonText="Submit" 
                            handleClose={() => dispatch(setIsCheckValuesOverlayOpen(false))} 
                            onSubmit={handleSubmit}
                            wrapperClassName='mt-6 mb-6 flex items-center justify-end w-full gap-x-8 pr-6'
                            isSubmitButtonDisabled={valuesReportLoading}
                            />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
