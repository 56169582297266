import { IconExternalLink } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import config from '../../../../config';

interface Props {
  quotationProductId?: string;
  storageProductId: string;
}

export default function ExternalLinks({ quotationProductId, storageProductId }: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex pb-2 mt-auto">
      <div className='flex gap-2 ml-auto'>
        {quotationProductId && (
          <a
            target='_blank'
            href={`${config.API_HOST}/internal/quotations/product/${quotationProductId}`}
            className="flex items-center gap-1 rounded bg-white px-2.5 text-xs font-semibold shadow-sm ring-1 ring-inset ring-cgray-300 hover:bg-cgray-100"
          >
            <IconExternalLink className="-ml-0.5 w-4" />
            {t('Quotation Product')}
          </a>
        )}
        <a
          target='_blank'
          href={`${config.API_HOST}/internal/products_storage/storageproduct/${storageProductId}`}
          className="flex items-center gap-1 rounded bg-white px-2.5 text-xs font-semibold shadow-sm ring-1 ring-inset ring-cgray-300 hover:bg-cgray-100"
        >
          <IconExternalLink className="-ml-0.5 w-4" />
          {t('Storage Product')}
        </a>
      </div>
    </div>
  );
} 