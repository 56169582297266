module.exports = {
  'cblue-100': '#F1F6F9', // Table header background, dialog header background
  'cblue-200': '#E4EDF5', // Hover
  'cblue-300': '#CADDEB', // Focus
  'cblue-500': '#005DA3', // Action color
  'cblue-700': '#003366', // Primary button hover & focus

  'cgray-100': '#F4F4F4', // Disabled items, Inactive for Textfield
  'cgray-200': '#e9e9e9', // Coming?
  'cgray-300': '#d3d3d3', // Divider, lines, contours - Hover, Coming - Inactive hover for Textfield
  'cgray-400': '#b2b2b2', // Divider, lines, contours. Disabled items
  'cgray-500': '#505050', // Another Disabled items?
  'cgray-600': '#282828', // Headline
  'cgray-700': '#27272a', // Text
  'cgray-800': '#18181b', // Customer pallete doesn't include this
  'cgray-900': '#09090b', // Customer pallete doesn't include this

  'cgreen-100': '#DCFCE7', // EXTRA LIGHT GREEN - more saturated
  'cgreen-200': '#BFFCCB', // LIGHT GREEN - more saturated
  'cgreen-300': '#86EFAC', // LIGHT GREEN - even more saturated
  'cgreen-500': '#5ACB6B', // Success
  'cgreen-700': '#338541', // Darker success


  'cred-100': '#FFF1F1', // EXTRA LIGHT RED - lighter version
  'cred-300': '#FDB6B6', // Highlights, missing items
  'cred-500': '#FF4040', // Error
  'cred-700': '#CC0000', // Darker error or critical status

  'cyellow-100': '#FFFBE6', // Lighter highlights
  'cyellow-200': '#FCF9C3', // Pinned instance
  'cyellow-400': '#FCE096', // Highlights, deviations
  'cyellow-500': '#FFD872', // Warning, obsolete indicator
  'cyellow-700': '#FFC700', // Darker highlights
  'cyellow-800': '#FFB400', // Darkest highlights
  'cyellow-900': '#a66c00', // Mega Darkest highlights
};
