export const SIGNATURE_EXPIRED_ERROR = 'Signature has expired';
export const REFRESH_TOKEN_EXPIRED_ERROR = 'Refresh token is expired';

export enum AlertType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum SearchType {
  DESCRIPTION = 'DESCRIPTION',
  ETIM = 'ETIM',
  QUOTATION = 'QUOTATION',
  UNIVERSAL = 'UNIVERSAL',
}

export enum SearchResultsType {
  PRODUCTS = 'PRODUCTS',
  All = 'ALL',
  GROUPS = 'GROUPS',
}

export enum ComposeModeSearchType {
  DEFAULT = 'DEFAULT',
  GROUPS = 'GROUPS',
}

export enum MaxUncollapsedDepthSource {
  QuotationListStaticItem = 'QuotationListStaticItem',
  QuotationListDndItem = 'QuotationListDndItem',
}

export enum SelectedColumnName {
  SURCHARGE = 'SURCHARGE',
  QUANTITY = 'QUANTITY',
  POSITION = 'POSITION',
  DESCRIPTION = 'DESCRIPTION',
  TIME = 'TIME',
  WAGE = 'WAGE',
  POSITION_TYPE = 'POSITION_TYPE',
}

export const GROUP_DETAILS_ROW_HEIGHT = 330;
export const PRODUCT_DETAILS_ROW_HEIGHT = 484;
export const SERVICE_DETAILS_ROW_HEIGHT = 250;
export const ROW_HEIGHT = 52;
export const GROUP_ROW_HEIGHT = 53;

export enum GroupCollapseState {
  ALL_COLLAPSED = 'ALL_COLLAPSED',
  ALL_EXPANDED = 'ALL_EXPANDED',
  MIXED = 'MIXED',
}
