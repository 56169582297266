import { gql } from '../../../__generated__/gql';

export const QUOTATION_QUERY = gql(`
  query Quotation($quotation: ID!){
    quotation(
      quotation: $quotation
      ){
        response {
          id
          name
          versionType
          versionTypeLabel
          versionNumber
          generateItemsPositions
          totalSalesPriceLabel
          itemsNotesQuantity
          items {
            id
            itemType
            order
            parentId
            position
            quantity
            group {
              description
              hasManualSalesPricePiece
              id
              representsTemplate
              positionType
              isProductionUnit
              productionStatus{
                id
                title
                color
                isReadonly
                isCompleted
              }
              groupComputation{
                calculatedNetSalesPricePieceIncludingDiscountLabel
                netSalesPricePieceLabel
                netPricePieceLabel
                netSalesPricePieceIncludingDiscountLabel
                time
                totalProfitLabel
                totalSalesPriceLabel
              }
            }
            product {
              description
              externalId
              id
              catalogId
              manufacturingWage{
                id
                costLabel
                wageGroupLabel
                label
              }
              salesWage{
                id
                costLabel
                wageGroupLabel
                label
              }
              productComputation{
                id
                netPricePieceLabel
                netSalesPricePieceLabel
                totalSalesPriceLabel
                totalProfitLabel
              }
              profitSurcharge
              positionType
              supplierLabel
              manufacturingTime
              unit
              salesTime
            }
            service {
              description
              id
              notes
              profitSurcharge
              serviceWage {
                costLabel
                id
                isCurrent
                label
                wageGroupLabel
              }
              serviceTime
              administrativeOverhead {
                id
                isCurrent
                surcharge
              }
              manufacturingOverhead {
                id
                isCurrent
                surcharge
              }
              salesOverhead {
                id
                isCurrent
                surcharge
              }
              serviceComputation{
                netSalesPricePieceLabel
                totalProfitLabel
                totalSalesPriceLabel
              }
            }
          }
        }
        errors {
          messages
          field
        }
    }
  }
`);

export const QUOTATIONS_QUERY = gql(`
  query Quotations($project: ID!){
    quotations(
      project: $project
      ){
        response {
          id
          name
          versionType
        }
        errors {
          messages
          field
        }
    }
  }
`);


export const QUOTATION_PDF_QUERY = gql(`
query quotationPdf($quotation: ID!){
  quotationPdf(
    quotation: $quotation
  ){
    response{
      body
      id
      selectedGroups {
        id
      }
      useAllGroups
      versionType
    }
    errors{
      field
      messages
    }
  }
}
`);

export const QUOTATION_VALUES_REPORT_QUERY = gql(`
  query QuotationValuesReport($quotation: ID!) {
    quotationValuesReport(
      quotation: $quotation
    ) {
      response {
        products {
          itemId
          position
          description
          oldValues {
            notes
            profitSurcharge
            manufacturingTime
            specialPrice
            netPricePiece
              manufacturingWage {
                id
                wageGroup
              }
              manufacturingOverhead {
                id
                surcharge
              }
              administrativeOverhead {
                id
                surcharge
              }
              salesOverhead {
                id
                surcharge
              }
              materialCostBurdenRate {
                id
                surcharge
              }
              productDiscount {
                id
                label
              }
            }
            newValues {
              notes
              profitSurcharge
              manufacturingTime
              specialPrice
              netPricePiece
              manufacturingWage {
                id
                wageGroup
              }
              manufacturingOverhead {
                id
                surcharge
              }
              administrativeOverhead {
                id
                surcharge
              }
              salesOverhead {
                id
                surcharge
              }
              materialCostBurdenRate {
                id
                surcharge
              }
              productDiscount {
                id
                label
              }
            }
        }
        services {
          itemId
          position
          description
          oldValues {
            manufacturingOverhead {
              id
              surcharge
            }
            administrativeOverhead {
              id
              surcharge
            }
            salesOverhead {
              id
              surcharge
            }
          }
          newValues {
            manufacturingOverhead {
              id
              surcharge
            }
            administrativeOverhead {
              id
              surcharge
            }
            salesOverhead {
              id
              surcharge
            }
          }
        }
      }
      errors {
        messages
        field
      }
    }
  }
`);

