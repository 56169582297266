import { useQuery } from '@apollo/client';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { ProductValuesReport } from '../../../../__generated__/graphql';
import { MANUFACTURING_WAGES_QUERY } from '../../../../api/queries/settings';
import { areValuesDifferent } from '../../../../helpers/utils';
import { ValueCell, ValueType } from './ValueCell';

interface ProductChangesTableProps {
  products: ProductValuesReport[];
  selectedProducts: string[];
  handleProductSelect: (id: string) => void;
  handleSelectAll: () => void;
  handleFieldSelect: (itemId: string, field: string) => void;
  isFieldSelected: (itemId: string, field: string) => boolean;
  handleColumnSelect: (columnKey: string) => void;
}

interface ColumnConfig {
  key: string;
  width: string;
  label: string;
  shortLabel: string;
  valueType: ValueType;
}

export const ProductChangesTable = ({ 
  products,
  selectedProducts,
  handleProductSelect,
  handleSelectAll,
  handleFieldSelect,
  isFieldSelected,
  handleColumnSelect,
}: ProductChangesTableProps) => {
  const { t } = useTranslation();
  const { data: manufacturingWagesData } = useQuery(MANUFACTURING_WAGES_QUERY);

  const getManufacturingWageLabel = (wageId: string) => {
    const wage = manufacturingWagesData?.manufacturingWages?.response?.find(
      w => w?.id === wageId,
    );
    return wage ? wage.label : '-';
  };

  // Configuration array defining the table columns:
  // - key: property name in the data object
  // - width: CSS width class for the column
  // - label: full column name for tooltip
  // - shortLabel: abbreviated column name shown in header
  // - valueType: type of value for proper rendering and formatting
  const columns: ColumnConfig[] = [
    { key: 'notes', width: 'w-80', label: 'Notes', shortLabel: 'Notes', valueType: ValueType.NOTES },
    { key: 'manufacturingTime', width: 'w-20', label: 'Manufacturing Time', shortLabel: 'Man. Time', valueType: ValueType.MANUFACTURING_TIME },
    { key: 'manufacturingWage', width: 'w-36', label: 'Manufacturing Wage', shortLabel: 'Man. Wage', valueType: ValueType.MANUFACTURING_WAGE },
    { key: 'manufacturingOverhead', width: 'w-20', label: 'Manufacturing Overhead', shortLabel: 'Man. OH', valueType: ValueType.MANUFACTURING_OVERHEAD },
    { key: 'administrativeOverhead', width: 'w-20', label: 'Administrative Overhead', shortLabel: 'Admin. OH', valueType: ValueType.ADMINISTRATIVE_OVERHEAD },
    { key: 'salesOverhead', width: 'w-20', label: 'Sales Overhead', shortLabel: 'Sales OH', valueType: ValueType.SALES_OVERHEAD },
    { key: 'materialCostBurdenRate', width: 'w-20', label: 'Material Cost Burden', shortLabel: 'Mat. Cost', valueType: ValueType.MATERIAL_COST_BURDEN },
    { key: 'profitSurcharge', width: 'w-20', label: 'Profit Surcharge', shortLabel: 'Profit', valueType: ValueType.PROFIT_SURCHARGE },
    { key: 'specialPrice', width: 'w-20', label: 'Special Price', shortLabel: 'Spec. Price', valueType: ValueType.SPECIAL_PRICE },
    { key: 'netPricePiece', width: 'w-20', label: 'Net Price', shortLabel: 'Net Price', valueType: ValueType.NET_PRICE },
    { key: 'productDiscount', width: 'w-20', label: 'Discount', shortLabel: 'Discount', valueType: ValueType.PRODUCT_DISCOUNT },
  ];

  const hasColumnChanges = (columnKey: string) => {
    return products?.some(product => {
      const oldValue = product?.oldValues?.[columnKey as keyof typeof product.oldValues];
      const newValue = product?.newValues?.[columnKey as keyof typeof product.newValues];
      
      return areValuesDifferent(oldValue, newValue, columnKey, getManufacturingWageLabel);
    });
  };

  const isColumnSelected = (columnKey: string) => {
    return products?.every(product => {
      const itemId = product?.itemId as string;
      const oldValue = product?.oldValues?.[columnKey as keyof typeof product.oldValues];
      const newValue = product?.newValues?.[columnKey as keyof typeof product.newValues];
      
      const hasChanges = areValuesDifferent(oldValue, newValue, columnKey, getManufacturingWageLabel);
      return !hasChanges || isFieldSelected(itemId, columnKey);
    });
  };

  const tableContent = (
    <>
      <div className="w-full h-[52px] flex items-center gap-6 bg-cblue-100 border-b-2 border-t-2 border-cblue-500 mb-3 font-bold text-sm text-cgray-600">
        <div className='w-14 flex items-center justify-center'>
          <Tippy content={selectedProducts.length > 0 ? t('Deselect All') : t('Select All')}>
            <input
              type="checkbox"
              className="h-5 w-5 rounded border-gray-300 text-cblue-500 focus:ring-0 focus:ring-offset-0 focus:outline-none cursor-pointer"
              checked={!!selectedProducts?.length}
              onChange={handleSelectAll}
            />
          </Tippy>
        </div>
        <div className="w-16 flex flex-wrap items-center">
          <Tippy content={t('Position')}><span>{t('Position')}</span></Tippy>
        </div>
        {/* Render table headers for each column */}
        {columns.map(column => (
          <div key={column.key} className={`${column.width} flex flex-wrap items-center gap-2`}>
            <Tippy content={t(column.label)}>
              <div className="flex items-center gap-2">
                {hasColumnChanges(column.key) ? (
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-cblue-500 focus:ring-0 focus:ring-offset-0 focus:outline-none cursor-pointer"
                    checked={isColumnSelected(column.key)}
                    onChange={() => handleColumnSelect(column.key)}
                  />
                ) : null}
                <span>{t(column.shortLabel)}</span>
              </div>
            </Tippy>
          </div>
        ))}
      </div>
      
      <div className="flow-root w-full mt-4 h-full overflow-y-auto">
        <ul className="space-y-0">
          {/* Render each product row with its values and change indicators */}
          {products.map((product, index) => {
            const isProductSelected = selectedProducts.includes(product?.itemId as string);
            const itemId = product.itemId as string;
            
            return (
              <li 
                key={index}
                className='relative flex items-center py-2 gap-6 border border-cgray-200 cursor-pointer'
              >
                <div className="relative w-14 flex items-center justify-center">
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-cblue-500 focus:ring-0 focus:ring-offset-0 focus:outline-none cursor-pointer"
                    checked={isProductSelected}
                    onChange={() => {
                      handleProductSelect(itemId);
                    }}
                  />
                </div>
                <div className="w-16 h-32 flex items-start justify-start truncate break-all">
                  <ValueCell 
                    oldValue={product?.position?.length ? product?.position : t('empty')} 
                    newValue={product?.position?.length ? product?.position : t('empty')}
                    valueType={ValueType.POSITION}
                    isSelected={isProductSelected}
                    description={product?.description ?? ''}
                  />
                </div>
                {columns.map(column => (
                  <div key={column.key} className={`${column.width} h-32 flex items-start justify-start truncate`}>
                    <ValueCell 
                      oldValue={product?.oldValues?.[column.key as keyof typeof product.oldValues]} 
                      newValue={product?.newValues?.[column.key as keyof typeof product.newValues]}
                      valueType={column.valueType}
                      isSelected={isFieldSelected(itemId, column.key)}
                      onClick={() => handleFieldSelect(itemId, column.key)}
                    />
                  </div>
                ))}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );

  return (
    <div>
      {tableContent}
    </div>
  );
}; 