import { useQuery } from '@apollo/client';
import type { UniqueIdentifier } from '@dnd-kit/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemTypeChoices, QuotationItemNoteType } from '../../../__generated__/graphql';
import { CURRENT_USER_QUERY } from '../../../api/queries/users';
import { IconG, IconP, IconS } from '../../../layout/icons';
import QuotationItemNoteCard, { Author } from './QuotationItemNoteCard';
import QuotationItemNoteForm from './QuotationItemNoteForm';
interface QuotationItemNoteProps {
  note: QuotationItemNoteType;
  itemId: UniqueIdentifier;
  itemType: ItemTypeChoices;
  itemTitle: string;
  onMouseEnter: (itemId: UniqueIdentifier) => void;
  onMouseLeave: () => void;
}

const QuotationItemNote: React.FC<QuotationItemNoteProps> = ({
  note,
  itemId,
  itemType,
  itemTitle,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { t } = useTranslation();
  const [showReplies, setShowReplies] = useState(false);
  
  // Get current user from Apollo cache
  const { data: userData } = useQuery(CURRENT_USER_QUERY);
  const currentUser = userData?.user;

  // Toggle replies visibility
  const toggleReplies = () => {
    setShowReplies(!showReplies);
  };

  // Handle successful reply submission
  const handleReplyAdded = () => {
    // Ensure replies are visible after adding a new one
    if (!showReplies) {
      setShowReplies(true);
    }
  };

  const replyCount = note.replies?.length || 0;

  return (
    <div 
      className="rounded-lg overflow-hidden mb-4"
      onMouseEnter={() => onMouseEnter(itemId)}
      onMouseLeave={onMouseLeave}
    >
      {/* Main note card with item header */}
      <div className="p-3 bg-white rounded-lg border border-cgray-200 mb-0">
        {/* Item reference at the top */}
        <div className="flex items-center text-sm font-medium text-cgray-700 mb-3 gap-2">
          {itemType === ItemTypeChoices.Group && (
            <IconG className={'relative w-6 h-6 flex items-center justify-center border-2 rounded cursor-pointer bg-cblue-200 font-bold text-cblue-500 border-cblue-500'} />
          )}
          {itemType === ItemTypeChoices.Product && (
            <IconP className={'w-6 h-6 flex items-center border-2 justify-center font-bold rounded cursor-pointer bg-cblue-500 text-white border-cblue-500'} />
          )}
          {itemType === ItemTypeChoices.Service && (
            <IconS className={'w-6 h-6 flex items-center border-2 justify-center font-bold rounded cursor-pointer bg-cblue-500 text-white border-cblue-500'} />
          )}
          {itemTitle}
        </div>
        <hr className="mb-3" />
        
        {/* Note card contents */}
        <QuotationItemNoteCard
          id={note.id}
          body={note.body}
          author={note.author as Author}
          createdAt={note.createdAt}
          className="p-0 border-0 mb-0"
          itemId={String(itemId)}
        />
        
        {/* Replies section with toggle */}
        <div className="mt-2 pt-2 border-t border-cgray-200">
          <div 
            className="flex items-center text-xs cursor-pointer" 
            onClick={toggleReplies}
          >
            <span className="text-cgray-600 font-medium">
              {replyCount > 0 ? `${replyCount} ${replyCount === 1 ? t('reply') : t('replies')}` : t('No replies. Click here to reply.')}
            </span>
            {replyCount > 0 && (
              <>
                <div className="flex-grow mx-2 border-b border-dashed border-cgray-300" />
                <span className="text-cblue-500 hover:text-cblue-700">
                  {showReplies ? t('hide') : t('show')}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      
      {/* Replies section */}
      {showReplies && (
        <div className="mt-3 pl-5 border-l-2 border-cblue-300">
          {/* Existing replies */}
          {note.replies && note.replies.length > 0 && note.replies.map(reply => (
            <QuotationItemNoteCard
              key={reply?.id}
              id={reply?.id}
              body={reply?.body || ''}
              author={reply?.author as Author}
              createdAt={reply?.createdAt}
              compact={true}
              itemId={String(itemId)}
              parentId={note.id}
            />
          ))}
          
          {/* Reply form */}
          {currentUser && (
            <QuotationItemNoteForm
              parentId={note.id}
              itemId={String(itemId)}
              compact={true}
              onReplyAdded={handleReplyAdded}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default QuotationItemNote; 