import { useQuery } from '@apollo/client';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { AdministrativeOverheadType, DiscountType, ManufacturingOverheadType, MaterialCostBurdenRateType, SalesOverheadType, WageType } from '../../../../__generated__/graphql';
import { MANUFACTURING_WAGES_QUERY } from '../../../../api/queries/settings';
import { classNames } from '../../../../helpers/utils';

export enum ValueType {
  NET_PRICE = 'netPrice',
  PROFIT_SURCHARGE = 'profitSurcharge',
  SPECIAL_PRICE = 'specialPrice',
  MANUFACTURING_TIME = 'manufacturingTime',
  NOTES = 'notes',
  MANUFACTURING_WAGE = 'manufacturingWage',
  MANUFACTURING_OVERHEAD = 'manufacturingOverhead',
  ADMINISTRATIVE_OVERHEAD = 'administrativeOverhead',
  SALES_OVERHEAD = 'salesOverhead',
  MATERIAL_COST_BURDEN = 'materialCostBurdenRate',
  PRODUCT_DISCOUNT = 'productDiscount',
  POSITION = 'position',
}

type ValueCellValue = string | number | null | undefined | WageType | AdministrativeOverheadType | ManufacturingOverheadType | MaterialCostBurdenRateType | DiscountType | SalesOverheadType;
type OverheadType = AdministrativeOverheadType | ManufacturingOverheadType | SalesOverheadType | MaterialCostBurdenRateType;

interface ValueCellProps {
  oldValue: ValueCellValue;
  newValue: ValueCellValue;
  valueType: ValueType;
  isSelected: boolean;
  description?: string;
  onClick?: () => void;
}

export function ValueCell({ oldValue, newValue, valueType, isSelected, description, onClick }: ValueCellProps) {
  const { t } = useTranslation();
  const { data: manufacturingWagesData } = useQuery(MANUFACTURING_WAGES_QUERY);

  const getManufacturingWageLabel = (wageId: string) => {
    const wage = manufacturingWagesData?.manufacturingWages?.response?.find(
      w => w?.id === wageId,
    );
    return wage ? wage.label : '-';
  };

  const hasValueChanged = (oldVal: ValueCellValue, newVal: ValueCellValue) => {
    // Handle null/undefined cases first
    if (oldVal == null && newVal == null) {
      return false;
    }
    if (oldVal == null || newVal == null) {
      return true;
    }

    // For objects that have an 'id' property (like manufacturingOverhead, salesOverhead etc)
    if (typeof oldVal === 'object' && 'id' in oldVal && typeof newVal === 'object' && 'id' in newVal) {
      return oldVal.id !== newVal.id;
    }

    // For simple values (notes, netPricePiece, profitSurcharge etc)
    return oldVal !== newVal;
  };

  const formatValue = (value: ValueCellValue, forTippy = false) => {
    if (value == null) return '-';

    // Handle objects
    if (typeof value === 'object') {
      switch (valueType) {
        case ValueType.MANUFACTURING_WAGE:
          const wageLabel = getManufacturingWageLabel((value as WageType).id);
          const isSameWageValue = oldValue && newValue && 
            (oldValue as WageType).id !== (newValue as WageType).id && 
            getManufacturingWageLabel((oldValue as WageType).id) === getManufacturingWageLabel((newValue as WageType).id);
          
          if (isSameWageValue && forTippy && value === oldValue) {
            return `${wageLabel} (${t('current')})`;
          }
          return wageLabel;
        
        case ValueType.MANUFACTURING_OVERHEAD:
        case ValueType.ADMINISTRATIVE_OVERHEAD:
        case ValueType.SALES_OVERHEAD:
        case ValueType.MATERIAL_COST_BURDEN:
          const surcharge = (value as OverheadType).surcharge ?? '-';
          const isSameSurchargeValue = oldValue && newValue &&
            (oldValue as OverheadType).id !== (newValue as OverheadType).id &&
            (oldValue as OverheadType).surcharge === (newValue as OverheadType).surcharge;

          if (isSameSurchargeValue && forTippy && value === oldValue) {
            return `${surcharge} (${t('current')})`;
          }
          return surcharge;
        
        case ValueType.PRODUCT_DISCOUNT:
          const label = String((value as DiscountType).label ?? '-');
          const isSameDiscountValue = oldValue && newValue &&
            (oldValue as DiscountType).id !== (newValue as DiscountType).id &&
            (oldValue as DiscountType).label === (newValue as DiscountType).label;

          if (isSameDiscountValue && forTippy && value === oldValue) {
            return `${label} (${t('current')})`;
          }
          return label;
        
        default:
          return '-';
      }
    }

    // Handle simple values (notes, netPricePiece, profitSurcharge etc)
    return String(value);
  };

  const hasChanged = hasValueChanged(oldValue, newValue);

  const getStyleClasses = () => {
    if (!hasChanged) return '';
    
    return isSelected 
      ? 'border-l-2 border-cgreen-300 bg-cgreen-100'
      : 'border-l-2 border-cred-300 bg-cred-100';
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onClick && hasChanged) {
      onClick();
    }
  };

  return (
    valueType === ValueType.POSITION ? (
      <div className="w-12 h-32 flex items-start justify-start truncate">
        <Tippy 
          content={
            <div className="p-2 w-full">
              {description}
            </div>
          }
          maxWidth={550}
          interactive={true}
          placement="right"
        >
          <span>{formatValue(oldValue)}</span>
        </Tippy>
      </div>
    ) : (
      <Tippy 
        content={
          <div className="p-2 w-full">
            <table className="w-full text-sm">
              <tbody>
                <tr className="bg-cgreen-100">
                  <td className="py-1 px-2 text-xs font-semibold border-r border-cgreen-300">
                    {(valueType === ValueType.NET_PRICE || valueType === ValueType.PRODUCT_DISCOUNT) ? t('Catalog') : t('Settings')}
                  </td>
                  <td className="py-1 px-2 break-words whitespace-pre-wrap">
                    {formatValue(oldValue, true)}
                  </td>
                </tr>
                <tr className="bg-cred-100">
                  <td className="py-1 px-2 text-xs font-semibold border-r border-cred-300">
                    {t('Quotation')}
                  </td>
                  <td className="py-1 px-2 break-words whitespace-pre-wrap bg-cred-100">
                    {formatValue(newValue)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        } 
        disabled={!hasChanged}
        maxWidth={550}
        interactive={true}
        placement="right"
      >
        <div 
          className={classNames(
            'px-2 py-1 h-full w-full overflow-y-auto whitespace-pre-wrap break-words',
            getStyleClasses(),
            hasChanged ? 'cursor-pointer' : '',
          )}
          onClick={handleClick}
        >
          {isSelected ? formatValue(oldValue) : formatValue(newValue)}
        </div>
      </Tippy>
    )
  );
} 