import {
  useApolloClient,
  useMutation,
} from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { QuotationGroupDetailsType } from '../../../../../../../__generated__/graphql';
import { ADD_GROUP_ETIM_CLASS } from '../../../../../../../api/mutations/quotations/group';
import { INLINE_ALL_CLASSES_QUERY } from '../../../../../../../api/queries/etimReleases';
import { QUOTATION_QUERY } from '../../../../../../../api/queries/quotations/quotation';
import { INLINE_RECORDS_SIZE } from '../../../../../../../config';
import { useAppDispatch } from '../../../../../../../helpers/reduxHooks';
import { getInlineOptionsFromQuery } from '../../../../../../../helpers/utils';
import { AsyncSelectInput } from '../../../../../../../layout/inputs';
import { setIsLoadingUpdate } from '../../../../../../../redux/quotationSlice';

interface FormValues {
  class: SelectOption | undefined,
}

interface Props {
  group: QuotationGroupDetailsType,
}

export default function SelectClassForm(props: Props) {
  const { group } = props;
  const { t } = useTranslation();
  const { quotationId } = useParams();

  const client = useApolloClient();
  const query: DocumentNode = INLINE_ALL_CLASSES_QUERY;
  const dispatch = useAppDispatch();

  const [addEtimClassMutation, { loading }] = useMutation(ADD_GROUP_ETIM_CLASS,  {
    refetchQueries: [{
      query: QUOTATION_QUERY,
      variables: { 
        quotation: quotationId as string,
      },
    }],
  });

  const schema = yup.object({
    class: yup.object().required(`${t('Requried')}`),
  });

  const initialClass = group.etimClass ? {
    label: group.etimClass.localizedDescription,
    value: group.etimClass.id,
  } : undefined;

  const initialValues: FormValues = {
    class: initialClass ? {
      label: initialClass.label || '',
      value: initialClass.value,
    } : undefined,
  };

  const {
    setFieldValue,
    errors: formikErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: () => { },
  });


  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function loadClassesOptions(search, loadedOptions, { skip }: any) {
    const variables = {
      searchInput: search,
      skip,
    };

    const { data } = await client.query({
      query,
      variables: variables,
      context: {
        debounceKey: 'INLINE_ALL_CLASSES_QUERY',
      },
    });
    const innerData = data[Object.keys(data)[0]];
    const innerOptions = getInlineOptionsFromQuery(data, 'localizedDescription');

    return {
      options: data ? innerOptions : [],
      hasMore: innerData.response.hasNext,
      additional: {
        skip: skip + INLINE_RECORDS_SIZE,
      },
    };
  }

  useEffect(() => {
    dispatch(setIsLoadingUpdate(loading));
  }, [dispatch, loading]);

  return (
    <form className="">
      <div>
        <AsyncSelectInput
          placeholder={t('Select ETIM Class')}
          onChange={(e: SelectOption) => {
            setFieldValue('class', e);
            addEtimClassMutation(
              {
                variables: {
                  etimClass: e.value as string,
                  quotation: quotationId as string,
                  group: group.id as string,
                },
              },
            );
          }
          }
          loadOptions={loadClassesOptions}
          defaultValue={initialClass as SelectOption}
        />
        {touched.class && !!formikErrors.class && (<div className="mt-2 text-sm text-red-600">{formikErrors.class}</div>)}
      </div>
    </form>

  );
}
