import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useRef, useState } from 'react';
import * as yup from 'yup';
import { UPDATE_SALES_TIME } from '../../../../../api/mutations/quotations/product';
import { useQuotationItemInputHandler } from '../../../../../helpers/customHooks';
import { classNames, convertMinutesToTimeLabel } from '../../../../../helpers/utils';

interface Props {
  productId: ID;
  salesTime?: number,
  quotationId: ID,
  refetchQueries: InternalRefetchQueriesInclude,
  label?: string,
  isSalesWage: boolean,
}

interface FormValues {
  salesTime?: number,
  product: ID,
  quotation: ID,
}

export default function SalesTimeInput(props: Props) {
  const { productId, salesTime, quotationId, refetchQueries, label, isSalesWage } = props;
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const fieldName = 'salesTime'; 
  const validationSchema = yup.object({
    [fieldName]: yup
      .mixed()
      .transform((originalValue) => {
        // If the original value is an empty string or represents an integer, transform it
        if (originalValue === '' || !isNaN(originalValue)) {
          return originalValue === '' ? null : Number(originalValue);
        }
        // If the original value is not a string or is a string not representing an integer, keep it as is
        return originalValue;
      })
      .nullable(),
  });

  const initialValues: FormValues = {
    salesTime: salesTime,
    product: productId as ID,
    quotation: quotationId as ID,
  };

  const { handleChange, values, handleBlur, isError } = useQuotationItemInputHandler({
    mutation: UPDATE_SALES_TIME,
    fieldName,
    validationSchema,
    initialValues,
    refetchQueries,
    setInputFocused,
    inputFocused,
  });

  const hours = Math.floor(values.manufacturingTime as number / 60);

  const inputClass = classNames(
    !inputFocused && hours > 99 ? 'text-xs' : 'text-sm',
    inputFocused && hours >= 16 ? 'text-xs' : 'text-sm',
    (inputFocused || isError) ? 'text-left pl-1' : 'text-right pl-0 pr-2',
    (inputFocused && !isError) && 'border-cblue-500',
    isError && 'border-cred-500',
    'border-b w-full h-full flex items-center justify-end rounded-t bg-cgray-100 hover:bg-cgray-200 focus:outline-none focus:bg-cgray-200 group-hover:bg-cgray-200',
  );

  return (
    <div className="relative h-full flex items-center group">
      {label && (
        <label 
          htmlFor={fieldName} 
          className={classNames(isError ? 'text-cred-500' : 'text-cgray-400', 'absolute top-0 left-2 text-2xs cursor-text')}
          onClick={() => inputRef.current?.focus()}
        >
          {label}
        </label>
      )}
      {isSalesWage && 
          <input
            name={fieldName}
            ref={inputRef}
            value={inputFocused || isError
              ? (values[fieldName] ? values[fieldName] : 0)
              : convertMinutesToTimeLabel(values[fieldName])
            }
            onChange={handleChange}
            className={inputClass}
            onFocus={() => setInputFocused(true)}
            onBlur={() => handleBlur()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // form will be submitted by handleBlur (without second submit) 
                (e.target as HTMLInputElement).blur();
              }
            }}
          />
      }
      {(inputFocused || isError) && <div className={classNames(hours >= 16 ? 'text-xs' : 'text-sm', 'absolute h-full right-1 top-0 flex items-center')}>m</div>}
    </div>
  );
}
