import { useQuery } from '@apollo/client';
import Tippy from '@tippyjs/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductValuesReport, ServiceValuesReport } from '../__generated__/graphql';
import { GROUP_CHILDREN_VALUES_REPORT_QUERY } from '../api/queries/quotations/group';
import ChangeGroupChildrenConfirmationModal from '../components/favoriteGroups/ChangeGroupChildrenConfirmationModal';
import { DraggableFavoriteGroups } from '../components/favoriteGroups/DraggableFavoriteGroups';
import { ValuesChangesTable, ChangedFields } from '../components/quotation/overlays/CheckValuesOverlay/ValuesChangesTable';
import ReduxAlertWrapper from '../components/ReduxAlertWrapper';
import FavouriteGroupSearchInput from '../components/shared/FavouriteGroupSearchInput';
import ModalWrapper from '../components/shared/modal/ModalWrapper';
import { useAppSelector } from '../helpers/reduxHooks';
import { LoadingIndicator, Navbar } from '../layout';
import Switch from '../layout/inputs/Switch';

export default function FavoriteGroups() {
  const { t } = useTranslation();
  const selectedFavouriteGroupId = useAppSelector(state => state.search.selectedFavouriteGroupId);
  const [changedProductFields, setChangedProductFields] = useState<ChangedFields>({});
  const [changedServiceFields, setChangedServiceFields] = useState<ChangedFields>({});
  const [showServiceFields, setShowServiceFields] = useState(false);
  const [showChangeGroupConfirmationModal, setShowChangeGroupConfirmationModal] = useState(false);
  
  const hasChanges = Object.values(changedProductFields).some(fields => 
    Object.values(fields).some(isSelected => isSelected),
  ) || Object.values(changedServiceFields).some(fields => 
    Object.values(fields).some(isSelected => isSelected),
  );

  const {
    data: valuesReportData,
    loading: valuesReportLoading,
  } = useQuery(GROUP_CHILDREN_VALUES_REPORT_QUERY, {
    variables: {
      group: selectedFavouriteGroupId as string,
    },
    skip: !selectedFavouriteGroupId,
  });

  const products = valuesReportData?.groupChildrenValuesReport?.response?.products;
  const services = valuesReportData?.groupChildrenValuesReport?.response?.services;
  
  const hasProducts = !!products?.length;
  const hasServices = !!services?.length;
  const hasBoth = hasProducts && hasServices;
  
  // Automatically set showServiceFields based on available data
  useEffect(() => {
    if (hasServices && !hasProducts) {
      setShowServiceFields(true);
    } else if (hasProducts && !hasServices) {
      setShowServiceFields(false);
    }
  }, [hasProducts, hasServices]);

  const heightClass = `${window.innerHeight - 64}px`;
  
  
  return (
    <div className="flex flex-wrap min-h-screen">
      <div className="w-full">
        <Navbar />
        <div className="pt-1">
          <ReduxAlertWrapper />
          <main className='w-full' style={{ height: heightClass }}>
            <div className='flex' style={{ height: heightClass }}>
              {/* Left side - Favorite Groups */}
              <div className='flex flex-col' style={{ height: heightClass }}>
                <div className="flex items-center bg-cblue-100 border-t-2 border-b-2 border-cblue-500 pt-3 pb-3 mb-3 font-bold text-sm text-cgray-600">
                  <div className="w-36 mt-0.5 mb-0.5 h-5 ml-6">
                    {t('Favorite Groups')}
                  </div>
                </div>
                <div className="grow w-72 pl-2 flex flex-col gap-2 pr-2 border-r-2 border-cgray-300 h-full">
                  <FavouriteGroupSearchInput />
                  <DraggableFavoriteGroups />
                </div>
              </div>
              
              {/* Right side - Values Changes Table */}
              <div className='flex-1 justify-between' style={{ height: heightClass }}>
                {!valuesReportLoading ? (
                  <>
                    <ValuesChangesTable 
                      products={products as ProductValuesReport[]}
                      services={services as ServiceValuesReport[]}
                      changedProductFields={changedProductFields}
                      setChangedProductFields={setChangedProductFields}
                      changedServiceFields={changedServiceFields}
                      setChangedServiceFields={setChangedServiceFields}
                      showServiceFields={showServiceFields}
                    />
                    <div className='flex items-center justify-between w-full p-6 mt-6 gap-4 h-1/12'>
                      {hasBoth && (
                        <Switch
                          value={showServiceFields}
                          onChange={()=>{setShowServiceFields(!showServiceFields);}}
                          className='flex items-center gap-2 shrink-0'
                          label={t('Show service fields')}
                          checkedIcon={<div className='w-3 h-3 text-sm mb-1.5'>S</div>}
                          uncheckedIcon={<div className='w-3 h-3 text-sm mb-1.5'>P</div>}
                        />
                      )}
                      <div className='flex flex-col items-end justify-end w-full gap-4'>
                        <Tippy 
                          content={t('Select the fields you want to update')}
                          disabled={hasChanges}
                        >
                          {/* Tippy is not working for the button, so we need to use a div */}
                          <div>
                            <button
                              onClick={() => setShowChangeGroupConfirmationModal(true)}
                              disabled={!hasChanges}
                              className="bg-cblue-500 text-white px-4 py-2 rounded hover:bg-cblue-600 disabled:bg-cgray-400"
                            >
                              {t('Submit')}
                            </button>
                          </div>
                        </Tippy>
                      </div>
                    </div>
                  </>
                ) : (
                  <LoadingIndicator className='w-full h-full flex items-center justify-center' />
                )}
              </div>
            </div>
          </main>
          {showChangeGroupConfirmationModal &&
            <ModalWrapper
              handleClose={() => setShowChangeGroupConfirmationModal(false)}
              show={showChangeGroupConfirmationModal}
            >
              <ChangeGroupChildrenConfirmationModal 
                handleClose={() => setShowChangeGroupConfirmationModal(false)} 
                changedProductFields={changedProductFields}
                changedServiceFields={changedServiceFields}
              />
            </ModalWrapper>
          }
        </div>
      </div>
    </div>
  );
}
