import { useMutation, useQuery } from '@apollo/client';
import { MenuItem, MenuDivider, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {
  IconCopy,
  IconScissors,
  IconTrash,
  IconPercentage30,
  IconClock,
  IconPercentage,
  IconBusinessplan,
  IconNote,
} from '@tabler/icons-react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ItemTypeChoices } from '../../../../__generated__/graphql';
import { UPDATE_ITEMS_QUANTITY } from '../../../../api/mutations/quotations/item';
import { QUOTATION_ITEM_NOTE_MUTATION } from '../../../../api/mutations/quotations/note';
import {
  UPDATE_MANUFACTURING_TIME,
  UPDATE_PRODUCTS_MANUFACTURING_WAGE,
  UPDATE_PRODUCTS_PROFIT_SURCHARGE,
} from '../../../../api/mutations/quotations/product';
import {
  UPDATE_SERVICES_TIME,
  UPDATE_SERVICES_WAGE,
  UPDATE_SERVICES_PROFIT_SURCHARGE,
} from '../../../../api/mutations/quotations/service';
import { QUOTATION_ITEM_NOTES_QUERY } from '../../../../api/queries/quotations/notes';
import { QUOTATION_QUERY } from '../../../../api/queries/quotations/quotation';
import { MANUFACTURING_WAGES_QUERY, SERVICE_WAGES_QUERY } from '../../../../api/queries/settings';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { LoadingIndicator } from '../../../../layout';
import PopoutInput from '../../../../layout/inputs/PopoutInput';
import {
  setCopiedItems,
  setItemsToCut,
  setShowDeleteModal,
} from '../../../../redux/quotationSlice';
import PasteBelowQuotationItem from '../actions/PasteBelowQuotationItem';
import PasteInsideQuotationItem from '../actions/PasteInsideQuotationItem';
import ContextMenuInput from './ContextMenuInput';
import ContextMenuTextAreaInput from './ContextMenuTextAreaInput';


export default function SelectedItemsContextMenu() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedItems = useAppSelector(state => state.quotation.selectedItems);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const copiedItems = useAppSelector(state => state.quotation.copiedItems);
  const itemsToCut = useAppSelector(state => state.quotation.itemsToCut);

  const pasteBelowRef = useRef<HTMLDivElement>(null);
  const pasteInsideRef = useRef<HTMLDivElement>(null);

  const combinedSelectedItems = selectedItems.concat(selectedItem || []);

  const allProducts: boolean = combinedSelectedItems.every(item => item.product);
  const allServices: boolean = combinedSelectedItems.every(item => item.service);
  
  // Check if only one item is selected for note creation
  const singleItemSelected = (selectedItem && !selectedItems.length) || (selectedItems.length === 1 && !selectedItem);
  const activeItem = selectedItem || selectedItems[0];

  const quotationId = useParams().quotationId;

  const refetchQueries = [{
    query: QUOTATION_QUERY,
    variables: {
      quotation: quotationId as string,
    },
  }];

  const noteRefetchQueries = [
    {
      query: QUOTATION_ITEM_NOTES_QUERY,
      variables: {
        quotation: quotationId as string,
      },
    },
    ...refetchQueries,
  ];

  const { data: manufacturingWagesData } = useQuery(MANUFACTURING_WAGES_QUERY);
  const { data: serviceWagesData } = useQuery(SERVICE_WAGES_QUERY);


  const [
    updateQuantity,
    { loading: updateQuantityLoading },
  ] = useMutation(UPDATE_ITEMS_QUANTITY);
  const [
    updateProductsManufacturingTime,
    { loading: updateProductsManufacturingTimeLoading },
  ] = useMutation(UPDATE_MANUFACTURING_TIME);
  const [
    updateServicesTime,
    { loading: updateServicesTimeLoading },
  ] = useMutation(UPDATE_SERVICES_TIME);
  const [
    updateProductsProfitSurcharge,
    { loading: updateProductsProfitSurchargeLoading },
  ] = useMutation(UPDATE_PRODUCTS_PROFIT_SURCHARGE);
  const [
    updateServicesProfitSurcharge,
    { loading: updateServicesProfitSurchargeLoading },
  ] = useMutation(UPDATE_SERVICES_PROFIT_SURCHARGE);
  const [
    updateProductsManufacturingWage,
    { loading: updateProductsManufacturingWageLoading },
  ] = useMutation(UPDATE_PRODUCTS_MANUFACTURING_WAGE);
  const [
    updateServicesWage,
    { loading: updateServicesWageLoading },
  ] = useMutation(UPDATE_SERVICES_WAGE);
  const [
    createItemNote,
    { loading: createItemNoteLoading },
  ] = useMutation(QUOTATION_ITEM_NOTE_MUTATION);

  const handleQuantityChange = (value: number | string) => {
    updateQuantity({
      variables: {
        quotation: quotationId as string,
        items: combinedSelectedItems.map(item => item.id as string),
        quantity: typeof value === 'string' ? Number(value) : value,
      },
      refetchQueries,
    });
  };

  const handleProductsManufacturingTimeChange = (value: number | string) => {
    updateProductsManufacturingTime({
      variables: {
        quotation: quotationId as string,
        products: combinedSelectedItems.map(item => item.product?.id as string),
        manufacturingTime: typeof value === 'string' ? parseInt(value) : value,
      },
      refetchQueries,
    });
  };

  const handleServicesTimeChange = (value: number | string) => {
    updateServicesTime({
      variables: {
        quotation: quotationId as string,
        services: combinedSelectedItems.map(item => item.service?.id as string),
        serviceTime: typeof value === 'string' ? parseInt(value) : value,
      },
      refetchQueries,
    });
  };
  const handleProductsProfitSurchargeChange = (value: number | string) => {
    updateProductsProfitSurcharge({
      variables: {
        quotation: quotationId as string,
        products: combinedSelectedItems.map(item => item.product?.id as string),
        profitSurcharge: typeof value === 'string' ? Number(value) : value,
      },
      refetchQueries,
    });
  };

  const handleServicesProfitSurchargeChange = (value: number | string) => {
    updateServicesProfitSurcharge({
      variables: {
        quotation: quotationId as string,
        services: combinedSelectedItems.map(item => item.service?.id as string),
        profitSurcharge: typeof value === 'string' ? Number(value) : value,
      },
      refetchQueries,
    });
  };
  const handleProductsManufacturingWageChange = (value: string) => {
    updateProductsManufacturingWage({
      variables: {
        quotation: quotationId as string,
        products: combinedSelectedItems.map(item => item.product?.id as string),
        manufacturingWage: value,
      },
      refetchQueries,
    });
  };

  const handleServicesWageChange = (value: string) => {
    updateServicesWage({
      variables: {
        quotation: quotationId as string,
        services: combinedSelectedItems.map(item => item.service?.id as string),
        serviceWage: value,
      },
      refetchQueries,
    });
  };

  const handleCreateNote = (body: string) => {
    if (activeItem) {
      createItemNote({
        variables: {
          item: activeItem.id as string,
          body: body,
        },
        refetchQueries: noteRefetchQueries,
      });
    }
  };

  // Render "paste" options only if there is a selected item or only one item in selected items
  // and there is something to paste (copied items or items to cut)
  const isPossibleToPaste = (selectedItem || selectedItems.length === 1) && (!!copiedItems?.length || !!itemsToCut?.length);

  return (
    <>
      {isPossibleToPaste && (
        <>
          <MenuItem
            className="hover:bg-cblue-100 px-4 py-2 cursor-pointer text-sm"
            onClick={() => pasteBelowRef.current?.click()}
          >
            <div className="flex items-center gap-2">
              <PasteBelowQuotationItem 
                ref={pasteBelowRef}
                item={selectedItem || selectedItems[0]} 
              />
              {t('Paste below selected item')}
            </div>
          </MenuItem>
          {(selectedItem?.itemType === ItemTypeChoices.Group || selectedItems[0]?.itemType === ItemTypeChoices.Group) && (
            <MenuItem
              className="hover:bg-cblue-100 px-4 py-2 cursor-pointer text-sm"
              onClick={() => pasteInsideRef.current?.click()}
            >
              <div className="flex items-center gap-2">
                <PasteInsideQuotationItem 
                  ref={pasteInsideRef}
                  item={selectedItem || selectedItems[0]} 
                />
                {t('Paste inside selected item')}
              </div>
            </MenuItem>
          )}
        </>
      )}
      <MenuItem
        className="hover:bg-cblue-100 px-4 py-2 cursor-pointer text-sm"
        onClick={() => dispatch(setCopiedItems(combinedSelectedItems))}
      >
        <div className="flex items-center gap-2">
          <IconCopy className="w-4 h-4" />
          {t('Copy selected items')}
        </div>
      </MenuItem>
      <MenuItem
        className="hover:bg-cblue-100 px-4 py-2 cursor-pointer text-sm"
        onClick={() => dispatch(setItemsToCut(combinedSelectedItems))}
      >
        <div className="flex items-center gap-2">
          <IconScissors className="w-4 h-4" />
          {t('Cut selected items')}
        </div>
      </MenuItem>
      <MenuItem
        className="hover:bg-cblue-100 px-4 py-2 cursor-pointer text-sm"
        onClick={() => dispatch(setShowDeleteModal(true))}
      >
        <div className="flex items-center gap-2">
          <IconTrash className="w-4 h-4 text-cred-500" />
          {t('Delete selected items')}
        </div>
      </MenuItem>
      <MenuDivider className="bg-cgray-200 my-1" />

      {/* Add Note - only visible when a single item is selected */}
      {singleItemSelected && (
        <SubMenu
          itemProps={{ className: 'px-4' }}
          label={
            <div className="flex items-center gap-2">
              <IconNote className="w-4 h-4" />
              {t('Add Note')}
            </div>
          }
          className="hover:bg-cblue-100 cursor-pointer text-sm"
        >
          <ContextMenuTextAreaInput
            handleSubmit={handleCreateNote}
            loading={createItemNoteLoading}
            placeholder={t('Enter note for this item...')}
          />
        </SubMenu>
      )}

      {/* Quantity - always visible */}
      <SubMenu
        itemProps={{ className: 'px-4' }}
        label={
          <div className="flex items-center gap-2">
            <IconPercentage30 className="w-4 h-4" />
            {t('Edit Quantity')}
          </div>
        }
        className="hover:bg-cblue-100 cursor-pointer text-sm"
      >
        <ContextMenuInput
          defaultValue={1}
          handleSubmit={(value) => handleQuantityChange(value as number)}
          loading={updateQuantityLoading}
        />
      </SubMenu>

      {allProducts && (
        <>
          <SubMenu
          itemProps={{ className: 'px-4' }}
          label={
            <div className="flex items-center gap-2">
              <IconClock className="w-4 h-4" />
              {t('Edit Products Time')}
            </div>
          }
          className="hover:bg-cblue-100 cursor-pointer text-sm">
            <ContextMenuInput
              defaultValue={5}
              handleSubmit={(value) => handleProductsManufacturingTimeChange(value as number)}
              loading={updateProductsManufacturingTimeLoading}
            />
          </SubMenu>
          <SubMenu
          itemProps={{ className: 'px-4' }}
          label={
            <div className="flex items-center gap-2">
              <IconPercentage className="w-4 h-4" />
              {t('Edit Products Profit Surcharge')}
            </div>
          }
          className="hover:bg-cblue-100 cursor-pointer text-sm">
            <ContextMenuInput
              defaultValue={10}
              handleSubmit={(value) => handleProductsProfitSurchargeChange(value as number)}
              loading={updateProductsProfitSurchargeLoading}
            />
          </SubMenu>
          <SubMenu
            itemProps={{ className: 'px-4' }}
            label={
              <div className="flex items-center gap-2">
                <IconBusinessplan className="w-4 h-4" />
                {t('Edit Products Wage')}
              </div>
            }
            className="hover:bg-cblue-100 cursor-pointer text-sm"
          >
              <div className="p-2 flex gap-2 justify-center">
                {updateProductsManufacturingWageLoading
                  ? <LoadingIndicator size='sm' />
                  : (
                  <PopoutInput
                    onChange={(value) => {
                      handleProductsManufacturingWageChange(value?.value as string);
                    }}
                    options={manufacturingWagesData?.manufacturingWages?.response?.map(wage => ({
                      value: wage?.id as string,
                      label: wage?.label as string,
                    }))}
                    buttonLabel={t('Select Wage')}
                    className="w-24 text-xs"
                  />
                  )}
              </div>
            </SubMenu>
        </>
      )}
      {allServices && (
        <>
          <SubMenu
            itemProps={{ className: 'px-4' }}
            label={
              <div className="flex items-center gap-2">
                <IconClock className="w-4 h-4" />
                {t('Edit Services Time')}
              </div>
            }
          className="hover:bg-cblue-100 cursor-pointer text-sm">
            <ContextMenuInput
              defaultValue={5}
              handleSubmit={(value) => handleServicesTimeChange(value as number)}
              loading={updateServicesTimeLoading}
            />
          </SubMenu>
          <SubMenu
            itemProps={{ className: 'px-4' }}
            label={
              <div className="flex items-center gap-2">
                <IconPercentage className="w-4 h-4" />
                {t('Edit Services Profit Surcharge')}
              </div>
            }
          className="hover:bg-cblue-100 cursor-pointer text-sm">
            <ContextMenuInput
              defaultValue={10}
              handleSubmit={(value) => handleServicesProfitSurchargeChange(value as number)}
              loading={updateServicesProfitSurchargeLoading}
            />
          </SubMenu>
          <SubMenu
            itemProps={{ className: 'px-4' }}
            label={
              <div className="flex items-center gap-2">
                <IconBusinessplan className="w-4 h-4" />
                {t('Edit Services Wage')}
              </div>
            }
            className="hover:bg-cblue-100 cursor-pointer text-sm">
              <div className="p-2 flex gap-2 justify-center">
              {updateServicesWageLoading
                ? <LoadingIndicator size='sm' />
                : (
                <PopoutInput
                  onChange={(value) => {
                    handleServicesWageChange(value?.value as string);
                  }}
                  options={serviceWagesData?.serviceWages?.response?.map(wage => ({
                    value: wage?.id as string,
                    label: wage?.label as string,
                  }))}
                  buttonLabel={t('Select Wage')}
                  className="w-24 text-xs"
                />
                )
              }
              </div>
            </SubMenu>
        </>
      )}
    </>
  );
} 