import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../../../helpers/utils';

interface Props {
  value: number | null;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function NumberFeatureValueInput(props: Props) {
  const { value, onChange } = props;
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  return (
    <div
      className={classNames(inputFocused && 'bg-cgray-200 border-cblue-500', 'border-b  relative h-full flex items-center justify-start rounded-t bg-cgray-100 hover:bg-cgray-200 cursor-text group')}
      onClick={() => { if (inputRef.current) inputRef.current.focus(); }}
    >
      <label
        htmlFor={'numberValue'}
        className=" absolute top-0 left-2 text-2xs text-cgray-400 cursor-text"
        onClick={() => { if (inputRef.current) inputRef.current.focus(); }}
      >
        {t('Value')}
      </label>
      <input
        name={'numberValue'}
        value={value ?? ''}
        onChange={onChange}
        className="px-2 w-full h-10 flex justify-end items-end rounded-t bg-cgray-100 group-hover:bg-cgray-200 focus:bg-cgray-100 focus:outline-none focus:border-none text-sm text-left"
        ref={inputRef}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
      />
    </div>
  );
}
