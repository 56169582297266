import {
  useApolloClient, useMutation,
} from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EtimClassFeatureType, EtimReleasesEtimFeatureFeatureTypeChoices, UnitType } from '../../../../../../../__generated__/graphql';
import { GROUP_ETIM_FEATURE } from '../../../../../../../api/mutations/quotations/group';
import { INLINE_ALL_FEATURES_QUERY } from '../../../../../../../api/queries/etimReleases';
import { QUOTATION_GROUP_DETAILS_QUERY } from '../../../../../../../api/queries/quotations/details';
import { INLINE_RECORDS_SIZE } from '../../../../../../../config';
import { useAppDispatch, useAppSelector } from '../../../../../../../helpers/reduxHooks';
import { AsyncSelectInput } from '../../../../../../../layout/inputs';
import ModalButtons from '../../../../../../../layout/inputs/ModalButtons';
import { setSuccessAlert } from '../../../../../../../redux/alertSlice';
import {
  setFeatureEdit,
  setIsLoadingUpdate,
  setSelectedGroupFeature,
} from '../../../../../../../redux/quotationSlice';
import GroupFeatureANValue from './GroupFeatureANValue';
import GroupFeatureLogicalValue from './GroupFeatureLogicalValue';
import GroupFeatureNumericValue from './GroupFeatureNumericValue';
import GroupFeatureRangeValue from './GroupFeatureRangeValue';

interface Props {
  handleClose: () => void,
}

export function getInlineOptionsFromQuery(queryData: any): SelectOption[] {
  const innerData = queryData[Object.keys(queryData)[0]];

  return innerData.response.objects.map((item: any) => {
    let label = item.feature?.localizedDescription + (item.unit?.abbreviation ? `, ${item.unit?.abbreviation}` : '');    
    if (['NUMERIC', 'RANGE'].includes(item.feature?.featureType)) {
      label = `[${item.feature?.featureType}] ${label}`;
    }
    return {
      label,
      value: item.id,
    };
  });
}

export default function SelectFeatureForm(props: Props) {
  const { handleClose } = props;
  const [allFeatures, setAllFeatures] = useState<EtimClassFeatureType[]>([]);
  const { t } = useTranslation();
  const activeQuotationGroupId = useAppSelector(state => state.quotation.activeQuotationGroupId);
  const selectedGroupFeature = useAppSelector(state => state.quotation.selectedGroupFeature);
  const dispatch = useAppDispatch();

  const client = useApolloClient();
  const query: DocumentNode = INLINE_ALL_FEATURES_QUERY;

  const [featureMutation, {
    loading: mutationLoading,
    data: mutationData,
  }] = useMutation(GROUP_ETIM_FEATURE,
    {
      refetchQueries: [
        {
          query: QUOTATION_GROUP_DETAILS_QUERY,
          variables: {
            group: activeQuotationGroupId,
          },
        },
      ],
    });

  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function loadFeaturesOptions(search, loadedOptions, { skip }: any) {
    const variables = {
      searchInput: search,
      skip,
    };

    const { data } = await client.query({
      query,
      variables: variables,
      context: {
        debounceKey: 'INLINE_ALL_FEATURES_QUERY',
      },
    });
    const innerData = data[Object.keys(data)[0]];
    const innerOptions = getInlineOptionsFromQuery(data);
    // @ts-ignore
    const incomingFeatures: EtimClassFeatureType[] = innerData.response.objects.map(item => ({
      id: item.id,
      unit: item.unit,
      feature: item.feature,
    }));
    
    setAllFeatures([...allFeatures, ...incomingFeatures]);

    return {
      options: data ? innerOptions : [],
      hasMore: innerData.response.hasNext,
      additional: {
        skip: skip + INLINE_RECORDS_SIZE,
      },
    };
  }

  const handleChangeFeature = (e: SelectOption) => {
    const selectedFeature = allFeatures.find((item) => item.id === e.value);
    if (selectedFeature) {
      dispatch(setSelectedGroupFeature({
        ...selectedGroupFeature,
        feature: selectedFeature.feature,
        value: null,
        unit: selectedFeature.unit as UnitType,
        etimClassFeatureId: selectedFeature.id,
      }));
    }
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    const variables = {
      etimClassFeature: selectedGroupFeature?.etimClassFeatureId as string,
      value: JSON.stringify(selectedGroupFeature?.value),
      group: activeQuotationGroupId,
      id: selectedGroupFeature?.id as string,
    };
    
    featureMutation({
      variables: variables,
    });
    
    dispatch(setFeatureEdit());
  };

  useEffect(() => {
    if (mutationData && mutationData.groupFeature && mutationData.groupFeature.response) {
      const message = selectedGroupFeature?.id ? t('Feature was successfully updated') : t('New feature was added');
      dispatch(setSuccessAlert(
        [message],
      ));
      handleClose();
    }
  }, [mutationData, dispatch, handleClose, t, selectedGroupFeature?.id]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(mutationLoading));
  }, [dispatch, mutationLoading]);

  const isValueArrayCorrect = !Array.isArray(selectedGroupFeature?.value) || (selectedGroupFeature?.value as number[])[0] <= (selectedGroupFeature?.value as number[])[1];
  const isSubmitButtonDisabled = mutationLoading || selectedGroupFeature?.value === null || selectedGroupFeature?.value === undefined || !isValueArrayCorrect;
  
  return (
    <form className="w-full px-8" onSubmit={e => handleSubmit(e)}>
      <div className="w-full">
        <AsyncSelectInput
          placeholder={t('Select Feature')}
          onChange={(e) => {
            handleChangeFeature(e);
          }}
          loadOptions={loadFeaturesOptions}
          value={selectedGroupFeature ? {
            value: selectedGroupFeature.etimClassFeatureId as string,
            label: selectedGroupFeature.feature?.localizedDescription + 
              (selectedGroupFeature.unit?.abbreviation ? `, ${selectedGroupFeature.unit.abbreviation}` : ''),
          } : undefined}
        />
      </div>
      {
        selectedGroupFeature?.feature?.featureType === EtimReleasesEtimFeatureFeatureTypeChoices.Numeric
        && (
          <div className="mt-2 w-full">
            <GroupFeatureNumericValue />
          </div>
        )
      }
      {
        selectedGroupFeature?.feature?.featureType === EtimReleasesEtimFeatureFeatureTypeChoices.Range
        && (
          <div className="mt-2 w-full">
            <GroupFeatureRangeValue />
            {!isValueArrayCorrect ? <p className="text-red-500 text-xs h-6">{t('The minimum value must be less or equal to the maximum value')}</p> : <p className='h-6'></p>}
          </div>
        )
      }
      {
        selectedGroupFeature?.feature?.featureType === EtimReleasesEtimFeatureFeatureTypeChoices.Alphanumeric
        && (
          <div className="mt-2 w-full">
            <GroupFeatureANValue />
          </div>
        )
      }
      {
        selectedGroupFeature?.feature?.featureType === EtimReleasesEtimFeatureFeatureTypeChoices.Logical
        && (
          <div className="mt-2 w-full">
            <GroupFeatureLogicalValue />
          </div>
        )
      }
      <ModalButtons 
        mutationLoading={mutationLoading} 
        cancelButtonText='Cancel' 
        submitButtonText="Save" 
        handleClose={() => {
          dispatch(setFeatureEdit());
          handleClose();
        }} 
        wrapperClassName='mt-60 mb-10 flex items-center justify-center w-full gap-x-8'
        isSubmitButtonDisabled={isSubmitButtonDisabled}
      />
    </form>

  );
}
