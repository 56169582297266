import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../../../../assets/css/react-image-gallery.css';
import { StorageProductImageType } from '../../../../__generated__/graphql';


export interface ProductGalleryProps {
  images?: StorageProductImageType[];
  externalId: string;
}


function ProductGallery(props: ProductGalleryProps) {
  const { images, externalId } = props;
  if (!images || images.length === 0) {
    return (
      <div className="w-[320px] aspect-square bg-gradient-to-br from-cgray-100 to-cblue-300 flex items-center justify-center rounded-t">
        <span className="text-cblue-500 text-center text-4xl font-bold drop-shadow-lg">{externalId}</span>
      </div>
    );
  }
  const items = images.map((image) => ({
    original: image.srcSet?.sm as string,
  }));
  return (
    <div className="w-full h-full">
      <ImageGallery
        items={items}
        showPlayButton={false}
        showThumbnails={true}
        showNav={items.length > 1}
        showBullets={items.length > 1}
        showFullscreenButton={false}
      />
    </div>
  );
}

export default ProductGallery;