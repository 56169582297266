import { gql } from '../../../__generated__/gql';


export const SERVICE_ESTIMATE_DETAILS_QUERY = gql(`
query serviceEstimateDetails($service: ID!){
  serviceEstimateDetails(
    service: $service
    ){
      response{
			 administrativeCostsLabel
			 administrativeOverheadRate{
				 label
				 isCurrent
			}
			 costAdministrationSalesLabel
			 costPerItemLabel
			 manufacturingCostLabel
			 manufacturingOverheadLabel
			 manufacturingOverheadRate{
				 label
				 isCurrent
			}
			 netSalesPricePieceLabel
			 profitPerPieceLabel
			 profitSurcharge
			 salesCostLabel
			 salesOverheadRate{
				 label
				 isCurrent
			}
			 serviceLabourCostLabel
			 serviceTime
			 serviceWage{
        id
        costLabel
        wageGroupLabel
        label
        isCurrent
			}
		}
      errors {
        messages
        field
      }
  }
}
`);

export const PRODUCT_DETAILS_QUERY = gql(`
  query productDetails(
      $externalId: ID!
      $product: ID
    ){
    productDetails(
      externalId: $externalId
      product: $product
      ){
        response {
          notes
          purchaseOptions {
            catalog {
              id
              name
              supplier {
                id
                name
              }
            }
            discount{
              id
              label
              pricePieceLabel
              isObsolete
            }
            discountedPricePieceLabel
            discountGroup
            id
            isObsolete
            isSelectedOffer
            matchCode
            netPricePieceLabel
            orderAmount
            priceIndicator
            priceLabel
            pricePieceLabel
            productType
            rawMaterialSurcharges{
              rawMaterial
              surchargeCost
              surchargeCostLabel
            }
            specialPriceLabel
            specialPriceIsObsolete
            unit
            unitsPerPrice
            discountGroup
          }
          productSettings{
            id
            specialPrice
          }
          storageProduct{
            description
            externalId
            gtin
            id
            images{
              caption
              id
              srcSet{
                lg
                md
                original
                sm
                xl
                xxl
              }
            }
          }
          etimProduct{
            id
            descriptionShort
            descriptionLong
            productFeatures{
              id
              etimClassFeature{
                id
                feature{
                  id
                  localizedDescription
                }
                unit{
                  id
                  abbreviation
                }
              }
              value
              valueLabels
            }
            etimClass{
              id 
              localizedDescription
              group{
                id
                localizedDescription
              }
            }
          }
        }
        errors {
          messages
          field
        }
    }
  }
`);

export const PRODUCT_ESTIMATE_DETAILS_QUERY = gql(`
query productEstimateDetails($product: ID!){
  productEstimateDetails(
    product: $product
    ){
      response {
        netSalesPricePieceLabel
        netPricePieceLabel
			  materialCostBurdenRate{
          isCurrent
          label
        }
			  materialOverheadLabel
			  manufacturingWage{
          id
          costLabel
          wageGroupLabel
          label
          isCurrent
        }
			  manufacturingTime
			  manufacturingLabourCostLabel
			  manufacturingOverheadRate{
          isCurrent
          label
        }
			  manufacturingOverheadLabel
			  materialCostLabel
			  productionCostLabel
			  manufacturingCostLabel
			  salesOverheadRate{
          isCurrent
          label
        }
			  salesWage{
          id
          costLabel
          wageGroupLabel
          label
          isCurrent
        }
			  salesTime
			  specialDirectCostsSalesLabel
			  shippingCost
			  salesCostLabel
			  salesOverheadLabel
        administrativeOverheadRate{
          isCurrent
          label
        }
			  administrativeCostsLabel
			  costAdministrationSalesLabel
			  costPerItemLabel
			  profitPerPieceLabel
        profitSurcharge
		  }
      errors {
        messages
        field
      }
  }
}
`);

export const QUOTATION_GROUP_DETAILS_QUERY = gql(`
query QuotationGroupDetails($group: ID!){
  quotationGroupDetails(
    group: $group
    ){
      response {
        description
        discount
        hasManualSalesPricePiece
        id
        isFavourite
        notes
        etimClass{
          id
          code
          features{
            id
            code
          }
          localizedDescription
        }
        productionUnitStatus{
          id
          sortOrder
          title
          color
          statusType
          isReadonly
          isCompleted
        }
        groupFeatures{
          id
          value
          valueLabels
          etimClassFeature{
            id
            unit{
              id
              abbreviation
              code
            }
            feature{
              id
              code
              featureType
              localizedDescription
            }
          }
        }
        isProductionUnit
        manualSalesPricePiece{
          id
          priceType
          value
          valueLabel
        }
		  }
      errors {
        messages
        field
      }
    }
  }
`);
