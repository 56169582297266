import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CREATE_GROUP_TEMPLATE } from '../../../api/mutations/groupTemplates';
import { QUOTATION_QUERY } from '../../../api/queries/quotations/quotation';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import ModalButtons from '../../../layout/inputs/ModalButtons';
import { setSuccessAlert } from '../../../redux/alertSlice';
import { setIsLoadingUpdate } from '../../../redux/quotationSlice';

interface Props {  
  handleClose: () => void,
}

export default function CreateGTConfirmationModal(props: Props) {
  const { handleClose } = props;
  const dispatch = useAppDispatch();
  const { quotationId } = useParams();
  const { t } = useTranslation();
  const itemIdToCreateGTFrom = useAppSelector(state => state.quotation.itemIdToCreateGTFrom);

  const [
    createGroupTemplate,
    {
      data: mutationData,
      loading: mutationLoading,
    },
  ] = useMutation(CREATE_GROUP_TEMPLATE, {
    refetchQueries: [{
      query: QUOTATION_QUERY,
      variables: {
        quotation: quotationId as string,
      },
    }],
  });

  const handleStoreGroupAsTemplate = () => {
    createGroupTemplate(
      {
        variables: {
          item: itemIdToCreateGTFrom,
        },
        onError: () => {
          dispatch(setIsLoadingUpdate(false));
        },
      },
    );
  };

  useEffect(() => {
    if (mutationData && mutationData.createGroupTemplate && mutationData.createGroupTemplate.response) {
      dispatch(setSuccessAlert(
        ['Group Template was successfully created.'],
      ));
      handleClose();
    } else if (mutationData && mutationData.createGroupTemplate && mutationData.createGroupTemplate.errors) {
      handleClose();
    }
  }, [mutationData, dispatch, handleClose]);

  return (
    <div className='flex flex-col justify-between items-center w-full rounded drop-shadow-xl bg-white' onClick={e => e.stopPropagation()}>
      <div className='pl-8 bg-cblue-100 w-full h-14 border-b-2 border-cblue-500 font-bold flex items-center text-cblue-500'>{t('Create Group Template')}</div>
      <div className='w-full pl-8 my-8'>{t('This group (or any of nested groups) has percentage type of manual price. This price is going to be saved as Absolute price.')}</div>
      <ModalButtons 
        mutationLoading={mutationLoading} 
        cancelButtonText='Cancel' 
        submitButtonText="Confirm" 
        handleClose={handleClose} 
        onSubmit={handleStoreGroupAsTemplate}
        wrapperClassName='flex justify-end gap-8 pr-8 pb-8 w-full'
      />
    </div>
  );
}
