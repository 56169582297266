import { useTranslation } from 'react-i18next';
import { ComposeModeSearchType } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import SelectInput from '../../../layout/inputs/SelectInput';
import { setComposeModeSearchType } from '../../../redux/searchSlice';

export default function ComposeModeSearchTypeSelect() {
  const dispatch = useAppDispatch();
  const composeModeSearchType = useAppSelector(state => state.search.composeModeSearchType);
  const { t } = useTranslation();

  const options: SelectOption[] = [
    { value: ComposeModeSearchType.DEFAULT, label: t('Default') },
    { value: ComposeModeSearchType.GROUPS, label: t('Groups') },
  ];

  return (
    <SelectInput
      options={options}
      placeholder={t('Search type')}
      value={options.find(option => option.value === composeModeSearchType)}
      onChange={(e: SelectOption) => dispatch(setComposeModeSearchType(e.value as ComposeModeSearchType))}
    />
  );
}
