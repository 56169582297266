import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UPDATE_GROUP_PRODUCTION_UNIT } from '../../../../../../../api/mutations/quotations/group';
import { useAppDispatch } from '../../../../../../../helpers/reduxHooks';
import { Switch } from '../../../../../../../layout/inputs';
import { setIsLoadingUpdate } from '../../../../../../../redux/quotationSlice';

interface Props {
  isProductionUnit: boolean,
  groupId: ID,
  refetchQueries: InternalRefetchQueriesInclude,
}

export default function UpdateProductionUnitForm(props: Props) {
  const { isProductionUnit, groupId, refetchQueries } = props;
  const { t } = useTranslation();
  const { quotationId } = useParams();

  const dispatch = useAppDispatch();

  const [
    updateIsProductionUnitMutation,
  ] = useMutation(UPDATE_GROUP_PRODUCTION_UNIT, {
    refetchQueries: refetchQueries,
    onCompleted: () => { dispatch(setIsLoadingUpdate(false)); },
  });

  const handleChange = () => {
    dispatch(setIsLoadingUpdate(true));
    updateIsProductionUnitMutation({
      variables: {
        quotation: quotationId as string,
        group: groupId as string,
        isProductionUnit: !isProductionUnit,
      },
    });
  };


  return (
    <div className="flex items-center">
      <Switch
        className='flex items-center gap-2 shrink-0'
        value={isProductionUnit}
        onChange={handleChange}
        label={t('Is Production Unit?')}
      />
    </div>
  );
}
